import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as profileBaseActions from '../actions/profile-base.actions';
import * as profileBaseSelectors from '../selectors/profile-base.selector';
import { TopProfileInfo } from '../../interfaces/customer-info/top-profile-info';
import { UpdatePasswordRequest } from '../../interfaces/customer-settings/update-password-request';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { NewMatchSettingsService } from '@profile/services/new-match-settings.service';
import { NewMatchSettingsModel } from '../../interfaces/new-match-settings/new-match-settings.model';
import { CustomerInfo } from '../../interfaces/customer-info/customer-info';

@Injectable({ providedIn: 'root' })
export class ProfileBaseStoreService {

    constructor(private readonly store: Store, private readonly newMatchSettingsService: NewMatchSettingsService) { }

    public readonly additionalProfilesEmails$ = this.store.select(profileBaseSelectors.selectAdditionalProfilesEmails);
    public readonly canAddAdditionalProfile$ = this.store.select(profileBaseSelectors.selectCanAddAdditionalProfile);
    public readonly activeAdditionalProfiles$ = this.store.select(profileBaseSelectors.selectActiveAdditionalProfiles);
    public readonly isSelfProfile$ = this.store.select(profileBaseSelectors.selectIsSelfProfile);
    public readonly selectedAdditionalProfileId$ = this.store.select(profileBaseSelectors.selectSelectedAdditionalProfileId);
    public readonly additionalProfiles$ = this.store.select(profileBaseSelectors.selectAdditionalProfiles);

    public loadAdditionalProfiles(filters: { isActiveOnly: boolean }): void {
        this.store.dispatch(profileBaseActions.loadAdditionalProfiles({ isActiveOnly: filters.isActiveOnly }));
    }

    public loadProfileData(): void {
        this.store.dispatch(profileBaseActions.loadProfileData());
    }

    public showDeactivateChildAccountDialog(childCustomerId: number): void {
        this.store.dispatch(profileBaseActions.showDeactivateChildAccountDialog({ childCustomerId }));
    }

    public showResendInviteDialog(childEmail: string): void {
        this.store.dispatch(profileBaseActions.showResendInviteDialog({ childEmail }));
    }

    public showCancelInvitationDialog(childEmail: string): void {
        this.store.dispatch(profileBaseActions.showCancelInvitationDialog({ childEmail }));
    }

    public showInviteUserDialog(): void {
        return this.store.dispatch(profileBaseActions.showInviteUserDialog());
    }

    public showEditProfileInfoDialog(profileInfo: TopProfileInfo): void {
        return this.store.dispatch(profileBaseActions.showEditProfileInfoDialog({ profileInfo }));
    }

    public showDeactivateAccountDialog(): void {
        return this.store.dispatch(profileBaseActions.showDeactivateAccountDialog());
    }

    public setActiveAdditionalProfileId(additionalProfileId: number): void {
        return this.store.dispatch(profileBaseActions.setActiveAdditionalProfileId({ additionalProfileId }));
    }

    public updatePassword(model: UpdatePasswordRequest): void {
        return this.store.dispatch(profileBaseActions.updatePassword({ model }));
    }

    public updateSettings(settings: CustomerSettings): void {
        return this.store.dispatch(profileBaseActions.updateSettings({ settings }));
    }

    public navigateToProfile(): void {
        return this.store.dispatch(profileBaseActions.navigateToProfile());
    }

    public loadSettings(): void {
        return this.store.dispatch(profileBaseActions.loadSettings());
    }

    public loadNewMatchSettings(): void {
        return this.store.dispatch(profileBaseActions.loadNewMatchSettings());
    }

    public updateNewMatchSettings(settings: NewMatchSettingsModel): void {
        const request = this.newMatchSettingsService.formatRequest(settings);

        return this.store.dispatch(profileBaseActions.updateNewMatchSettings({ request }));
    }

    public updateCustomerInfo(customerInfo: CustomerInfo): void {
        return this.store.dispatch(profileBaseActions.updateCustomerInfo({ customerInfo }));
    }

    public navigateToPrimaryProfilePage(): void {
        this.store.dispatch(profileBaseActions.navigateToPrimaryProfilePage());
    }
}