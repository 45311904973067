<mat-checkbox class="rpc-checkbox" [checked]="state === 'checked'" [indeterminate]="state === 'indeterminate'"
    [color]="color" (change)="onCheckboxChange()" (click)="$event.stopPropagation()">

    <ng-container *ngIf="label">
        {{ label | translate }}
    </ng-container>

    <ng-content>
    </ng-content>

</mat-checkbox>