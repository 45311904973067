import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NewMatchSettingsService } from '@profile/services/new-match-settings.service';
import { FeatureCollection } from '@settings/models/map/feature-collection';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { OnMarketSettings } from '@settings/models/settings/on-market-settings';
import * as settingsActions from '@settings/store/actions/settings.actions';
import * as settingsSelectors from '@settings/store/selectors/settings.selector';
import { NewMatchSettingsModel } from 'app/modules/user-modules/profile-modules/profile-base/interfaces/new-match-settings/new-match-settings.model';
import { LayoutSettings } from '@settings/models/settings/layout-settings';

@Injectable({ providedIn: 'root' })
export class SettingsStoreService {

    constructor(
        private readonly store: Store,
        private readonly newMatchSettingsService: NewMatchSettingsService
    ) { }

    public readonly isDarkMode$ = this.store.select(settingsSelectors.selectModeInfo);
    public readonly defaultFolderId$ = this.store.select(settingsSelectors.selectDefaultFolderId);
    public readonly permissionSettings$ = this.store.select(settingsSelectors.selectPermissionSettings);
    public readonly canSearchForListings$ = this.store.select(settingsSelectors.selectCanSearchForListings);
    public readonly isNeighborhoodLayerVisible$ = this.store.select(settingsSelectors.selectIsNeighborhoodLayerVisible);
    public readonly shouldHideOpenRentals$ = this.store.select(settingsSelectors.selectHideOpenRentals);
    public readonly isLoading$ = this.store.select(settingsSelectors.selectIsLoading);
    public readonly newMatchSettingsLoaded$ = this.store.select(settingsSelectors.selectNewMatchSettingsLoaded);

    public getSettings(): Observable<CustomerSettings> {
        return this.store.select(settingsSelectors.selectSettings)
            .pipe(filter(settings => settings != null));
    }

    public getNewMatchSettings(): Observable<NewMatchSettingsModel> {
        return this.store
            .select(settingsSelectors.selectNewMatchSettings)
            .pipe(map(settingsValue => this.newMatchSettingsService.createSettings(settingsValue)));
    }

    public getOnMarketSettings(): Observable<OnMarketSettings> {
        return this.store.select(settingsSelectors.selectOnMarketSettings);
    }

    public getNeighborhoodsMapping(): Observable<FeatureCollection> {
        return this.store.select(settingsSelectors.selectNeighborhoodsMapping);
    }

    public loadSettings(): void {
        this.store.dispatch(settingsActions.loadSettings());
    }

    public loadNewMatchSettings(): void {
        this.store.dispatch(settingsActions.loadNewMatchSettings());
    }

    public loadNeighborhoodsMapping(): void {
        this.store.dispatch(settingsActions.loadNeighborhoodsMapping());
    }

    public updateLayoutSettings(layoutSettings: Partial<LayoutSettings>): void {
        this.store.dispatch(settingsActions.updateLayoutSettings({ layoutSettings }));
    }

    public updateNewMatchSettings(settings: NewMatchSettingsModel): void {
        const request = this.newMatchSettingsService.formatRequest(settings);

        this.store.dispatch(settingsActions.updateNewMatchSettings({ request }));
    }
}