import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Email } from '../../../profile-base/interfaces/customer-info/email';
import { EmailTypes } from '../../../profile-base/interfaces/enums/email-types.enum';

@Component({
    selector: 'emails-list',
    templateUrl: './emails-list.component.html',
    styleUrls: ['../../../profile-base/components/profile-info-base/profile-info-base.component.scss', './emails-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailsListComponent {
    @Input() public emails: Email[];

    public readonly emailTypesText = new Map<EmailTypes, string>([
        [EmailTypes.office, 'PROFILE_COMMON.ENUM_TYPES.OFFICE'],
        [EmailTypes.personal, 'PROFILE_COMMON.ENUM_TYPES.PERSONAL'],
        [EmailTypes.other, 'PROFILE_COMMON.ENUM_TYPES.OTHER'],
    ]);
}