import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { NotAuthorizedToolbarComponent } from './not-authorized-toolbar.component';
import { RpcControlsModule } from '../../controls/controls.module';
import { LogoModule } from '@core-layout/logo/logo.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { NgLetModule } from 'ng-let';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgLetModule,

        RpcControlsModule,
        LogoModule,
    ],
    declarations: [NotAuthorizedToolbarComponent],
    exports: [NotAuthorizedToolbarComponent],
})
export class NotAuthorizedToolbarModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
