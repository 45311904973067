import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as additionalProfileActions from '@additional-profile/store/actions/additional-profile.actions';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { NewMatchSettings } from '@settings/models/settings/new-match-settings';
import { NewMatchSettingsRequest } from '@settings/models/settings/new-match-settings-request';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsActionErrors } from '../enums/settings-action-errors';

@Injectable({ providedIn: 'root' })
export class SettingsApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public loadSettings(): Observable<Action> {
        return this.http.post('settings/get-settings', {}).pipe(
            map(settingsActions.loadSettingsSuccessful),
            catchError(() => of(settingsActions.loadSettingsFailed({ error: SettingsActionErrors.SettingsLoading })))
        );
    }

    public loadAdditionalProfileSettings(additionalProfileId: number): Observable<Action> {
        return this.http.post('settings/get-settings', { additionalProfileId }).pipe(
            map((settings: CustomerSettings) => additionalProfileActions.loadSettingsSuccessful({ settings })),
            catchError(() => of(additionalProfileActions.loadSettingsFailed({ error: SettingsActionErrors.SettingsLoading })))
        );
    }

    public loadNewMatchSettings(): Observable<Action> {
        return this.http.get('settings/new-match-settings').pipe(
            map((response: ApiDataResult<NewMatchSettings>) => settingsActions.loadNewMatchSettingsSuccessful({ settings: response.result })),
            catchError(() => of(settingsActions.loadNewMatchSettingsFailed({ error: SettingsActionErrors.NewMatchSettingsLoading })))
        );
    }

    public loadAdditionalProfileNewMatchSettings(additionalProfileId: number): Observable<Action> {
        return this.http.get(`settings/new-match-settings/${additionalProfileId}`).pipe(
            map((response: ApiDataResult<NewMatchSettings>) => additionalProfileActions.loadNewMatchSettingsSuccessful({ settings: response.result })),
            catchError(() => of(additionalProfileActions.loadNewMatchSettingsFailed({ error: SettingsActionErrors.NewMatchSettingsLoading })))
        );
    }

    public updateSettings(newSettings: CustomerSettings, oldSettings: CustomerSettings): Observable<Action> {
        return this.http.post('settings/update-settings', { settings: newSettings }).pipe(
            map(settingsActions.updateSettingsSuccessful),
            catchError(() => of(settingsActions.updateSettingsFailed({ error: SettingsActionErrors.SettingsUpdate, settings: oldSettings })))
        );
    }

    public updateAdditionalProfileSettings(additionalProfileId: number, newSettings: CustomerSettings, oldSettings: CustomerSettings): Observable<Action> {
        return this.http.post('settings/update-settings', { additionalProfileId, settings: newSettings }).pipe(
            map(additionalProfileActions.updateSettingsSuccessful),
            catchError(() => of(additionalProfileActions.updateSettingsFailed({ error: SettingsActionErrors.SettingsUpdate, settings: oldSettings })))
        );
    }

    public updateNewMatchSettings(request: NewMatchSettingsRequest): Observable<Action> {
        return this.http.post('settings/update-new-match-settings', request).pipe(
            map(settingsActions.updateNewMatchSettingsSuccessful),
            catchError(() => {
                return of(settingsActions.updateNewMatchSettingsFailed({
                    error: SettingsActionErrors.NewMatchSettingsUpdate,
                    settings: { settingsValues: [...request.personalSettings, ...request.sharedSettings] }
                }));
            })
        );
    }

    public updateAdditionalProfileNewMatchSettings(additionalProfileId: number, request: NewMatchSettingsRequest): Observable<Action> {
        return this.http.post('settings/update-new-match-settings', { additionalProfileId, ...request }).pipe(
            map(additionalProfileActions.updateNewMatchSettingsSuccessful),
            catchError(() => {
                return of(additionalProfileActions.updateNewMatchSettingsFailed({
                    error: SettingsActionErrors.NewMatchSettingsUpdate,
                    settings: { settingsValues: [...request.personalSettings, ...request.sharedSettings] }
                }));
            })
        );
    }

    public loadNeighborhoodsMapping(): Observable<Action> {
        return this.http.get('configuration/neighborhoodsMapping').pipe(
            map(settingsActions.loadNeighborhoodsMappingSuccessful),
            catchError(() => of(settingsActions.loadNeighborhoodsMappingFailed({ error: SettingsActionErrors.NeighborhoodsMappingLoading })))
        );
    }
}