import { Injectable } from '@angular/core';
import { MessageHandler } from './message-handler';
import { HybridService } from '../hybrid.service';

export interface SetFullscreenMessage {
    setFullscreen: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SetFullscreenMessageHandler
    implements MessageHandler<SetFullscreenMessage> {
    constructor(private readonly hybridService: HybridService) { }

    public handle(message: SetFullscreenMessage): void {
        this.hybridService.fullscreenHybridState$.next(message.setFullscreen);
    }
}
