export const locale = {
    lang: 'en',
    data: {
        PROFILE_TOOLBAR: {
            TITLES: {
                BACK: 'Back',
                PROFILE: 'Profile',
                CHILD_PROFILE: 'Child Account',
            }
        }
    }
};
