import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CurrencyConstants } from '@core-constants/currency/currency.constants';
import { DateConstants } from '@core-constants/dates/date.constants';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';

@Component({
    selector: 'price-change-info',
    templateUrl: './price-change-info.component.html',
    styleUrls: ['./price-change-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceChangeInfoComponent {
    @Input() priceChange: ListingPriceChange;
    @Input() isPriceIncreased: boolean;

    public readonly currency = CurrencyConstants;
    public readonly date = DateConstants;
    public readonly math = Math;
}