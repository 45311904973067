import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TopProfileInfo } from '../../../profile-base/interfaces/customer-info/top-profile-info';
import { featureName } from '../../constants/additional-profile.constants';
import { AdditionalProfileState } from '../states/additional-profile.state';

const selectAdditionalProfileState = createFeatureSelector<AdditionalProfileState>(featureName);

export const selectPhones = createSelector(selectAdditionalProfileState, state => state?.phones ?? []);

export const selectEmails = createSelector(selectAdditionalProfileState, state => state?.emails ?? []);

export const selectAddresses = createSelector(selectAdditionalProfileState, state => state?.addresses ?? []);

export const selectProfile = createSelector(selectAdditionalProfileState, state => state?.profile);

export const selectSettings = createSelector(selectAdditionalProfileState, state => state?.settings);

export const selectNewMatchSettings = createSelector(selectAdditionalProfileState, state => state?.newMatchSettings);
export const selectGeneralInfoLoaded = createSelector(selectAdditionalProfileState, state => state?.generalInfoLoaded);
export const selectSettingsLoaded = createSelector(selectAdditionalProfileState, state => state?.settingsLoaded);
export const selectNewMatchSettingsLoaded = createSelector(selectAdditionalProfileState, state => state?.newMatchSettingsLoaded);

export const selectTopProfileInfo = createSelector(
    selectProfile,
    selectEmails,
    (additionalProfile, emails) => {
        if (additionalProfile == null) {
            return null;
        }

        const { customerId, firstName, lastName, email } = additionalProfile;

        return { customerId, firstName, lastName, userName: email, isAdditionalProfile: true, emails } as TopProfileInfo;
    }
);

export const selectProfileImage = createSelector(selectProfile, profile => profile?.profileImage);

export const selectCustomerFullName = createSelector(selectProfile, profile => `${profile?.firstName} ${profile?.lastName}`);
