<div class="invite-child">

    <div class="invite-child-header">

        <div class="invite-child-header-title">
            {{ 'INVITE_USER_DIALOG.TITLES.TITLE' | translate }}
        </div>

        <rpc-icon-button class="invite-child-header-close" [iconName]="'cross'"
            [title]="'INVITE_USER_DIALOG.BUTTONS.CLOSE'" [mat-dialog-close]="false" (click)='close()'>
        </rpc-icon-button>

        <rpc-button class="invite-child-header-action" [title]="'INVITE_USER_DIALOG.BUTTONS.SEND_EMAIL'"
            [color]="'accent'" [disabled]="isFormInvalid" (click)="sendEmail()">
        </rpc-button>

    </div>

    <div class="invite-child-body">

        <div class="invite-child-text">
            {{ 'INVITE_USER_DIALOG.TITLES.DESCRIPTION' | translate }}
        </div>

        <invite-user-form class="invite-child-form-wrap" [email]="inviteDialogData?.email"
            [portfolioEmails]="inviteDialogData?.portfolioEmails">
        </invite-user-form>

    </div>

    <div class="invite-child-footer">

        <rpc-button [title]="'INVITE_USER_DIALOG.BUTTONS.CANCEL'" [appearanceType]="'stroke'" [mainButton]="true"
            (clicked)="close()">
        </rpc-button>

        <rpc-button [title]="'INVITE_USER_DIALOG.BUTTONS.SEND_EMAIL'" [appearanceType]="'flat'" [color]="'accent'"
            [mainButton]="true" [disabled]="isFormInvalid" (click)="sendEmail()">
        </rpc-button>

    </div>

</div>