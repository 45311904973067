<form name="registrationForm" [formGroup]="registrationForm">
    <rpc-input class="register-form-input" type="text" appearance="fill" label="REGISTRATION_FORM.FIELDS.FIRST_NAME"
        [options]="firstNameControlOptions">
    </rpc-input>
    <rpc-input class="register-form-input" type="text" appearance="fill" label="REGISTRATION_FORM.FIELDS.LAST_NAME"
        [options]="lastNameControlOptions">
    </rpc-input>
    <rpc-input class="register-form-input" type="email" appearance="fill" label="REGISTRATION_FORM.FIELDS.EMAIL"
        [options]="emailControlOptions" [disabled]="true">
    </rpc-input>
    <phone-form-input [appearance]="'fill'" [phoneNumberInfo]="customerData.phoneNumberInfo" [isPhoneRequired]="false"
        [isFormatValidationRequired]="true" (phoneNumberChanged)="onPhoneNumberChanged($event)">
    </phone-form-input>

    <ng-container *ngIf="!isWorkspaceAddition">

        <password class="register-form-input" [label]="'REGISTRATION_FORM.FIELDS.PASSWORD'"
            [options]="passwordControlOptions">
        </password>

        <password class="register-password-form-input" [label]="'REGISTRATION_FORM.FIELDS.PASSWORD_CONFIRM'"
            [options]="passwordConfirmControlOptions">
        </password>

    </ng-container>

    <div class="register-password-form-terms">
        <rpc-checkbox [(state)]="agreeToTermsAndConditions">
            <span>
                {{'REGISTRATION_FORM.TITLES.AGREE_TERMS' | translate}}
                <br />
                <a [href]="termsAndConditionsLink" target="_blank" rel="nofollow">
                    {{'REGISTRATION_FORM.TITLES.TERMS_CONDITIONS' | translate}}
                </a>
                {{'REGISTRATION_FORM.TITLES.AND' | translate}}
                <a [href]="privacyPolicyLink" target="_blank" rel="nofollow">
                    {{'REGISTRATION_FORM.TITLES.PRIVACY_POLICY' | translate}}
                </a>
            </span>
        </rpc-checkbox>
    </div>

    <rpc-button class="register-submit-button" [mainButton]="true" [color]="'accent'"
        [disabled]="registrationForm.invalid || agreeToTermsAndConditions !== 'checked'" [appearanceType]="'flat'"
        [title]=" isWorkspaceAddition ? 'REGISTRATION_FORM.BUTTONS.WORKSPACE_ADD' : 'REGISTRATION_FORM.BUTTONS.CREATE'"
        (clicked)="onSubmit()">
    </rpc-button>
</form>