import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { NewMatchSettings } from '@settings/models/settings/new-match-settings';
import { NewMatchSettingsRequest } from '@settings/models/settings/new-match-settings-request';
import { ChildAccount } from '../../../profile-base/interfaces/child-account/child-account';
import { NewAccountDataModel, OldAccountDataModel } from '../../../profile-base/interfaces/customer-info/account-data-model';
import { CustomerInfo } from '../../../profile-base/interfaces/customer-info/customer-info';
import { UpdateCustomerInfoResponse } from '../../../profile-base/interfaces/customer-info/update-cuctomer-info-response';
import { UpdateCustomerInfoRequest } from '../../../profile-base/interfaces/customer-info/update-customer-info-request';
import { UpdatePasswordModel } from '../../../profile-base/interfaces/customer-settings/update-password-model';
import { SettingsActionErrors } from '@settings/store/enums/settings-action-errors';

export const load = createAction('[Additional Profile] Load');

export const setProfileDetails = createAction('[Additional Profile] Set Profile Details', props<{ profile: ChildAccount }>());
export const loadProfileData = createAction('[Additional Profile] Load Profile Data');
export const loadProfileDataSuccessful = createAction('[Additional Profile] Load Profile Data Successful', props<{ customerInfo: CustomerInfo }>());
export const loadProfileDataFailed = createAction('[Additional Profile] Load Profile Data Failed', (error: ApiError) => error);

export const updateCustomerInfo = createAction('[Additional Profile] Update Customer info', props<{ customerInfo: CustomerInfo }>());
export const customerInfoUpdating = createAction('[Additional Profile] Customer info Updating', props<{ customerInfoChanges: UpdateCustomerInfoRequest, previousCustomerInfo: CustomerInfo }>());
export const updateCustomerInfoSuccessful = createAction('[Additional Profile] Update Customer info Successful', props<{ customerInfo: UpdateCustomerInfoResponse }>());
export const updateCustomerInfoFailed = createAction('[Additional Profile] Update Customer info Failed', props<{ error: ApiError, previousCustomerInfo: CustomerInfo }>());

export const updateProfileImage = createAction('[Additional Profile] Update Profile Image', props<{ image: File }>());
export const updateProfileImageSuccessful = createAction('[Additional Profile] Update Profile Image Successful', props<{ profileUrl: string }>());
export const updateProfileImageFailed = createAction('[Additional Profile] Update Profile Image Failed', props<{ error: ApiError, oldProfileUrl: string }>());

export const deleteProfileImage = createAction('[Additional Profile] Delete Profile Image');
export const deleteProfileImageSuccessful = createAction('[Additional Profile] Delete Profile Image Successful');
export const deleteProfileImageFailed = createAction('[Additional Profile] Delete Profile Image Failed', props<{ error: ApiError, oldProfileUrl: string }>());

export const updateAccountData = createAction(
    '[Additional Profile] Update Account Data',
    props<{ newData: NewAccountDataModel, oldData: OldAccountDataModel }>()
);
export const updateAccountDataSuccessful = createAction('[Additional Profile] Update Account Data Successful');
export const updateAccountDataFailed = createAction(
    '[Additional Profile] Update Account Date Failed',
    props<{ error: ApiError, oldData: OldAccountDataModel }>()
);

export const updatePassword = createAction('[Additional Profile] Update Password', props<{ updatePassword: UpdatePasswordModel }>());
export const updatePasswordSuccessful = createAction('[Additional Profile] Update Password Successful');
export const updatePasswordFailed = createAction('[Additional Profile] Update Password Failed', props<{ error: ApiError }>());

export const loadSettings = createAction('[Additional Profile] Load Settings', props<{ customerId: number }>());
export const loadSettingsSuccessful = createAction('[Additional Profile] Load Settings Successful', props<{ settings: CustomerSettings }>());
export const loadSettingsFailed = createAction('[Additional Profile] Load Settings Failed', props<{ error: SettingsActionErrors }>());

export const updateSettingsSuccessful = createAction('[Additional Profile] Update Settings Successful');
export const updateSettingsFailed = createAction('[Additional Profile] Update Settings Failed', props<{ error: SettingsActionErrors, settings: CustomerSettings }>());

export const loadNewMatchSettings = createAction('[Additional Profile] Load New Match Settings');
export const loadNewMatchSettingsSuccessful = createAction('[Additional Profile] Load New Match Settings Successful', props<{ settings: NewMatchSettings }>());
export const loadNewMatchSettingsFailed = createAction('[Additional Profile] Load New Match Settings Failed', props<{ error: SettingsActionErrors }>());

export const updateNewMatchSettings = createAction('[Additional Profile] Update New Match Settings', props<{ request: NewMatchSettingsRequest }>());
export const updateNewMatchSettingsSuccessful = createAction('[Additional Profile] Update New Match Settings Successful');
export const updateNewMatchSettingsFailed = createAction('[Additional Profile] Update New Match Settings Failed', props<{ error: SettingsActionErrors, settings: NewMatchSettings }>());
