<div class="agent-selection-inner">

    <div class="agent-selection-title" *ngIf="texts?.title != null && texts?.title !== ''">
        {{ texts.title | translate }}
    </div>

    <div class="agent-selection-description" *ngIf="texts?.description != null && texts?.description !== ''">
        {{ texts.description | translate }}
    </div>

    <div class="agent-selection-list">

        <ng-container *ngFor="let agentCandidate of agentCandidates">

            <agent-box class="agent-selection-list-item" [isSelected]="selectedCustomerId === agentCandidate.customerId"
                [agentCandidate]="agentCandidate" [isCurrent]="currentCustomerId === agentCandidate.customerId"
                (click)="onAgentSelected(agentCandidate.customerId)">
            </agent-box>

        </ng-container>

    </div>

    <div class="agent-selection-action-wrap" *ngIf="texts?.buttonText != null && texts?.buttonText !== ''">

        <rpc-button class="agent-selection-action agent-selection-action--submit" [appearanceType]="'flat'"
            [mainButton]="true" [color]="'accent'" [title]="texts.buttonText" [disabled]="!canSubmit"
            (clicked)="onAgentSelectionConfirmed()">
        </rpc-button>

    </div>

</div>