<mat-form-field class="rpc-select field-{{appearance}}" [appearance]="appearance" [color]="color"
    [class.rpc-select--readonly]="readonly" [class.rpc-select--without-label]="label == null || label === ''">

    <mat-label class="rpc-select-label" *ngIf="label != null && label !== ''">
        {{ label | translate }}
    </mat-label>

    <mat-select [formControl]="formControl" (selectionChange)="onChanged($event)" (closed)="onClosed()">

        <mat-select-trigger *ngIf="selectedItem != null" class="rpc-selec-box"
            [class.rpc-selec-box-placeholder]="selectedItem.value === -1">

            <rpc-icon class="rpc-select-icon" *ngIf="selectedItem.iconName != null && selectedItem.iconName !== ''"
                [iconName]="selectedItem.iconName">
            </rpc-icon>

            <span class="rpc-select-text">
                {{ shouldTranslate ? (selectedItem.title | translate) : selectedItem.title }}
            </span>

        </mat-select-trigger>

        <ng-content>
        </ng-content>

        <mat-option class="rpc-select-option" [class.rpc-select-option--placeholder]="option.value === -1"
            *ngFor="let option of controlOptions.options" [value]="option.value">

            <rpc-icon class="rpc-select-option-icon" *ngIf="option.iconName != null && option.iconName !== ''"
                [iconName]="option.iconName">
            </rpc-icon>

            <span class="rpc-select-option-text">
                {{ shouldTranslate ? (option.title | translate) : option.title }}
            </span>

        </mat-option>

    </mat-select>

    <mat-error *ngFor="let validationItem of controlOptions.validatorsMap">

        <ng-container *ngTemplateOutlet="showErrorsTemplate, context: {
            $implicit: {
                showError: validationItem.showError,
                message: validationItem.message
            }
        }">
        </ng-container>

    </mat-error>

</mat-form-field>

<ng-template #showErrorsTemplate let-validationItem>

    <div *ngIf="validationItem.showError(formControl)">
        {{ validationItem.message | translate }}
    </div>

</ng-template>