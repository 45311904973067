<div class="general-profile-page" *ngLet="isSelfProfile$ | async as isSelfProfile">

    <div class="general-profile-page-toolbar">

        <rpc-button class="general-profile-page-toolbar-left" *ngIf="isEditMode; else backButton"
            [title]="'GENERAL_INFO_PAGE.TITLES.CANCEL'" (clicked)="onCancel()">
        </rpc-button>

        <ng-template #backButton>

            <rpc-icon-button class="general-profile-page-toolbar-left" [title]="'GENERAL_INFO_PAGE.TITLES.BACK'"
                [iconName]="'left'" [color]="'accent'" (clicked)="onNavigateBack()">
            </rpc-icon-button>

        </ng-template>

        <div class="general-profile-page-toolbar-title">
            {{ 'GENERAL_INFO_PAGE.TITLES.GENERAL_INFO' | translate }}
        </div>

        <rpc-button class="general-profile-page-toolbar-right" *ngIf="isEditMode; else editButton"
            [title]="'GENERAL_INFO_PAGE.TITLES.SAVE'" [color]="'accent'" (clicked)="onSave()">
        </rpc-button>

        <ng-template #editButton>

            <rpc-icon-button class="general-profile-page-toolbar-right" [iconName]="'edit'" [color]="'accent'"
                (clicked)="onEdit()">
            </rpc-icon-button>

        </ng-template>

    </div>

    <ng-container *ngLet="{
            isPrimaryCustomer: isPrimaryCustomer$ | async,
            emails: emails$ | async,
            phones: phones$ | async,
            addresses: addresses$ | async 
        } as data">

        <general-info-form class="general-profile-page-body" *ngIf="isEditMode; else viewMode"
            [isPrimaryCustomer]="data.isPrimaryCustomer" [isSelfProfile]="isSelfProfile" [emails]="data.emails"
            [phones]="data.phones" [addresses]="data.addresses" [isFullVersion]="false">
        </general-info-form>

        <ng-template #viewMode>

            <general-info class="general-profile-page-body" [isPrimaryCustomer]="data.isPrimaryCustomer"
                [isSelfProfile]="isSelfProfile" [emails]="data.emails" [phones]="data.phones"
                [addresses]="data.addresses" [isFullVersion]="false">
            </general-info>

        </ng-template>

    </ng-container>

    <rpc-scroll-to-top class="rpc-scroll-to-top-wrap">
    </rpc-scroll-to-top>

</div>