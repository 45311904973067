import { createAction, props } from '@ngrx/store';

import { FeatureCollection } from '@settings/models/map/feature-collection';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { NewMatchSettings } from '@settings/models/settings/new-match-settings';
import { NewMatchSettingsRequest } from '@settings/models/settings/new-match-settings-request';
import { LayoutSettings } from '@settings/models/settings/layout-settings';
import { SettingsActionErrors } from '../enums/settings-action-errors';

export const loadSettings = createAction('[Settings] Load Settings');
export const loadSettingsSuccessful = createAction('[Settings] Load Settings Successful', (settings: CustomerSettings) => settings);
export const loadSettingsFailed = createAction('[Settings] Load Settings Failed', props<{ error: SettingsActionErrors }>());

export const loadNewMatchSettings = createAction('[Settings] Load New Match Settings');
export const loadNewMatchSettingsSuccessful = createAction('[Settings] Load New Match Settings Successful', props<{ settings: NewMatchSettings }>());
export const loadNewMatchSettingsFailed = createAction('[Settings] Load New Match Settings Failed', props<{ error: SettingsActionErrors }>());

export const updateSettingsRequested = createAction('[Settings] Update Settings Requested', props<{ newSettings: CustomerSettings, oldSettings: CustomerSettings }>());
export const updateSettings = createAction('[Settings] Update Settings', props<{ settings: CustomerSettings }>());
export const updateSettingsSuccessful = createAction('[Settings] Update Settings Successful');
export const updateSettingsFailed = createAction('[Settings] Update Settings Failed', props<{ error: SettingsActionErrors, settings: CustomerSettings }>());

export const updateNewMatchSettings = createAction('[Settings] Update New Match Settings', props<{ request: NewMatchSettingsRequest }>());
export const updateNewMatchSettingsSuccessful = createAction('[Settings] Update New Match Settings Successful');
export const updateNewMatchSettingsFailed = createAction('[Settings] Update New Match Settings Failed', props<{ error: SettingsActionErrors, settings: NewMatchSettings }>());

export const loadNeighborhoodsMapping = createAction('[Settings] Load Neighborhoods Mapping');
export const loadNeighborhoodsMappingSuccessful = createAction(
    '[Settings] Load Neighborhoods Mapping Successful',
    (neighborhoodsMapping: FeatureCollection[]) => ({ neighborhoods: neighborhoodsMapping })
);
export const loadNeighborhoodsMappingFailed = createAction('[Settings] Load Neighborhoods Mapping Failed', props<{ error: SettingsActionErrors }>());

export const saveLoadedSettings = createAction('[Settings] Save Loaded Settings', (settings: CustomerSettings, isInitialSave: boolean) => ({ settings, isInitialSave }));

export const setSettings = createAction('[Settings] Set Settings', props<{ settings: CustomerSettings }>());

export const changeSystemFoldersVisibility = createAction('[Settings] Change System Folders Visibility');

export const resetState = createAction('[Settings] Reset State');

export const updateLayoutSettings = createAction('[Settings] Update Layout Settings', props<{ layoutSettings: Partial<LayoutSettings> }>());