import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Address } from '../../interfaces/customer-info/address';
import { AddressTypes } from '../../interfaces/enums/address-types.enum';

@Component({
    selector: 'addresses-list',
    templateUrl: './addresses-list.component.html',
    styleUrls: ['./addresses-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddressesListComponent {
    @Input()
    public set addresses(addresses: Address[]) {
        this.addressesList = addresses.map(address => ({
            type: this.addressTypesText.get(address.type) ?? '',
            value: this.formatAddress(address)
        }));
    }

    public addressesList: { type: string, value: string }[] = [];

    private readonly addressTypesText = new Map<AddressTypes, string>([
        [AddressTypes.office, 'PROFILE_COMMON.ENUM_TYPES.OFFICE'],
        [AddressTypes.home, 'PROFILE_COMMON.ENUM_TYPES.HOME'],
        [AddressTypes.work, 'PROFILE_COMMON.ENUM_TYPES.WORK'],
        [AddressTypes.other, 'PROFILE_COMMON.ENUM_TYPES.OTHER'],
    ]);

    private formatAddress(address: Address): string {
        const { line1, line2, city, state, zipCode } = address;
        const addressLines = line1 + (line2 == null || line2 === '' ? '' : ' ' + line2);
        const addressCity = city ?? '---';
        const addressState = state == null || state === '' || state === '0' ? '---' : state;

        return `${addressLines}, ${addressCity}, ${addressState}, ${zipCode}`;
    }
}