import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { Creator } from '@appointments/enums/creator.enum';
import { ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import { AppointmentsState } from '@appointments/store/states/appointments.state';
import { AppointmentStatusService } from '../services/appointment-status.service';
import * as settingsSelector from "@settings/store/selectors/settings.selector";
import { AppointmentBaseInfo } from '../models/appointment-info';
import { ICSShareEvent } from '@appointments/models/share-event/ics-share-event';
import { ListingHelperService } from '@listings/services/listing-helper.service';

const appointmentsStateSelector = createFeatureSelector<AppointmentsState>('appointments');

export const selectAppointmentError = createSelector(
    appointmentsStateSelector,
    (state) => state?.error
);

export const selectIsSingleAppointmentLoaded = createSelector(
    appointmentsStateSelector,
    (state) => state?.isSingleAppointmentLoaded
);

export const selectAppointmentsLodaded = createSelector(
    appointmentsStateSelector,
    appointmentsState => appointmentsState?.appointmentsLoaded ?? false
);

export const selectListingsAppointments = createSelector(
    appointmentsStateSelector,
    (state) => state?.listingsAppointments
);

export const selectIsAppointmentsLoadding = createSelector(
    appointmentsStateSelector,
    (state) => state?.isAppointmentsLoading ?? false
);

export const selectFlatListingsAppointments = createSelector(
    selectListingsAppointments,
    (listingsAppointments) => listingsAppointments == null
        ? []
        : Object.keys(listingsAppointments).flatMap(key => listingsAppointments[key] as ListingAppointment[])
);

export const selectFlatListingsAppointmentsCount = createSelector(
    selectFlatListingsAppointments,
    (flatListingsAppointments) => flatListingsAppointments.filter(appointment => moment(appointment.startDateTime).isAfter(new Date(), 'minute')
        && appointment.customerStatus !== AppointmentStatus.Declined).filter(appointment => appointment.agentStatus !== AppointmentStatus.Declined).length
);

export const selectCalendarEvent = (appointmentInfo: AppointmentBaseInfo) => createSelector(
    settingsSelector.selectHideOpenRentals,
    (isHideOpenRentals) => {
        const { listing } = appointmentInfo;
        const listingAddress = listing != null ? ListingHelperService.getListingFullAddress(listing, isHideOpenRentals) : '';

        return new ICSShareEvent(listingAddress, appointmentInfo.appointment);
    }
);

export const selectListingAppointments = (listingIdHashCode: number) => createSelector(
    selectListingsAppointments,
    listingsAppointments => listingsAppointments[listingIdHashCode] as ListingAppointment[]
);

export const selectListingAppointmentCount = (listingIdHashCode: number) => createSelector(
    selectListingAppointments(listingIdHashCode),
    appointments => appointments?.length ?? 0
);

export const selectListingAppointmentNewCount = (listingIdHashCode: number) => createSelector(
    selectListingAppointments(listingIdHashCode),
    listingAppointments => AppointmentStatusService
        .filterByStatus(listingAppointments, AppointmentStatus.Pending)
        .filter(appointment => appointment.creator === Creator.Agent).length
);

export const selectUnviewedAppointmentsCount = createSelector(
    selectFlatListingsAppointments,
    appointments => AppointmentStatusService
        .filterByStatus(appointments, AppointmentStatus.Pending)
        .filter(appointment => appointment.creator === Creator.Agent).length
);