import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { serverMessagesMapping } from '@auth/pages/forgot-password/server-message-mapping/server-message-mapping';
import * as restorePaswordActions from '@auth/store/actions/restore-password.actions';
import { RestorePasswordEffects } from '@auth/store/effects/restore-password.effects';
import { AuthStoreService } from '@auth/store/services/auth-store.service';
import { fadeInScaleAnimation } from '@core-animations/element-animations';
import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ServerMessageService } from '@core-services/server-message.service';
import { ToastService } from '@core-services/toast.service';
import { ApiError } from '@error/models/api-error';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../../auth-base/auth-base.component.scss', './forgot-password.component.scss'],
    animations: [fadeInScaleAnimation]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    public forgotPasswordForm: FormGroup;
    public userNameControlOptions: RpcInputOptions;
    public companyConfiguration: CompanyConfiguration;

    constructor(
        private readonly authStoreService: AuthStoreService,
        private readonly restorePasswordEffects: RestorePasswordEffects,
        private readonly configurationService: AppConfigurationService,
        private readonly routeService: RouteService,
        private readonly toaster: ToastService,
        private readonly serverMessageService: ServerMessageService
    ) {
        configurationService.configuration = { layout: { spinner: { hidden: true } } };

        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }

    public ngOnInit(): void {
        this.forgotPasswordForm = new FormGroup({});

        this.userNameControlOptions = {
            formGroup: this.forgotPasswordForm,
            controlName: 'userName',
            validatorsMap: [
                {
                    message: 'FORGOT_PASSWORD.VALIDATION_ERRORS.EMAIL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'FORGOT_PASSWORD.VALIDATION_ERRORS.EMAIL_VALID',
                    showError: (control: FormControl) => control.hasError('email'),
                    validator: Validators.email
                }
            ]
        };

        this.restorePasswordEffects.sendPasswordRestoreLink$
            .pipe(
                ofType(restorePaswordActions.sendRestorePasswordLinkSuccessful),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                () => {
                    this.routeService.navigate(RpcRoute.Login)
                        .then(() => this.toaster.showClientSuccess('FORGOT_PASSWORD.MESSAGES.LINK_SENT_SUCCESSFULLY'))
                        .catch(() => { });
                });

        this.restorePasswordEffects.sendPasswordRestoreLink$
            .pipe(
                ofType(restorePaswordActions.sendRestorePasswordLinkFailed),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                (error: ApiError) => this.showErrorMessage(error));

        this.configurationService.configuration$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(configuration => this.companyConfiguration = configuration.company);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public sendEmail(): void {
        const userName = this.forgotPasswordForm.get('userName').value as string;

        this.authStoreService.sendRestorePasswordLink(userName);
    }

    private showErrorMessage(error: ApiError): void {
        this.toaster.showServerError(error);
    }
}