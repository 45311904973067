import { Injectable } from '@angular/core';
import { EMPTY, delay, filter, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { NavigationExtras } from '@angular/router';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { NotificationsApiService } from '../services/notifications-api.service';
import * as notificationsActions from '../actions/notifications.actions';
import { NotificationEventEntity } from '@notifications/enums/notification-event-entity';
import * as listingsActions from '@listings/store/actions/listings.actions';
import { NotificationsService } from '../services/notifications.service';
import { NotificationsStoreService } from '../services/notifications-store.service';
import { RouteService } from '@core-layout/app/services/route.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { StoreNotification } from '@notifications/models/store-notification';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { NOT_SHOWN_NOTIFICATIONS, NotShownNotifications } from '@notifications/constants/notifications.constants';
import { NotificationEntityType } from 'app/graphql/graphql';
import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';
import { NOTIFICATION_TARGET_SCROLL_TO } from '@notifications/constants/notifications.constants';
import { ListingDetailsSectionName } from '@listings/enums/listing-details-section-name';
import { InternalListingDetailsNavigationService } from '@listing-details-navigation/services/listing-details-navigation.service';
import { NavigationItem } from '@listing-details-navigation/models/navigation-item';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { MatchMediaService } from '@media/services/match-media.service';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';

@Injectable()
export class NotificationsEffects {
    public readonly LISTINGS_LOADING_DELAY = 1000;

    constructor(
        private readonly actions$: Actions,
        private readonly userStoreService: UserStoreService,
        private readonly notificationsApiService: NotificationsApiService,
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly routeService: RouteService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly internalListingDetailsNavigationService: InternalListingDetailsNavigationService<NavigationItem>,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly scrollService: ScrollService,
        private readonly matchMediaService: MatchMediaService,
        private readonly hybridService: HybridService
    ) { }

    public readonly loadNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.loadNotifications),
        switchMap(() => this.notificationsApiService.getNotifications())
    ));

    public readonly loadNotificationsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(notificationsActions.loadNotificationsSuccess),
            concatLatestFrom(() => [this.userStoreService.customerId$, this.userStoreService.getAgent()]),
            map(([{ apiNotifications }, customerId, agent]) => {
                const notificationsToShow = apiNotifications.reduce(
                    (notificationsToShow, notification) => {
                        return NOT_SHOWN_NOTIFICATIONS.includes(notification.action as NotShownNotifications)
                            ? notificationsToShow
                            : [...notificationsToShow, NotificationsService.mapToNotificationBaseModel(notification, customerId, agent.id)];
                    },
                    new Array<StoreNotification>()
                );

                const notifications = NotificationsService.getNotificationsWithoutOutdated(notificationsToShow);

                return notificationsActions.setNotifications({ notifications });
            })
        );
    });

    public readonly setActiveTabIndex$ = createEffect(
        () => this.actions$.pipe(
            ofType(notificationsActions.setActiveTabIndex),
            concatLatestFrom(() => this.hybridService.isHybrid$),
            tap(([{ index }, isHybrid]) => {
                const isMobile = new Set<string>(['xs', 'sm', 'md']).has(this.matchMediaService.activeMediaQuery);

                isHybrid || isMobile
                    ? this.scrollService.scrollInContainer('#' + NOTIFICATION_TARGET_SCROLL_TO)
                    : this.scrollService.scrollToElementById(NOTIFICATION_TARGET_SCROLL_TO);

                this.googleAnalyticsStoreService.addNotificationBellViewEvent(index);
            })
        ),
        { dispatch: false }
    );

    public readonly viewUnviewCreatorNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.viewUnviewCreatorNotifications),
        map(({ creatorNotifications: { notifications, isViewed } }) => {
            const notificationIds = NotificationsService.getNotificationIdsByViewed(notifications, isViewed);

            return notificationsActions.setNotificationsViewedUnviewed({ notificationIds });
        })
    ));

    public readonly viewUnviewGroupNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.viewUnviewGroupNotifications),
        map(({ group: { groups, isViewed } }) => {
            const notificationIds = groups.reduce(
                (ids, { notifications }) => [...ids, ...NotificationsService.getNotificationIdsByViewed(notifications, isViewed)],
                new Array<number>()
            );

            return notificationsActions.setNotificationsViewedUnviewed({ notificationIds });
        })
    ));

    public readonly setNotificationsViewedUnviewed$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.setNotificationsViewedUnviewed),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        mergeMap(([{ notificationIds, isKeepViewed }, notifications]) => {
            const updatedNotifications = NotificationsService.viewUnviewNotifications(notifications, notificationIds, isKeepViewed);

            return [
                notificationsActions.setNotifications({ notifications: updatedNotifications }),
                notificationsActions.viewUnviewNotifications({ notificationIds }),
            ];
        })
    ));

    public readonly viewUnviewNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.viewUnviewNotifications),
        filter(({ notificationIds }) => notificationIds.length > 0),
        mergeMap(({ notificationIds }) => this.notificationsApiService.viewUnviewNotifications(notificationIds))
    ));

    public readonly viewUnviewNotificationsFailed$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.viewUnviewNotificationsFailed),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        map(([{ notificationIds }, notifications]) => {
            const updatedNotifications = NotificationsService.viewUnviewNotifications(notifications, notificationIds);

            return notificationsActions.setNotifications({ notifications: updatedNotifications });
        })
    ));

    public readonly markAllViewed$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.markAllViewed),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        switchMap(([, notifications]) => {
            const notificationIds = notifications.reduce((ids, x) => x.isViewed ? ids : [...ids, x.notificationId], Array<number>());

            return of(notificationsActions.setNotificationsViewedUnviewed({ notificationIds }));
        })
    ));

    public readonly removeListingsNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(listingsActions.hardDeleteSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ listingsHashCodes }, collaborationId]) => {
            return this.notificationsApiService.removeListingsNotifications({ listingsIds: listingsHashCodes, collaborationId });
        })
    ));

    public readonly removeListingsNotificationsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.removeListingsNotificationsSuccess),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        map(([{ listingsHashCodes }, currentNotifications]) => {
            const hashCodes = new Set(listingsHashCodes);
            const notifications = currentNotifications.filter(x => !hashCodes.has(x.listingId));

            return notificationsActions.setNotifications({ notifications });
        })
    ));

    public readonly redirectToNotificationEntity$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.redirectToNotificationEntity),
        concatLatestFrom(() => [this.notificationsStoreService.flatNotifications$, this.listingsStoreService.getListings()]),
        switchMap(([{ notificationId, goToAll }, notifications, listings]) => {
            const notification = notifications.find(x => x.notificationId === notificationId);
            const listing = Object.values(listings).find(x => x.hashCode === notification.listingId);

            const { route, extras, params } = new Map<NotificationEventEntity, { route: RpcRoute, extras?: NavigationExtras, params?: string }>([
                [NotificationEventEntity.ExternalListing, { route: RpcRoute.Portfolio }],
                [NotificationEventEntity.ListingActivity, { route: RpcRoute.Listing, params: listing?.id }],
                [NotificationEventEntity.Appointment, goToAll ? { route: RpcRoute.Appointments } : { route: RpcRoute.Listing, extras: { state: { tab: ListingDetailsSectionName.Appointments } }, params: listing?.id }],
                [NotificationEventEntity.Comment, { route: RpcRoute.Listing, extras: { state: { tab: ListingDetailsSectionName.Comments } }, params: listing?.id }],
            ]).get(notification.type);

            this.internalListingDetailsNavigationService.setNavigation();

            this.routeService.navigate(route, extras, RpcRoute.Portfolio, params).catch(() => { });

            this.googleAnalyticsStoreService.addNotificationBellRedirectEvent(notification.type);

            const actions = notification.isViewed ? [] : [notificationsActions.setNotificationsViewedUnviewed({ notificationIds: [notificationId] })];

            return actions.length > 0 ? actions : EMPTY;
        })
    ));

    public readonly loadNotificationListings$ = createEffect(
        () => this.actions$.pipe(
            ofType(notificationsActions.loadNotificationsSuccess),
            delay(this.LISTINGS_LOADING_DELAY),
            concatLatestFrom(() => [this.listingsStoreService.getCustomerListings(), this.listingsStoreService.isListingsLoading$]),
            switchMap(([{ apiNotifications }, listings, isListingsLoading]) => {
                const loadedHashCodes = new Set(listings.map(x => x.hashCode));

                const listingsToLoadHashCodes = apiNotifications.reduce((acc, x) => loadedHashCodes.has(x.listingId) || x.listingId == null ? acc : acc.add(x.listingId), new Set<number>());

                return isListingsLoading ? EMPTY : [listingsActions.loadListinsgByHashCodes({ hashCodes: [...listingsToLoadHashCodes] })];
            })
        )
    );

    public readonly updateAppointmentNotificationStatus$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.changeAppointmentStatus, appointmentsActions.changeAppointmentStatusFailed),
        concatLatestFrom(() => [this.notificationsStoreService.flatNotifications$, this.userStoreService.getAgent()]),
        switchMap(([{ request: { appointmentId, customerStatus, previousCustomerStatus, updateId } }, currentNotifications, agent]) => {

            if (previousCustomerStatus === AppointmentStatus.Confirmed && customerStatus === AppointmentStatus.Declined) {
                return EMPTY;
            }

            const notifications = NotificationsService.updateAppointmentCreatedNotification(
                currentNotifications,
                appointmentId,
                previousCustomerStatus,
                customerStatus,
                updateId,
                agent.id
            );

            return of(notificationsActions.setNotifications({ notifications }));
        })
    ));

    public readonly removeAppointmentNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.updateListingAppointmentSuccess),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        map(([{ request }, currentNotifications]) => {
            const notifications = currentNotifications.reduce(
                (updatedNotifications, x) => x.entityId !== request.listingAppointment.id ? [...updatedNotifications, x] : updatedNotifications,
                new Array<StoreNotification>()
            );

            return notificationsActions.setNotifications({ notifications });
        })
    ));

    public readonly removeNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.removeNotifications),
        mergeMap(({ ids }) => this.notificationsApiService.removeNotifications(ids))
    ));

    public readonly removeNotificationsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.removeNotificationsSuccess),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        map(([{ ids }, currentNotifications]) => {
            const notificationsIds = new Set(ids);
            const notifications = currentNotifications.filter(x => !notificationsIds.has(x.notificationId));

            return notificationsActions.setNotifications({ notifications });
        })
    ));

    public readonly removeEntitiesNotifications$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.removeEntitiesNotifications),
        mergeMap(({ entitiesIds, entityType }) => {
            return this.notificationsApiService.removeEntitiesNotifications({ entitiesIds, entityType: entityType as unknown as NotificationEntityType });
        })
    ));

    public readonly removeEntityNotificationsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.removeEntitiesNotificationsSuccess),
        concatLatestFrom(() => this.notificationsStoreService.flatNotifications$),
        map(([{ entitiesIds }, currentNotifications]) => {
            const notificationsIds = new Set(entitiesIds);
            const notifications = currentNotifications.filter(x => !notificationsIds.has(x.entityId));

            return notificationsActions.setNotifications({ notifications });
        })
    ));

    public readonly showOnlyUnviewedFilterChanged$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.showOnlyUnviewedFilterChanged),
        concatLatestFrom(() => this.notificationsStoreService.isShowOnlyUnviewedNotifications$),
        map(([, showOnlyUnreadNotifications]) => settingsActions.updateLayoutSettings({ layoutSettings: { showOnlyUnreadNotifications } }))
    ));
}