export const locale = {
    lang: 'en',
    data: {
        EMAILS_EDIT_FORM: {
            TITLES: {
                LOGIN_EMAIL: 'LOGIN EMAIL',
                EMAIL: 'Email',
                DELETE: 'Delete',
                TYPE: 'Type',
                EMAIL_ADDRESS: 'Email Address',
                ADD_NEW: 'Add New',
                OFFICE: 'Office',
                PERSONAL: 'Personal',
                OTHER: 'Other',
                ADD_CC: 'Add as CC',
                ADD_CC_EPLANATION: 'Add an email address as CC for email notifications.',
                LOGIN_DISABLE_EXPLANATION: 'Your login email address can not be edited.'
            },
            ERRORS: {
                CLIENT: {
                    EMAIL_TYPE_REQUIRED: 'Email type is required',
                    EMAIL_REQUIRED: 'Email is required',
                    EMAIL_INVALID: 'Please enter a valid email address',
                    EMAIL_NOT_UNIQUE: 'Please enter a unique email address'
                }
            }
        }
    }
};