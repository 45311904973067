import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { RpcInputOptions } from '../rpc-input/models/rpc-input-options';

@Component({
    selector: 'password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent {
    @Input() options: RpcInputOptions;
    @Input() label?: string;
    @Input() autocomplete?: string;
    @Output() changed = new EventEmitter<string>();

    public type: 'password' | 'text' = 'password';
    public iconName: 'eye' | 'eye-crossed' = 'eye';

    public onInput(value: string): void {
        this.changed.emit(value);
    }

    public onIconClicked(event: MouseEvent): void {
        event.stopPropagation();

        [this.type, this.iconName] = this.type === 'password' ? ['text', 'eye-crossed'] : ['password', 'eye'];
    }
}
