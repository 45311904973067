<div class="f-management-wrap" *ngLet="listings$ | async as listings"
    [class.f-management-wrap--vertical]="listings?.length === 1">

    <div class="f-management-title-wrap">

        <div class="f-management-title">
            {{ 'FOLDER_MANAGEMENT_DIALOG.TITLES.FOLDER_MANAGEMENT' | translate }}
        </div>

        <rpc-icon-button class="f-management-title-close" [iconName]="'cross'"
            [title]="'FOLDER_MANAGEMENT_DIALOG.TITLES.CANCEL'" [mat-dialog-close]="false">
        </rpc-icon-button>

    </div>

    <div class="f-management-action-wrap">

        <rpc-button class="f-management-action" [appearanceType]="'stroke'"
            [title]="'FOLDER_MANAGEMENT_DIALOG.TITLES.CANCEL'" [mainButton]="true" (clicked)="close()">
        </rpc-button>

        <rpc-button class="f-management-action" [appearanceType]="'flat'"
            [title]="'FOLDER_MANAGEMENT_DIALOG.TITLES.SAVE'" [color]="'accent'" [mainButton]="true"
            [disabled]="isSaveButtonDisabled(listings)" (clicked)="onSaveClicked()">
        </rpc-button>

    </div>

    <div class="f-management-box f-management-box--first">

        <listings-list [listings]="listings" (unselectListingClicked)="onUnselectListingClicked($event)">
        </listings-list>

    </div>

    <div class="f-management-box f-management-box--second">

        <div class="f-management-box-title-wrap">

            <div class="f-management-box-title">
                {{ 'FOLDER_MANAGEMENT_DIALOG.TITLES.ADD_TO' | translate }}
            </div>

            <rpc-menu-button #rpcMenuButtonApi="rpcMenuButtonApi" class="f-management-box-add"
                [title]="'FOLDER_MANAGEMENT_DIALOG.TITLES.NEW_FOLDER'" [iconName]="'plus'" [menuIconVisible]="false"
                [menuActionClass]="'folder-edit-wrap'">

                <div class="f-management-box-add-menu">

                    <div class="f-management-box-add-title">
                        {{ 'FOLDER_MANAGEMENT_DIALOG.TITLES.CREATE_NEW_FOLDER' | translate }}
                    </div>

                    <add-edit-folder-form #createFolderForm
                        [submitButtonText]="'FOLDER_MANAGEMENT_DIALOG.TITLES.CREATE_FOLDER'"
                        [additionalFormClass]="'folder-edit-form--menu'"
                        (save)="onCreateFolderClicked($event); rpcMenuButtonApi.closeMenu();"
                        (canceled)="onCancelCreationClicked(); rpcMenuButtonApi.closeMenu()">
                    </add-edit-folder-form>

                </div>

            </rpc-menu-button>

        </div>

        <div id="folderSelections" class="f-management-folder-wrap">

            <folder-selection class="f-management-folder-item"
                *ngFor="let folder of folders$ | async; trackBy: trackByFolderId" [id]="folder.id" [folder]="folder"
                [listings]="listings" [isEditModeEnabled]="editedFolderId === folder.id"
                [isEditButtonHidden]="editedFolderId != null" (editClicked)="onEditClicked($event)"
                (renameClicked)="onRenameClicked($event)" (cancelClicked)="onCancelRenameClicked()">
            </folder-selection>

        </div>

    </div>

</div>