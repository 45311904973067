export const locale = {
    lang: 'en',
    data: {
        SAVED_SEARCH_LIST: {
            TITLES: {
                CLOSE: 'Close',
                SAVE_YOUR_SEARCH: 'Save Your Search',
                SAVED_SEARCHES: 'Saved Searches',
            }
        }
    }
};