import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable } from 'rxjs';

import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NOTIFICATION_TARGET_SCROLL_TO } from '@notifications/constants/notifications.constants';
import { Notifications } from '@notifications/models/notifications';
import { Counters } from '@notifications/models/counters';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent {
    @Output() public readonly notificationSourceNavigated = new EventEmitter<NotificationNavigateParams>();
    @Output() public readonly closed = new EventEmitter<void>();

    public readonly notificationTargetScrollTo = NOTIFICATION_TARGET_SCROLL_TO;
    public readonly tabsData: { title: string; notifications$: Observable<Notifications>, counterKey: keyof Counters | '' }[] = [
        { title: 'NOTIFICATIONS.TITLES.ALL', notifications$: this.notificationsStoreService.allNotitifications$, counterKey: '' },
        { title: 'NOTIFICATIONS.TITLES.MESSAGES', notifications$: this.notificationsStoreService.messageNotitifications$, counterKey: 'messages' },
        { title: 'NOTIFICATIONS.TITLES.APPOINTMENTS', notifications$: this.notificationsStoreService.appointmentNotitifications$, counterKey: 'appointments' },
        { title: 'NOTIFICATIONS.TITLES.LISTINGS', notifications$: this.notificationsStoreService.listingNotitifications$, counterKey: 'listings' },
    ];

    public readonly isShowOnlyUnviewedNotifications$ = this.notificationsStoreService.isShowOnlyUnviewedNotifications$;
    public readonly unreadNotificationsCounters$ = this.notificationsStoreService.unviewedNotificationsCounters$;
    public readonly flatNotifications$ = this.notificationsStoreService.flatNotifications$;
    public readonly activeTabIndex$ = this.notificationsStoreService.activeTabIndex$;

    constructor(
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
    ) { }

    public onShowOnlyUnviewedFilterChanged(value: boolean): void {
        this.googleAnalyticsStoreService.addNotificationBellToggleEvent(value);
        this.notificationsStoreService.showOnlyUnviewedFilterChanged();
    }

    public onSelectedTabChanged(tab: MatTabChangeEvent): void {
        this.notificationsStoreService.setActiveTabIndex(tab.index);
    }

    public onNavigateToNotificationEntity(notificationNavigateParams: NotificationNavigateParams): void {
        this.notificationSourceNavigated.emit(notificationNavigateParams);
    }

    public onMarkAllViewed(): void {
        this.notificationsStoreService.markAllViewed();
    }

    public onClose(): void {
        this.closed.emit();
    }
}