<div class="f-listing-wrap" *ngIf="listings != null">

    <div class="f-listing-title">
        {{ 'LISTINGS_LIST.TITLES.LISTINGS' | translate : { count: listings.length } }}
    </div>

    <div class="f-listing-list">

        <div class="f-listing-item" [class.f-listing-item--removed]="isMarkedRemoved && listing.isDeleted"
            *ngFor="let listing of listings">

            <default-image class="f-listing-item-img" [image]="listing.image?.desktopUrl" [useBackgroundImage]="true"
                [isDummy]="listing.image?.isDummy">
            </default-image>

            <div class="f-listing-item-address text-truncate" [title]="listing.listingAddress">
                {{ listing.listingAddress }}
            </div>

            <div class="f-listing-item-price-box">

                <last-price-change class="f-listing-item-price" [prices]="listing.prices">
                </last-price-change>

                <div class="f-listing-item-removed" *ngIf="isMarkedRemoved && listing.isDeleted">
                    {{ 'LISTINGS_LIST.TITLES.REMOVED' | translate }}
                </div>

            </div>

            <rpc-icon-button class="f-listing-item-btn" *ngIf="listings.length > 1" [color]="'accent'"
                [iconName]="'decline'" [title]="'LISTINGS_LIST.TITLES.REMOVE'"
                (clicked)="onUnselectClicked(listing.listingId)">
            </rpc-icon-button>

        </div>

    </div>

</div>