import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IInviteUserModel } from '../../invite-user/invite-user-model';
import { InviteUserFormComponent } from '../invite-user-form/invite-user-form.component';

@Component({
    selector: 'invite-user-dialog',
    templateUrl: './invite-user-dialog.component.html',
    styleUrls: ['./invite-user-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserDialogComponent {
    @ViewChild(InviteUserFormComponent) private readonly inviteUserFormComponent: InviteUserFormComponent;

    constructor(
        private readonly dialogRef: MatDialogRef<InviteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inviteDialogData: { email?: string, portfolioEmails: string[] }
    ) { }

    public get isFormInvalid(): boolean {
        return this.inviteUserFormComponent?.inviteUserFormGroup?.invalid;
    }

    public sendEmail(): void {
        if (this.isFormInvalid) {
            return;
        }

        const inviteUserModel = this.inviteUserFormComponent.inviteUserFormGroup.getRawValue() as IInviteUserModel;

        this.dialogRef.close(inviteUserModel);
    }

    public close(): void {
        this.dialogRef.close();
    }
}
