import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AgentCandidate } from '@agents/models/agent-candidate';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';

@Component({
    selector: 'change-agent-dialog',
    templateUrl: './change-agent-dialog.component.html',
    styleUrls: ['./change-agent-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeAgentDialogComponent {
    public readonly currentCustomerId$ = this.userStoreService.customerId$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly agentCandidates: AgentCandidate[],
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService,
        private readonly dialogRef: MatDialogRef<ChangeAgentDialogComponent>,
        private readonly userStoreService: UserStoreService,
    ) { }

    public onSpaceSelected(customerId: number): void {
        this.collaborationSpaceStoreService.switchCollaborationSpace(customerId);

        this.dialogRef.close();
    }

    public onClose(): void {
        this.dialogRef.close();
    }
}
