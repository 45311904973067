import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { Folder } from '@folders/models/folder';
import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model'; import { FolderSavedSearch } from '@folders/models/folder-saved-search';
import { ListingFolderIdsMap } from '@folders/models/listing-folder-ids-map';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { RemoveFolderListingsModel } from '@folders/models/remove-folder-listings-model';
import { IRemoveFolderModel } from '@folders/models/remove-folder-model';
import { FolderNewMatch } from '@folders/models/folder-new-match';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';
import { AddEditFolderParams } from '@folders/models/add-edit-folder-params';
import { FolderDetails } from '@folders/models/folder-details';

export const load = createAction('[Folders] Load');

export const loadFolders = createAction('[Folders] Load Folders', props<{ shouldSetLoading: boolean }>());
export const loadFoldersSuccess = createAction('[Folders] Load Folders Success', props<{ folders: Folder[] }>());
export const loadFoldersFailed = createAction('[Folders] Load Folders Failed', (error: ApiError) => error);

export const loadListingFolderIdsMappings = createAction('[Folders] Load Listing Folder Ids Mappings');
export const loadListingFolderIdsMappingsSuccess = createAction(
    '[Folders] Load Listing Folder Ids Mappings Success',
    props<{ listingFolderIdsMap: ListingFolderIdsMap[] }>()
);
export const loadListingFolderIdsMappingsFailed = createAction('[Folders] Load Listing Folder Ids Mappings Failed', (error: ApiError) => error);

export const setDefaultFolder = createAction('[Folders] Set Default Folder', props<{ folderId: number | null }>());

export const update = createAction('[Folders] Update Folder Name', props<{ folderData: IManageFolderModel }>());
export const updateSuccess = createAction('[Folders] Update Folder Name Success', props<{ folderData: IManageFolderModel }>());
export const updateFailed = createAction('[Folders] Update Folder Name Failed', (error: ApiError) => error);

export const create = createAction('[Folders] Create folder', props<{ name: string }>());
export const createSuccess = createAction('[Folders] Create Folder Success', props<{ id: number, name: string, customerId: number }>());
export const createFailed = createAction('[Folders] Create Folder Failed', (error: ApiError) => error);

export const selectFolder = createAction('[Folders] Select Folder', props<{ folderId: number }>());
export const unselectFolder = createAction('[Folders] Unselect Folder');

export const resetState = createAction('[Folders] Reset State');

export const remove = createAction('[Folders] Remove Folder', props<{ model: IRemoveFolderModel, isFromStateOnly?: boolean }>());
export const removeSuccess = createAction('[Folders] Remove Folder Success', props<{ model: RemoveFolderListingsModel }>());
export const removeFailed = createAction('[Folders] Remove Folder Failed', props<{ error: ApiError, folders: Folder[] }>());

export const openFolderManagementDialog = createAction('[Folders] Open Folder Management Dialog', props<{ shouldUnselectListings: boolean, listingId?: string }>());

export const manageListingsFolders = createAction(
    '[Folders] Manage Listings Folders',
    props<{ models: FolderManagerModel[], shouldUnselectListings: boolean }>()
);
export const manageListingsFoldersSuccess = createAction(
    '[Folders] Manage Listings Folders Success',
    props<{ models: FolderManagerModel[], activitiesSet: ListingActivityId[] }>()
);
export const manageListingsFoldersFailed = createAction(
    '[Folders] Manage Listings Folders Failed',
    (error: ApiError, models: FolderManagerModel[]) => ({ error, models })
);

export const updateFolderSavedSearch = createAction(
    '[Folders] Update Folder Saved Search',
    props<{ currentFolderId?: number, savedSearchId?: number, savedSearchName?: string }>()
);

export const attachListingsToFolder = createAction(
    '[Folders] Attach Listings To Folder',
    props<{ folderId: number, listingsIds: number[] }>()
);

export const detachListingsFromFolder = createAction(
    '[Folders] Detach Listings From Folder',
    props<{ folderId: number, listingsIds: number[] }>()
);

export const loadFolderSavedSearches = createAction('[Folders] Load Folder Saved Searches');
export const loadFolderSavedSearchesSuccess = createAction('[Folders] Load Folder Saved Searches Success', props<{ folderSavedSearches: FolderSavedSearch[] }>());
export const loadFolderSavedSearchesFailed = createAction('[Folders] Load Folder Saved Searches Failed', props<{ error: ApiError }>());

export const attachListingsToSavedSearchFolder = createAction('[Folders] Attach Listings To Saved Search Folder', props<{ shouldUnselectListings: boolean, listingId?: string }>());

export const openFolderAttachmentDialog = createAction('[Folders] Open Folder Attachment Dialog', props<{ folderId: number, shouldUnselectListings: boolean, listingId?: string }>());

export const loadFolderNewMatches = createAction('[Folders] Load Folder New Matches');
export const loadFolderNewMatchesSuccess = createAction('[Folders] Load Folder New Matches Success', props<{ folderNewMatches: FolderNewMatch[] }>());
export const loadFolderNewMatchesFailed = createAction('[Folders] Load Folder New Matches Failed', props<{ error: ApiError }>());

export const updateFolderMappings = createAction('[Folders] Update Folder Ids Mappings', props<{ newMatchIds: number[] }>());

export const setFolderMappings = createAction('[Folders] Set Folder Mappings', props<{ updatedListingsFolderIdsMapping: ListingFolderIdsMap[], updatedFolderNewMatchIds: FolderNewMatch[] }>());
export const setListingFolderMapping = createAction('[Folders] Set Listing Folder Mappings', props<{ listingFolderIdsMap: ListingFolderIdsMap[] }>());
export const showAddEditFolderDialog = createAction('[Folders] Show Add Edit Folder Dialog', props<{ params: AddEditFolderParams }>());

export const deleteSavedSearchFolders = createAction('[Folders] Delete Saved Search Folders', props<{ savedSearchId: number }>());

export const showFolderDetails = createAction('[Folders] Show Folder Details', props<{ folder: FolderDetails }>());
export const showFolderDetailsDialog = createAction('[Folders] Show Folder Details Dialog', props<{ folder: FolderDetails, isDefault: boolean }>());
export const showFolderDetailsBottomSheet = createAction('[Folders] Show Folder Details Bottom Sheet', props<{ folder: FolderDetails, isDefault: boolean }>());

export const showRemoveFolderDialog = createAction('[Folders] Show Remove Folder Dialog', props<{ id: number, actionOnSuccess: () => void }>());