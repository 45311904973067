import { Injectable } from '@angular/core';
import { MessageHandler } from './message-handler';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { ActivityType } from '@customer-activity/models/activity-type';
import { filter, take } from 'rxjs';
import { ApplicationUpdateService } from '@notifications/services/application-update.service';

export interface LoginHappenedMessage {
}

@Injectable({
    providedIn: 'root'
})
export class LoginHappenedMessageHandler implements MessageHandler<LoginHappenedMessage> {

    constructor(private readonly customerActivityService: CustomerActivityService,
                private readonly applicationUpdateService: ApplicationUpdateService) {
    }

    public handle(message: LoginHappenedMessage): void {
        this.applicationUpdateService.getCurrentVersion().pipe(
            filter(version => version != null),
            take(1)
        ).subscribe(() => {
            this.customerActivityService.addActivity(ActivityType.Login)
                .subscribe();
        });
    }
}

