export class DateConstants {
    public static Formats = {
        ShortDateSlash: 'MM/DD/YYYY',
        FullMonthDate: 'D MMMM YYYY',
        MonthDate: 'MMMM YYYY',
        AngularPipeDay: 'd',
        ShortDateMinus: 'YYYY-MM-DD',
        ShortTime: 'h:mm A',
        AngularPipeShortDateSlash: 'MM/dd/yyyy',
        AngularPipeShortTime: 'h:mm a',
        AngularPipeShortTimeWithoutPeriod: 'h:mm',
        DayOfWeek: 'dddd',
        AngularPipeDayOfWeek: 'EEEE',
        DayAndMonth: 'EEE. MMM d',
        IsoWithoutTimezoneOffset: 'YYYY-MM-DDTHH:mm:ss'
    };
}