export const locale = {
    lang: 'en',
    data: {
        CHANGE_PASSWORD: {
            TITLES: {
                HEADER: 'Change Password'
            },
            VALIDATION_ERRORS: {
                PASSWORD_REQUIRED: 'Password is required',
                PASSWORD_CONFIRMATION_REQUIRED: 'Password confirmation is required',
                PASSWORDS_NOT_MATCH: 'Passwords must match',
                PASSWORDS_MATCH: 'New password cannot repeat your previous password',
                NUMBER_REQIRED: 'At least 1 digit is required',
                CAPITAL_REQUIRED: 'At least 1 capital character is required',
                SMALL_REQUIRED: 'At least 1 small character is required',
                SPECIAL_REQIRED: 'At least 1 special character is required',
                MINIMUM_LENGTH: 'Password must be at least 6 characters',
                MAXIMUM_LENGTH: 'Password must be not more than 16 characters.'
            },
            FIELDS: {
                OLD_PASSWORD: 'Old password',
                NEW_PASSWORD: 'New password',
                PASSWORD_CONFIRM: 'New password (confirm)'
            },
            BUTTONS: {
                CANCEL: 'cancel',
                SAVE: 'Save',
                CHANGE_PASSWORD: 'Change password',
                UPDATE_PASSWORD: 'Update password'
            }
        }
    }
};