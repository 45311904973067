import { SettingsActionErrors } from '../enums/settings-action-errors';

export class LayoutFilterSettingsConstants {
    public static readonly allUsers = 'All_Users';
}

export const SETTINGS_ACTION_ERRORS_LOCALIZAION = new Map<SettingsActionErrors, string>([
    [SettingsActionErrors.SettingsLoading, 'SETTINGS_COMMON.ERRORS.SETTINGS_LOADING'],
    [SettingsActionErrors.SettingsUpdate, 'SETTINGS_COMMON.ERRORS.SETTINGS_UPDATE'],
    [SettingsActionErrors.NeighborhoodsMappingLoading, 'SETTINGS_COMMON.ERRORS.NEIGHBORHOODS_MAPPING_LOADING']
]);