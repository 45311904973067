import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SmartBannerService } from './smart-banner.service';

@Component({
    selector: 'smart-banner',
    templateUrl: './smart-banner.component.html',
    styleUrls: ['./smart-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartBannerComponent {

    @Input() isDarkMode: boolean;

    public readonly isAndroid = this.platform.ANDROID;
    public readonly isIOS = this.platform.IOS;

    constructor(
        private readonly platform: Platform,
        private readonly smartBannerService: SmartBannerService
    ) { }

    public onClose(): void {
        this.smartBannerService.hide();
    }

    public onInstall(platform: 'android' | 'ios'): void {
        this.smartBannerService.navigateToStore(platform);
        this.smartBannerService.hide();
    }
}
