<div class="rfd-wrap">

    <div class="rfd-title-wrap">

        <div class="rfd-title">
            {{'REMOVE_FOLDER_DIALOG.TITLES.DELETE_FOLDER' | translate}}
        </div>

        <rpc-icon-button class="rfd-title-close" [iconName]="'cross'" [title]="'REMOVE_FOLDER_DIALOG.BUTTONS.CLOSE'"
            [mat-dialog-close]="false" (click)='onClose()'>
        </rpc-icon-button>

    </div>

    <div class="rfd-body">

        <div class="rfd-subtitle">
            {{'REMOVE_FOLDER_DIALOG.TITLES.CONFIRMATION_MESSAGE_1' | translate}}
            «<span>{{data.name}}</span>»
            {{'REMOVE_FOLDER_DIALOG.TITLES.CONFIRMATION_MESSAGE_2' | translate}}
        </div>

        <mat-checkbox *ngIf="data?.hasListings" class="rfd-checkbox" [checked]="allListingsSelected"
            (change)="onSelectionChanged($event.checked)">
            {{'REMOVE_FOLDER_DIALOG.TITLES.DELETE_LISTINGS' | translate }}
        </mat-checkbox>

    </div>

    <div class="rfd-action-wrap">

        <rpc-button class="rfd-action" [appearanceType]="'stroke'" [mainButton]="true"
            [title]="'REMOVE_FOLDER_DIALOG.BUTTONS.CANCEL' | translate" (click)="onClose()">
        </rpc-button>

        <rpc-button class="rfd-action" [appearanceType]="'flat'" [color]="'accent'" [mainButton]="true"
            [title]="'REMOVE_FOLDER_DIALOG.BUTTONS.DELETE' | translate" (click)="onRemoveFolder()">
        </rpc-button>

    </div>

</div>