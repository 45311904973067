<div class="profile-edit-phone">

    <div class="profile-edit-phone-item" *ngFor="let phoneControl of phoneControls; let i = index">

        <div class="profile-edit-phone-item-header">

            <div class="profile-edit-phone-item-header-title">
                {{ 'PHONES_EDIT_FORM.TITLES.PHONE' | translate }} {{ i + 1 }}
            </div>

            <rpc-button class="profile-edit-phone-item-header-btn" [color]="'warn'"
                [title]="'PHONES_EDIT_FORM.TITLES.DELETE'" [iconName]="'delete-bin'" (clicked)="onRemovePhone(i)">
            </rpc-button>

        </div>

        <div class="profile-edit-phone-item-body">

            <rpc-select class="profile-edit-phone-item-body-select" [color]="'accent'"
                [label]="'PHONES_EDIT_FORM.TITLES.TYPE'" [controlOptions]="phoneControl.selectOptions"
                [appearance]="'fill'">
            </rpc-select>

            <phone-form-input class="profile-edit-phone-item-body-input" [appearance]="'fill'"
                [phoneNumberInfo]="phoneControl.phoneInfo" [isPhoneRequired]="true" [isFormatValidationRequired]="true"
                (phoneNumberChanged)="onPhoneNumberChanged($event)">
            </phone-form-input>

        </div>

    </div>

    <div class="profile-edit-phone-add-wrap">

        <rpc-button [color]="'accent'" [iconName]="'add-new'" [title]="'PHONES_EDIT_FORM.TITLES.ADD_NEW'"
            (clicked)="onAddPhone()">
        </rpc-button>

    </div>

</div>