import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { IInviteUserModel } from 'app/modules/user-modules/profile-modules/profile-base/invite-user/invite-user-model';
import { ChildAccount } from '../../interfaces/child-account/child-account';
import { IResendInvitationModel } from '../../invite-user/resend-invitation-model';
import { TopProfileInfo } from '../../interfaces/customer-info/top-profile-info';
import { NewAccountDataModel, OldAccountDataModel } from '../../interfaces/customer-info/account-data-model';
import { UpdatePasswordRequest } from '../../interfaces/customer-settings/update-password-request';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { NewMatchSettingsRequest } from '@settings/models/settings/new-match-settings-request';
import { CustomerInfo } from '../../interfaces/customer-info/customer-info';
import { ProfileActionErrors } from '@profile/enums/profile-action-errors';

export const loadAdditionalProfiles = createAction('[Profile Base] Load Additional Profiles', props<{ isActiveOnly: boolean }>());
export const loadAdditionalProfilesSuccessful = createAction('[Profile Base] Load Additional Profiles Successful', props<{ additionalProfiles: ChildAccount[] }>());
export const loadAdditionalProfilesFailed = createAction('[Profile Base] Load Additional Profiles Failed', (error: ApiError) => error);

export const loadProfileData = createAction('[Profile Base] Load Profile Data');

export const setActiveAdditionalProfileId = createAction('[Profile Base] Set Active Additional Profile Id', props<{ additionalProfileId: number | null }>());

export const inviteUser = createAction('[Profile Base] Invite User', props<{ inviteUserModel: IInviteUserModel }>());
export const inviteUserSuccessful = createAction('[Profile Base] Invite User Successful', props<{ childEmail: string }>());
export const inviteUserFailed = createAction('[Profile Base] Invite User Failed', props<{ error: ProfileActionErrors }>());

export const showDeactivateChildAccountDialog = createAction('[Profile Base] Show Deactivate Child Account Dialog', props<{ childCustomerId: number }>());

export const deactivateChildAccountByPrimary = createAction('[Profile Base] Deactivate Account Child Account By Primary', props<{ childCustomerId?: number }>());
export const deactivateChildAccountByPrimarySuccessful = createAction('[Profile Base] Deactivate Child Account By Primary Account Successful', props<{ childCustomerId: number }>());
export const deactivateChildAccountByPrimaryFailed = createAction('[Profile Base] Deactivate Account Child Account By Primary Failed', props<{ error: ProfileActionErrors }>());

export const showResendInviteDialog = createAction('[Profile Base] Show Resend Invite Dialog', props<{ childEmail: string }>());

export const reSendInvite = createAction('[Profile Base] Re-Send Invite', props<{ model: IResendInvitationModel }>());
export const reSendInviteSuccessful = createAction('[Profile Base] Re-Send Invite Successful', props<{ oldEmail: string, newEmail?: string | null }>());
export const reSendInviteFailed = createAction('[Profile Base] Re-Send Invite Failed', props<{ error: ProfileActionErrors }>());

export const showCancelInvitationDialog = createAction('[Profile Base] Show Cancel Invitation Dialog', props<{ childEmail: string }>());

export const cancelInvitation = createAction('[Profile Base] Cancel Invitation', props<{ childEmail: string }>());
export const cancelInvitationSuccessful = createAction('[Profile Base] Cancel Invitation Successful', props<{ childEmail: string }>());
export const cancelInvitationFailed = createAction('[Profile Base] Cancel Invitation Failed', props<{ error: ProfileActionErrors }>());

export const showInviteUserDialog = createAction('[Profile Base] Show Invite User Dialog');

export const showEditProfileInfoDialog = createAction('[Profile Base] Show Edit Profile Info Dialog', props<{ profileInfo: TopProfileInfo }>());

export const editProfileInfo = createAction('[Profile Base] Edit Profile Info', props<{ newData: NewAccountDataModel, oldData: OldAccountDataModel, isAdditionalProfile: boolean }>());

export const showDeactivateAccountDialog = createAction('[Profile Base] Show Deactivate Account Dialog');

export const deactivateAccount = createAction('[Profile Base] Deactivate Account', props<{ isPrimaryCustomer: boolean }>());

export const updatePassword = createAction('[Profile Base] Update Password', props<{ model: UpdatePasswordRequest }>());
export const updateSettings = createAction('[Profile Base] Update Settings', props<{ settings: CustomerSettings }>());
export const navigateToProfile = createAction('[Profile Base] Navigate To Profile');

export const loadSettings = createAction('[Profile Base] Load Settings');
export const loadNewMatchSettings = createAction('[Profile Base] Load New Match Settings');
export const updateNewMatchSettings = createAction('[Profile Base] Update New Match Settings', props<{ request: NewMatchSettingsRequest }>());

export const updateCustomerInfo = createAction('[Profile Base] Update Customer Info', props<{ customerInfo: CustomerInfo }>());

export const navigateToPrimaryProfilePage = createAction('[Profile Base] Navigate To Primary Profile Page');