import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { fromEvent, take } from 'rxjs';

import { MatchMediaService } from '@media/services/match-media.service';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';
import { SearchStoreService } from '@search/store/services/search-store.service';

@Component({
    selector: 'saved-search-list',
    templateUrl: './saved-search-list.component.html',
    styleUrls: ['./saved-search-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchListComponent implements OnInit, AfterViewInit {
    @Input() public showCreateOption: boolean;

    @Output() public createSavedSearch = new EventEmitter<void>();
    @Output() public closed = new EventEmitter<void>();

    public readonly activeSavedSearchId$ = this.savedSearchStoreService.activeSavedSearchId$;
    public readonly savedSearchesInfo$ = this.savedSearchStoreService.savedSearchesInfo$;
    public readonly isCriteriaEmpty$ = this.searchStoreService.isCriteriaEmpty$;

    constructor(
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly searchStoreService: SearchStoreService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: { showCreateOption: boolean; },
        private readonly matchMediaService: MatchMediaService,
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef<HTMLElement>,
    ) { }

    public ngOnInit(): void {
        this.showCreateOption = this.showCreateOption ?? this.data.showCreateOption;
    }

    public ngAfterViewInit(): void {
        const panelElement = this.elementRef.nativeElement.parentElement;

        this.renderer.setStyle(panelElement, 'height', panelElement.clientHeight + 'px');

        if (this.matchMediaService.activeMediaQuery === 'xs') {
            fromEvent(panelElement, 'scroll')
                .pipe(take(1))
                .subscribe(() => this.onExpandPanel());
        }
    }

    public onSavedSearchSelected(activeSavedSearchId: number): void {
        this.savedSearchStoreService.runSavedSearch(activeSavedSearchId);

        this.onClose();
    }

    public onEditSavedSearch(activeSavedSearchId: number): void {
        this.savedSearchStoreService.showAddEditSavedSearchDialog(activeSavedSearchId);

        this.onClose();
    }

    public onCreateNewSavedSearch(): void {
        this.savedSearchStoreService.showSaveYourSearch();

        this.onClose();
    }

    public onClose(): void {
        this.closed.emit();
    }

    public onExpandPanel(): void {
        if (this.matchMediaService.activeMediaQuery === 'xs') {
            this.renderer.addClass(this.elementRef.nativeElement.parentElement, 'rpc-bottomsheet--top');

            this.renderer.removeStyle(this.elementRef.nativeElement.parentElement, 'height');
        }
    }
}