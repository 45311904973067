export const locale = {
    lang: 'en',
    data: {
        NEW_MATCH_SETTINGS_PAGE: {
            TITLES: {
                BACK: 'Back',
                NEW_MATCHES: 'New Matches'
            }
        }
    }
};