export const locale = {
    lang: 'en',
    data: {
        GENERAL_INFO_TAB: {
            TITLES: {
                GENERAL_INFO: 'General Info',
                CANCEL: 'Cancel',
                SAVE: 'Save',
                EDIT: 'Edit',
            }
        }
    }
};