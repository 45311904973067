import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs';

import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';
import { FolderCover } from '@folders/models/folder-cover';
import { FolderSelectionModel } from '@folders/models/folder-selection-model';
import { Image } from '@media/models/image';
import { MAX_IMAGES_ON_FOLDER_COVER } from '@folders/constants/folder.constants';
import { MatchMediaService } from '@media/services/match-media.service';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { FolderDetails } from '@folders/models/folder-details';

@Component({
    selector: 'folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderComponent {
    @Input() folder: FolderCover;
    @Input() isDefault: boolean;

    @Output() public folderClicked = new EventEmitter<FolderSelectionModel>();

    public readonly initialFolderStateMode = InitialFolderStateMode;
    public readonly isMobile$ = this.matchMediaService.onMediaChange.pipe(map(mediaQuery => mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md'));

    public isMediaInitialized = false;

    private readonly imagesClassMap = new Map<number, string>([
        [0, 'folder-body--empty'],
        [1, ''],
        [2, 'folder-body--xs'],
        [3, 'folder-body--sm'],
        [4, 'folder-body--md'],
        [5, 'folder-body--lg'],
        [MAX_IMAGES_ON_FOLDER_COVER, 'folder-body--xlg'],
    ]);

    constructor(
        private readonly matchMediaService: MatchMediaService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
    ) { }

    public get hasNoMedia(): boolean {
        return this.folder.images == null || this.folder.images.length === 0;
    }

    public get mediaClass(): string {
        return this.imagesClassMap.get(this.folder.images.length);
    }

    public onClick(initialFolderStateMode?: InitialFolderStateMode): void {
        this.folderClicked.emit({
            id: this.folder.id,
            initialFolderStateMode: initialFolderStateMode ?? InitialFolderStateMode.Default
        });
    }

    public trackByImage(index: number, image: Image): number {
        return image.mediaId;
    }

    public onImageLoaded(): void {
        this.isMediaInitialized = true;
    }

    public onFolderDetailsClick(folder: FolderDetails): void {
        this.foldersStoreWriteService.showFolderDetails(folder);
    }
}
