import { locale as activitiesTooltip } from '@listings/components/activities-tooltip/i18n/en';
import { locale as multiActivityControls } from '@listings/components/activity-controls/multi-activity-controls/i18n/en';
import { locale as removeActivityControls } from '@listings/components/activity-controls/removed-listing-activity-controls/i18n/en';
import { locale as removeMultiActivityControls } from '@listings/components/activity-controls/removed-listing-multi-activity-controls/i18n/en';
import { locale as singleActivityControls } from '@listings/components/activity-controls/single-activity-controls/i18n/en';
import { locale as lastPriceChange } from '@listings/components/last-price-change/i18n/en';
import { locale as listingLabels } from '@listings/components/listing-labels/i18n/en';
import { locale as listingsList } from '@listings/components/listings-list/i18n/en';
import { locale as multiActivityMobileControls } from '@listings/components/multi-activity-compact-controls/i18n/en';
import { locale as newMatchTooltip } from '@listings/components/new-match-tooltip/i18n/en';
import { locale as listingManagement } from '@listings/components/remove-listing-dialog/i18n/en';
import { locale as openHouseTooltip } from '@listings/components/open-house-tooltip/i18n/en';
import { locale as multiSelectionMenu } from '@listings/components/multi-selection-menu/i18n/en';
import { locale as priceChangeInfo } from '@listings/components/price-change-info/i18n/en';

const listingsCommon = {
    LISTINGS_COMMON: {
        MESSAGES: {
            DIRECTION_LENGTH: 'Direction will be shown only for first 10 selected listings.'
        },
        ERRORS: {
            REMOVE_LISTING_FAILED: 'Failed to remove listings',
            FAILED_TO_DELETE_LISTINGS: 'Failed to delete listings',
            RESTORE_LISTINGS_FAILED: 'Failed to restore listings',
            LISTING_REMOVED_ERROR: 'The process is aborted due to listing removal.',
            LISTINGS_REMOVED_ERROR: 'The process is aborted due to listings removal.',
            LISTING_RESTORED_ERROR: 'The process is aborted due to listing restoring.',
            LISTINGS_RESTORED_ERROR: 'The process is aborted due to listings restoring.'
        }
    }
};

const listingFields = {
    LISTING_FIELDS: {
        DEFAULT: {
            ROOMS: 'Rooms',
            ROOM: 'Room',
            ROOM_TITLE: 'Room',
            BEDROOMS: 'Bedrooms',
            BEDROOM: 'Bedroom',
            BEDROOM_TITLE: 'Bedroom',
            BATHROOMS: 'Bathrooms',
            BATHROOM: 'Bathroom',
            BATHROOM_TITLE: 'Bathroom',
            FLOORS: 'Floors',
            FLOOR: 'Floor',
            FLOOR_TITLE: 'Floor',
            LAST_ASK: 'Last Ask Price',
            ORIGINAL_PRICE: 'Original Price',
            STATUS: 'Status',
            RM_BD_BTH: 'Rm/Bd/Bth',
            RM_BD_BTH_TITLE: 'Rooms/Bedrooms/Bathrooms',
            OUTDOOR: 'Outdoor',
            APPROX_SQFT: 'Approx SqFt',
            $_PER_SQFT: '$ per SqFt',
            STUDIO: 'Studio',
            SQUARE_FOOTAGE: 'Square Footage',
            PRICE_PER_SQUARE_FOOTAGE: '$ per Square Footage'
        },
        APPARTMENTS: {
            LISTED: 'Listed',
            VIEWS: 'Views',
            DOM_TITLE: 'Days On Market',
            DOM: 'DOM',
            FEATURES: 'Features',
            EXPOSURE: 'Exposure',
            WASHER_DRYER: 'Washer/Dryer',
            AC: 'AC',
            LISTING_NUMBER: 'Listing #',
            BUILDING_ALLOWS: 'Building Allows',
            APT_TYPE: 'Apt Type',
            UPDATED_DATE: 'Updated Date'
        },
        COOP: {
            MAINTENANCE_TITLE: 'Maintenance',
            MAINTENANCE: 'Maint',
            SHARES: 'Shares'
        },
        CONDO: {
            RE_TAXES: 'R.E. Taxes',
            COMMON_CHARGES: 'Common Charges'

        },
        TOWNHOUSE: {
            FLOOR: 'Floor',
            FLOORS: 'Floors',
            UNIT: 'Unit',
            UNITS: 'Units',
            BUILDING_SIZE: 'Building Size',
            BUILDING_SIZE_TITLE: 'Bldg',
            LOT_SIZE: 'Lot Size',
            LOT_SIZE_TITLE: 'Lot',
            BUILDING_EXTENSION: 'Building Extension',
            BUILDING_EXTENSION_TITLE: 'Ext',
            TYPE: 'Type',
            CONDITION: 'Condition',
            ELEVATORS: '# of Elevators',
            GARAGE: 'Garage',
            BASEMENT: 'Basement'
        },
        SALES: {
            FINANCING_ALLOWED: 'Financing Allowed',
            FLIP_TAX: 'Flip Tax',
            CONTRACT_SIGNED: 'Contract Signed'

        },
        RENTAL: {
            LEASE_TERM: 'Lease Term',
            LEASE_TYPE: 'Lease Type',
            LEASE_SIGNED: 'Lease Signed',
            FURNISHED: 'Furnished',
            DATE_AVAILABLE: 'Date Available',
            PETS_ALLOWED: 'Pets Allowed'
        },
        UNIT_FEATURES: {
            VIEWS: 'Views',
            EXPOSURE: 'Exposure',
            MISCELLANEOUS_DETAILS: 'Miscellaneous Details',
            PRIVATE_OUTDOOR_SPACE: 'Private Outdoor Space',
            APARTMENT_TYPE: 'Apartment Type',
            FURNISHED: 'Furnished',
            MISCELLANEOUS_DETAILS_OPTIONS: {
                WASHER_DRYER: 'Washer/Dryer',
                SPONSOR_APARTMENT: 'Sponsor Apartment',
                DINING_ROOM: 'Dining Room',
                EAT_IN_KITCHEN: 'Eat In Kitchen',
                MAIDS_ROOM: 'Maid\'s Room',
                BUILDING_ALLOWS_PETS: 'Building Allows Pets',
                LOFT_SPACE: 'Loft Space',
                PENTHOUSE: 'Penthouse',
                FIREPLACE: 'Fireplace'
            }
        },
        BUILDING_FEATURES: {
            BUILDING_PERIOD: 'Building Period',
            BUILDING_ALLOWS: 'Building Allows',
            ATTENDED_LOBBY: 'Attended Lobby',
            BUILDING_TYPE: 'Building Style',
            COMMON_OUTDOOR_SPACE: 'Common Outdoor Space',
            FEATURES: 'Features',
            BUILDING_PERIOD_OPTIONS: {
                PRE_WAR: 'Pre-War',
                POST_WAR: 'Post-War'
            },
            ATTENDED_LOBBY_OPTIONS: {
                DOORMAN_FULL_TIME: 'Doorman Full Time',
                DOORMAN_PART_TIME: 'Doorman Part Time',
                CONCIERGE: 'Concierge',
                ELEVATOR_MAN: 'Elevator Man',
                UNATTENDED_LOBBY: 'Unattended Lobby'
            },
            BUILDING_TYPE_OPTIONS: {
                HIGHRISE: 'High-Rise',
                LOWRISE: 'Low-Rise',
                LOFT: 'Loft',
                TOWNHOUSE: 'Townhouse',
                BROWNSTONE: 'Brownstone',
                WALKUP: 'Walkup',
                OFFICE: 'Office',
                RETAIL: 'Retail',
                HOTEL: 'Hotel',
            },
            COMMON_OUTDOOR_SPACE_OPTIONS: {
                COURTYARD: 'Courtyard',
                ROOF_DECK: 'Roof Deck'
            },
            BUILDING_ALLOWS_OPTIONS: {
                CORPORATE_OWNERSHIP: 'Corporate Ownership',
                DIPLOMATS: 'Diplomats',
                FINANCING: 'Financing',
                LIVE_WORK: 'Live/Work',
                OPEN_HOUSE: 'Open House',
                PETS: 'Pets',
                PIED_A_TERRE: 'Pied-A-Terre',
                RENTAL_APARTMENT_SHARING: 'Rental Apartment Sharing',
                RENTING_SUBLETTING: 'Renting/Subletting',
                WASHER_DRYER_IN_APARTMENT: 'Washer Dryer In Apartment',
            },
            FEATURES_OPTIONS: {
                COOLING: 'Cooling',
                HEALTH_CLUB_FITNESS: 'Health Club/Fitness',
                LAUNDRY_FACILITIES: 'Laundry Facilities',
                PARKING: 'Parking',
                POOL: 'Pool',
                SECURITY: 'Security',
                STORAGE: 'Storage',
                WHEELCHAIR: 'Wheelchair Accessible',
                ELEVATOR: 'Elevator',
            },
        },
        ATTRIBUTE_VALUES: {
            YES: 'Yes',
            NO: 'No',
            UNKNOWN: 'Unknown',
        }
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...listingsCommon,
        ...listingFields,
        ...multiActivityControls.data,
        ...singleActivityControls.data,
        ...lastPriceChange.data,
        ...listingLabels.data,
        ...multiActivityMobileControls.data,
        ...removeActivityControls.data,
        ...removeMultiActivityControls.data,
        ...listingsList.data,
        ...listingManagement.data,
        ...activitiesTooltip.data,
        ...newMatchTooltip.data,
        ...openHouseTooltip.data,
        ...multiSelectionMenu.data,
        ...priceChangeInfo.data,
    }
};