<div class="rpc-activities">

    <rpc-icon-button [title]="getActivityTitle(listingActivities.Liked)" [iconName]="'like'"
        [actionClass]="'active liked'" [disabled]="disabled" (clicked)="onLikedClicked()">
    </rpc-icon-button>

    <rpc-icon-button [title]="getActivityTitle(listingActivities.Shown)" [iconName]="'eye'"
        [actionClass]="'active shown'" [disabled]="disabled" (clicked)="onShownClicked()">
    </rpc-icon-button>

    <rpc-icon-button [title]="getActivityTitle(listingActivities.Disliked)" [iconName]="'dislike'"
        [actionClass]="'active disliked'" [disabled]="disabled" (clicked)="onDislikedClicked()">
    </rpc-icon-button>

    <ng-container *ngTemplateOutlet="extraActions">
    </ng-container>

</div>