import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnChanges, Output, TemplateRef } from '@angular/core';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ListingsSelectionEventParams } from '@listings/models/events/listings-selection-event-params';
import { ListingsService } from '@listings/services/listings.service';

@Component({
    selector: 'multi-selection-menu',
    templateUrl: './multi-selection-menu.component.html',
    styleUrls: ['./multi-selection-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectionMenuComponent implements OnChanges {

    @Input() public listingIds: string[];
    @Input() public selectedListingIds: string[];

    @Output() public selectionChanged = new EventEmitter<ListingsSelectionEventParams>();

    @ContentChild('selectionSubMenu') public selectionSubMenu: TemplateRef<unknown>;

    public allListingsSelected: boolean;
    public someListingsSelected: boolean;

    constructor(
        private readonly listingsService: ListingsService,
    ) { }

    public ngOnChanges(changes: SimpleChanges<MultiSelectionMenuComponent>): void {
        const selectedListings = new Set(this.selectedListingIds);

        this.allListingsSelected = selectedListings.size > 0 && this.listingIds.length > 0 && this.listingIds.every(listingId => selectedListings.has(listingId));
        this.someListingsSelected = selectedListings.size > 0 && this.listingIds.length > 0 && !this.allListingsSelected;
    }

    public onSelectionChanged(checked: boolean): void {
        const listingsSelection = { listingIds: this.listingIds, areSelected: checked };

        this.listingsService.updateListingsSelection(listingsSelection);

        this.selectionChanged.emit(listingsSelection);
    }
}