export const locale = {
    lang: 'en',
    data: {
        NM: {
            TITLES: {
                CHECK_FOR_NEW_MATCHES: 'Check for New Matches',
                HOW_OFTEN_SHOULD_WE_CHECK: 'How often should we check your searches for new matches',
                EMAIL_TEMPLATE: 'Email Template',
                HOW_WOULD_YOU_LIKE_TO_FORMAT_INFORMATION: 'How would you like the information formatted within the notification emails',
                MY_NOTIFICATIONS: 'My Notifications',
                HOW_OFTEN_TO_RECEIVE: 'How often would you like to receive email alerts for your saved searches',
                EVERY_FIVE_MIN: 'Every 5 Minutes',
                FOUR_TIMES_A_DAY: '4 Times a Day',
                DETAILED_VIEW: 'Detailed View',
                SUMMARY_VIEW: 'Summary View',
                REALTIME: 'Real Time',
                DAILY: 'Daily',
                WEEKLY: 'Weekly',
                MONTHLY: 'Monthly',
                FREQUENCY_PER_DAY: 'Frequency Per Day',
                DELIVERY_SCHEDULE: 'Delivery Schedule'
            },
            DAYS: {
                FRI: 'Fri',
                MON: 'Mon',
                SAT: 'Sat',
                SUN: 'Sun',
                THU: 'Thu',
                TUE: 'Tue',
                WED: 'Wed'
            },
            TEXT: {
                EMAILS_ARE_SENT: 'Emails are Sent',
                PER_DAY: 'per day',
                EVERY: 'Every',
                OF_THE_MONTH: 'of the Month',
                MONTHLY_EMAIL_SENDING: 'Emails are Sent Once per day',
                REALTIME: 'Emails are Sent every 15 minutes',
                WEEKS: 'Week(s) on a',
                WEEKLY_EMAIL_SENDING: 'Emails are Sent Once per day'
            },
            ERROR_MESSAGE: {
                SELECT_ONE_DAY: 'Select at least one day'
            },
            INFO: {
                EMAIL_TEMPLATE: 'Email Template',
                AND: 'and',
                MY_NOTIFICATIONS: 'My Notifications',
                WILL_ONLY_APPLY: 'will only apply if the option to',
                INCLUDE_IN_NEW_MATCHES: 'Include in New Matches',
                IS_SELECTED_WHEN_SAVING: 'is selected when saving a search'
            }
        }
    }
};