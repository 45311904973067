import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';

import { NewMatchCheckingFrequency } from '../../interfaces/enums/new-match-checking-frequency.enum';
import { NewMatchDailyFrequency } from '../../interfaces/enums/new-match-daily-frequency.enum';
import { NewMatchEmailTemplate } from '../../interfaces/enums/new-match-email-template.enum';
import { NewMatchMonthlyFrequency } from '../../interfaces/enums/new-match-monthly-frequency.enum';
import { NewMatchWeekDays } from '../../interfaces/enums/new-match-weekdays.enum';
import { NewMatchWeeklyFrequency } from '../../interfaces/enums/new-match-weekly-frequency.enum';
import { NewMatchNotificationFrequency, NewMatchNotificationFrequencyKind } from '../../interfaces/new-match-settings/new-match-notification-frequency';
import { NewMatchSettingsNotificationFrequencyOption } from '../../interfaces/new-match-settings/new-match-notification-frequency-option';
import { NewMatchSettingsFormModel } from '../../interfaces/new-match-settings/new-match-settings-form-model';
import { NewMatchSettingsModel } from '../../interfaces/new-match-settings/new-match-settings.model';
import { NewMatchSettingsWeekDayOption } from '../../interfaces/new-match-settings/new-match-week-day-option';

@Component({
    selector: 'new-match-settings',
    templateUrl: './new-match-settings.component.html',
    styleUrls: ['./new-match-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewMatchSettingsComponent implements OnChanges, OnDestroy {
    @Input() public settings: NewMatchSettingsModel;

    @Output() public settingsChanged = new EventEmitter<NewMatchSettingsModel>();

    public newMatchSettingsForm: FormGroup;
    public frequencyPerDay: RpcSelectControltOptions;
    public weeklyFrequency: RpcSelectControltOptions;
    public weeklyFrequencyDay: RpcSelectControltOptions;
    public monthlyFrequency: RpcSelectControltOptions;

    public readonly NewMatchCheckingFrequency = NewMatchCheckingFrequency;
    public readonly NewMatchEmailTemplate = NewMatchEmailTemplate;
    public readonly NewMatchWeekDays = NewMatchWeekDays;

    public readonly notificationFrequencyOptions: readonly NewMatchSettingsNotificationFrequencyOption[] = [
        { titleTranslationKey: 'NM.TITLES.REALTIME', frequency: 'realtime' },
        { titleTranslationKey: 'NM.TITLES.DAILY', frequency: 'daily' },
        { titleTranslationKey: 'NM.TITLES.WEEKLY', frequency: 'weekly' },
        { titleTranslationKey: 'NM.TITLES.MONTHLY', frequency: 'monthly' }
    ];

    public readonly weekDayOptions: readonly NewMatchSettingsWeekDayOption[] = [
        { titleTranslationKey: 'NM.DAYS.SUN', day: NewMatchWeekDays.Sunday },
        { titleTranslationKey: 'NM.DAYS.MON', day: NewMatchWeekDays.Monday },
        { titleTranslationKey: 'NM.DAYS.TUE', day: NewMatchWeekDays.Tuesday },
        { titleTranslationKey: 'NM.DAYS.WED', day: NewMatchWeekDays.Wednesday },
        { titleTranslationKey: 'NM.DAYS.THU', day: NewMatchWeekDays.Thursday },
        { titleTranslationKey: 'NM.DAYS.FRI', day: NewMatchWeekDays.Friday },
        { titleTranslationKey: 'NM.DAYS.SAT', day: NewMatchWeekDays.Saturday }
    ];

    private unsubscribe$ = new Subject<void>();
    private readonly updateDebounceTime = 200;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngOnChanges(changes: SimpleChanges<NewMatchSettingsComponent>): void {
        if (changes?.settings?.currentValue != null) {
            this.unsubscribe();
            this.unsubscribe$ = new Subject<void>();

            this.buildForm(changes.settings.currentValue);
            const currentSettings = changes.settings.currentValue;

            this.newMatchSettingsForm.valueChanges
                .pipe(debounceTime(this.updateDebounceTime), takeUntil(this.unsubscribe$))
                .subscribe((settings: NewMatchSettingsFormModel) => {
                    const updatedSettings = this.createSettingsModel(settings);

                    if (updatedSettings != null && !isEqual(currentSettings, updatedSettings)) {
                        this.settingsChanged.next(updatedSettings);
                    }
                });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public toggleCheckFrequency(frequency: NewMatchCheckingFrequency): void {
        this.newMatchSettingsForm.controls.checkingFrequency.setValue(frequency);
        this.changeDetectorRef.detectChanges();
    }

    public getCheckFrequencyButtonColor(frequency: NewMatchCheckingFrequency): ThemePalette | null {
        return this.newMatchSettingsForm.controls.checkingFrequency.value === frequency ? 'accent' : null;
    }

    public getCheckFrequencyButtonAppearance(frequency: NewMatchCheckingFrequency): 'stroke' | 'flat' {
        return this.newMatchSettingsForm.controls.checkingFrequency.value === frequency ? 'flat' : 'stroke';
    }

    public toggleEmailTemplate(template: NewMatchEmailTemplate): void {
        this.newMatchSettingsForm.controls.emailTemplate.setValue(template);
        this.changeDetectorRef.detectChanges();
    }

    public getEmailTemplateButtonColor(template: NewMatchEmailTemplate): ThemePalette | null {
        return this.newMatchSettingsForm.controls.emailTemplate.value === template ? 'accent' : null;
    }

    public getEmailTemplateButtonAppearance(template: NewMatchEmailTemplate): 'stroke' | 'flat' {
        return this.newMatchSettingsForm.controls.emailTemplate.value === template ? 'flat' : 'stroke';
    }

    public toggleEmailNotificationFrequency(kind: NewMatchNotificationFrequencyKind): void {
        this.newMatchSettingsForm.controls.notificationFrequency.setValue(kind);
        this.changeDetectorRef.detectChanges();
    }

    public getEmailNotificationFrequencyButtonColor(kind: NewMatchNotificationFrequencyKind): ThemePalette | null {
        return this.isEmailNotificationFrequencySelected(kind) ? 'accent' : null;
    }

    public getEmailNotificationFrequencyButtonAppearance(kind: NewMatchNotificationFrequencyKind): 'stroke' | 'flat' {
        return this.isEmailNotificationFrequencySelected(kind) ? 'flat' : 'stroke';
    }

    public isEmailNotificationFrequencySelected(kind: NewMatchNotificationFrequencyKind): boolean {
        return this.newMatchSettingsForm.controls.notificationFrequency.value === kind;
    }

    public getDailyFrequencyButtonColor(weekDay: NewMatchWeekDays): ThemePalette | null {
        const frequency = this.newMatchSettingsForm.controls.notificationFrequency.value as string;

        if (frequency === 'daily') {
            const days = this.newMatchSettingsForm.controls.selectedDays.value as NewMatchWeekDays[];

            return days.includes(weekDay) ? 'accent' : null;
        }

        return null;
    }

    public getDailyFrequencyButtonAppearance(weekDay: NewMatchWeekDays): 'stroke' | 'flat' {
        const days: NewMatchWeekDays[] = this.newMatchSettingsForm.controls.selectedDays.value;

        return days.includes(weekDay) ? 'flat' : 'stroke';
    }

    public toggleDailyFrequencyDay(weekDay: NewMatchWeekDays): void {
        const frequency = this.newMatchSettingsForm.controls.notificationFrequency.value as string;

        if (frequency === 'daily') {
            const days = this.newMatchSettingsForm.controls.selectedDays.value as NewMatchWeekDays[];
            const index = days.indexOf(weekDay);

            if (index > -1) {
                days.splice(index, 1);
            } else {
                days.push(weekDay);
            }

            this.newMatchSettingsForm.controls.selectedDays.setValue(days);
        }
    }

    private createSettingsModel(settings: NewMatchSettingsFormModel): NewMatchSettingsModel {
        let notificationFrequency: NewMatchNotificationFrequency;

        switch (settings.notificationFrequency) {
            case 'realtime':
                notificationFrequency = { kind: 'realtime' };
                break;
            case 'daily':
                if (settings.selectedDays.length === 0) {
                    return null;
                }

                notificationFrequency = { kind: 'daily', frequency: settings.frequencyPerDay, days: settings.selectedDays };
                break;
            case 'weekly':
                notificationFrequency = { kind: 'weekly', frequency: settings.weeklyFrequency, day: settings.weeklyFrequencyDay };
                break;
            case 'monthly':
                notificationFrequency = { kind: 'monthly', frequency: settings.monthlyFrequency };
                break;
        }

        return new NewMatchSettingsModel(settings.checkingFrequency, settings.emailTemplate, notificationFrequency);
    }

    private buildForm(settings: NewMatchSettingsModel): void {
        this.newMatchSettingsForm = this.formBuilder.group({
            checkingFrequency: [settings.checkingFrequency],
            notificationFrequency: [settings.emailNotificationFrequency.kind],
            emailTemplate: [settings.emailTemplate],
            selectedDays: [[...Object.values(NewMatchWeekDays).filter(val => !isNaN(Number(val)))]],
            frequencyPerDay: [NewMatchDailyFrequency.FourTimesPerDay],
            weeklyFrequency: [NewMatchWeeklyFrequency.EveryOneWeek],
            weeklyFrequencyDay: [NewMatchWeekDays.Sunday],
            monthlyFrequency: [NewMatchMonthlyFrequency.Every1st]
        });

        this.frequencyPerDay = {
            formGroup: this.newMatchSettingsForm,
            controlName: 'frequencyPerDay',
            options: [
                new RpcSelectOption(NewMatchDailyFrequency.OncePerDay, 'Once'),
                new RpcSelectOption(NewMatchDailyFrequency.TwoTimesPerDay, 'Two Times'),
                new RpcSelectOption(NewMatchDailyFrequency.FourTimesPerDay, 'Four Times'),
            ],
            selectedValue: NewMatchDailyFrequency.FourTimesPerDay
        };

        this.weeklyFrequency = {
            formGroup: this.newMatchSettingsForm,
            controlName: 'weeklyFrequency',
            options: [
                new RpcSelectOption(NewMatchWeeklyFrequency.EveryOneWeek, '1'),
                new RpcSelectOption(NewMatchWeeklyFrequency.EveryTwoWeeks, '2'),
                new RpcSelectOption(NewMatchWeeklyFrequency.EveryThreeWeeks, '3'),
                new RpcSelectOption(NewMatchWeeklyFrequency.EveryFourWeeks, '4'),
            ],
            selectedValue: NewMatchWeeklyFrequency.EveryOneWeek
        };

        this.weeklyFrequencyDay = {
            formGroup: this.newMatchSettingsForm,
            controlName: 'weeklyFrequencyDay',
            options: [
                new RpcSelectOption(NewMatchWeekDays.Sunday, 'Sunday'),
                new RpcSelectOption(NewMatchWeekDays.Monday, 'Monday'),
                new RpcSelectOption(NewMatchWeekDays.Tuesday, 'Tuesday'),
                new RpcSelectOption(NewMatchWeekDays.Wednesday, 'Wednesday'),
                new RpcSelectOption(NewMatchWeekDays.Thursday, 'Thursday'),
                new RpcSelectOption(NewMatchWeekDays.Friday, 'Friday'),
                new RpcSelectOption(NewMatchWeekDays.Saturday, 'Saturday'),
            ],
            selectedValue: NewMatchWeekDays.Sunday
        };

        this.monthlyFrequency = {
            formGroup: this.newMatchSettingsForm,
            controlName: 'monthlyFrequency',
            options: Object
                .keys(NewMatchMonthlyFrequency)
                .filter(key => isNaN(Number(key)))
                .map(key => new RpcSelectOption(NewMatchMonthlyFrequency[key], key.substring('Every'.length))),
            selectedValue: NewMatchMonthlyFrequency.Every1st
        };

        switch (settings.emailNotificationFrequency.kind) {
            case 'daily':
                this.frequencyPerDay.selectedValue = settings.emailNotificationFrequency.frequency;
                this.newMatchSettingsForm.controls.frequencyPerDay.setValue(settings.emailNotificationFrequency.frequency);
                this.newMatchSettingsForm.controls.selectedDays.setValue(settings.emailNotificationFrequency.days.slice());
                break;
            case 'weekly':
                this.weeklyFrequency.selectedValue = settings.emailNotificationFrequency.frequency;
                this.weeklyFrequencyDay.selectedValue = settings.emailNotificationFrequency.day;
                this.newMatchSettingsForm.controls.weeklyFrequency.setValue(settings.emailNotificationFrequency.frequency);
                this.newMatchSettingsForm.controls.weeklyFrequencyDay.setValue(settings.emailNotificationFrequency.day);
                break;
            case 'monthly':
                this.monthlyFrequency.selectedValue = settings.emailNotificationFrequency.frequency;
                this.newMatchSettingsForm.controls.monthlyFrequency.setValue(settings.emailNotificationFrequency.frequency);
                break;
            case 'realtime':
            default:
                break;
        }
    }

    private unsubscribe(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}