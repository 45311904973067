import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { HybridService } from '../../hybrid/hybrid.service';
import { RouteService } from '@core-layout/app/services/route.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';

@Component({
    selector: 'not-authorized-toolbar',
    templateUrl: './not-authorized-toolbar.component.html',
    styleUrls: ['./not-authorized-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAuthorizedToolbarComponent {
    @Input() public hasLink?: boolean = true;

    public isHybrid$ = this.hybridService.isHybrid$;

    constructor(
        private readonly hybridService: HybridService,
        private readonly routeService: RouteService,
    ) { }

    public onHybridLogoClicked(): void {
        // TODO: Stas add redirect to login page in hybrid app
    }

    public onLoginClicked(isHybrid: boolean): void {
        if (!isHybrid) {
            this.routeService.navigate(RpcRoute.Login).catch(() => { });
        } else {
            // TODO: Stas add redirect to login page in hybrid app
        }
    }
}
