import { Injectable } from '@angular/core';

import { LocalStorageConstants } from '@auth/constants/local-storage-constants';
import { LoginData } from '@auth/models/login-data';
import { User } from '@auth/models/user';
import { StorageService } from '@auth/services/storage.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
    private static readonly timeCorrection = 1000;

    public static saveUserTokens(tokens: LoginData): void {
        localStorage.setItem(LocalStorageConstants.TokenKey, JSON.stringify(tokens));
        StorageService.saveDarkMode(tokens.customerSettings.layoutSettings.darkMode);
        StorageService.saveSearchMode(tokens.customerSettings.permissionSettings.canSearchForListings);
    }

    public static restoreCurrentUser(): User {
        const tokensStr = localStorage.getItem(LocalStorageConstants.TokenKey);

        if (tokensStr?.length > 0 && tokensStr !== 'null') {
            const user = TokenService.parseUserFromToken(JSON.parse(tokensStr) as LoginData);

            user.darkMode = StorageService.getDarkMode();
            user.searchMode = StorageService.getSearchMode();

            return user;
        }

        return null;
    }

    public static parseUserFromToken(data: LoginData): User {
        const base64Url = data.accessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const binaryString = window.atob(base64);

        const jsonString = new TextDecoder().decode(Uint8Array.from(binaryString, (m) => m.codePointAt(0)));
        const { exp, ...user } = JSON.parse(jsonString) as (User & { exp: number });

        user.loginData = data;
        user.expiration = new Date(exp * TokenService.timeCorrection);
        user.primaryCustomerId = +user.primaryCustomerId > 0 ? +user.primaryCustomerId : null;
        user.customerId = +user.customerId;
        user.agentId = +user.agentId;
        user.companyId = +user.companyId;
        user.companyId = +user.companyId;

        return user;
    }

    public static removeUserTokens(): void {
        localStorage.removeItem(LocalStorageConstants.TokenKey);
    }

    public static processUserData(loginData: LoginData): User {
        TokenService.saveUserTokens(loginData);

        return TokenService.parseUserFromToken(loginData);
    }
}