<div class="profile-address-list">

    <ng-container *ngIf="addressesList.length > 0; else empty">

        <div class="profile-address-list-item" *ngFor="let address of addressesList">

            <div class="profile-address-list-item-option">
                {{ address.type | translate }}
            </div>

            <div class="profile-address-list-item-value" [title]="address.value">
                {{address.value}}
            </div>

        </div>

    </ng-container>

    <ng-template #empty>

        <div class="profile-address-list-empty">
            {{ 'ADDRESS_LIST.TITLES.EMPTY' | translate }}
        </div>

    </ng-template>

</div>