<ng-container *ngIf="settings != null" [formGroup]="form">
    <div class="profile-settings-block" *ngFor="let fieldGroup of fieldGroups">

        <div class="profile-settings-block-title">
            {{ fieldGroup.title | translate }}
        </div>

        <div class="profile-settings-block-list">

            <div class="profile-settings-block-list-item" *ngFor="let field of fieldGroup.fields">

                <div class="profile-settings-block-list-item-text-wrap" [title]="field.title | translate"
                    [class.profile-settings-block-list-item-text-wrap--icon]="field.icon != null">

                    <rpc-icon class="profile-settings-block-list-item-text-icon" [class]="field.iconClass"
                        *ngIf="field.icon != null" [iconName]="field.icon">
                    </rpc-icon>

                    <div class="profile-settings-block-list-item-text">
                        {{field.title | translate}}:
                    </div>

                </div>

                <rpc-select class="profile-settings-block-list-item-select" [appearance]="'fill'"
                    [controlOptions]="field.controlOptions" (changed)="onSettingChanged(field.key,$event)">
                </rpc-select>

            </div>

        </div>

    </div>
</ng-container>