/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiHttpClient extends HttpClient {

    constructor(
        @Inject('BASE_API_URL') private readonly baseUrl: { url: string },
        handler: HttpHandler
    ) {
        super(handler);
    }

    public get(endpointUrlPart: string, options?: object): Observable<any> {
        return super.get(this.getUrl(endpointUrlPart), options);
    }

    public post(endpointUrlPart: string, body: any, options?: object): Observable<any> {
        return super.post(this.getUrl(endpointUrlPart), body, options);
    }

    public put(endpointUrlPart: string, body: any, options?: object): Observable<any> {
        return super.put(this.getUrl(endpointUrlPart), body, options);
    }

    public delete(endpointUrlPart: string, options?: object): Observable<any> {
        return super.delete(this.getUrl(endpointUrlPart), options);
    }

    public head(endpointUrlPart: string, options?: object): Observable<any> {
        return super.head(this.getUrl(endpointUrlPart), options);
    }

    public options(endpointUrlPart: string, options?: object): Observable<any> {
        return super.options(this.getUrl(endpointUrlPart), options);
    }

    public jsonp(endpointUrlPart: string, callbackParam: string): Observable<any> {
        return super.jsonp(this.getUrl(endpointUrlPart), callbackParam);
    }

    private getUrl(endpointUrlPart: string): string {
        return this.baseUrl.url.endsWith('/')
            ? this.baseUrl.url + endpointUrlPart
            : this.baseUrl.url + '/' + endpointUrlPart;
    }
}