<div class="not-authorized-toolbar" [class.not-authorized-toolbar--btn]="hasLink"
    *ngLet="isHybrid$ | async as isHybrid">

    <div class="not-authorized-toolbar-logo-wrap">

        <logo class="not-authorized-toolbar-logo" *ngIf="!isHybrid">
        </logo>

        <div class="not-authorized-toolbar-logo-img-wrap" *ngIf="isHybrid" (click)="onHybridLogoClicked()">

            <img class="not-authorized-toolbar-logo-img" src="assets/images/logos/logo.svg" alt="">

            <div class="not-authorized-toolbar-logo-img-text">myPlaceNYC</div>

        </div>

    </div>

    <rpc-button class="not-authorized-toolbar-btn" *ngIf="hasLink" [color]="'accent'"
        [title]="'NOT_AUTHORIZED_TOOLBAR.BUTTONS.LOGIN'" (clicked)="onLoginClicked(isHybrid)">
    </rpc-button>

</div>