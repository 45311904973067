import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Phone } from '../../interfaces/customer-info/phone';
import { PhoneTypes } from '../../interfaces/enums/phone-types.enum';

@Component({
    selector: 'phones-list',
    templateUrl: './phones-list.component.html',
    styleUrls: ['./phones-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PhonesListComponent {
    @Input() phones: Phone[] = [];

    public readonly phoneTypesText = new Map<PhoneTypes, string>([
        [PhoneTypes.office, 'PROFILE_COMMON.ENUM_TYPES.OFFICE'],
        [PhoneTypes.home, 'PROFILE_COMMON.ENUM_TYPES.HOME'],
        [PhoneTypes.mobile, 'PROFILE_COMMON.ENUM_TYPES.MOBILE'],
        [PhoneTypes.other, 'PROFILE_COMMON.ENUM_TYPES.OTHER'],
    ]);
}