import { Injector, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

import { DynamicLinkService } from './dynamic-link.service';

const createDefaultApolloClient = (dynamicLinkService: DynamicLinkService): ApolloClientOptions<NormalizedCacheObject> => {
    return {
        link: dynamicLinkService.createDynamicLink(),
        cache: new InMemoryCache(),
    };
};

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createDefaultApolloClient,
            deps: [DynamicLinkService],
        }
    ],
})
export class GraphQLModule { }
