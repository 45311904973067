<ng-container *ngLet="{
    isPrimaryCustomer: isPrimaryCustomer$ | async
} as data">

    <div class="general-info-tab-block general-info-tab-block--edit" *ngIf="isEditMode; else viewMode">

        <div class="general-info-tab-block-header">

            <div class="general-info-tab-block-header-title">
                {{ 'GENERAL_INFO_TAB.TITLES.GENERAL_INFO' | translate }}
            </div>

        </div>

        <mat-divider>
        </mat-divider>

        <general-info-form class="general-info-tab-block-body" [isPrimaryCustomer]="data.isPrimaryCustomer"
            [isSelfProfile]="isSelfProfile" [emails]="emails" [phones]="phones" [addresses]="addresses">
        </general-info-form>

        <mat-divider>
        </mat-divider>

        <div class="general-info-tab-block-footer">

            <rpc-button [mainButton]="true" [appearanceType]="'stroke'" [title]="'GENERAL_INFO_TAB.TITLES.CANCEL'"
                (clicked)="onCancel()">
            </rpc-button>

            <rpc-button [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
                [title]="'GENERAL_INFO_TAB.TITLES.SAVE'" [disabled]="isFormInvalid" (clicked)="onSave()">
            </rpc-button>

        </div>

    </div>

    <ng-template #viewMode>

        <div class="general-info-tab-block">

            <div class="general-info-tab-block-header general-info-tab-block-header--right">

                <div class="general-info-tab-block-header-title">
                    {{ 'GENERAL_INFO_TAB.TITLES.GENERAL_INFO' | translate }}
                </div>

                <div class="general-info-tab-block-header-right">

                    <rpc-button [appearanceType]="'flat'" [color]="'accent'" [iconName]="'edit'"
                        [title]="'GENERAL_INFO_TAB.TITLES.EDIT'" (clicked)="onEdit()">
                    </rpc-button>

                </div>

            </div>

            <mat-divider>
            </mat-divider>

            <general-info class="general-info-tab-block-body" [isPrimaryCustomer]="data.isPrimaryCustomer"
                [isSelfProfile]="isSelfProfile" [emails]="emails" [phones]="phones" [addresses]="addresses">
            </general-info>

        </div>

    </ng-template>

</ng-container>