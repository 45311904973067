<div class="price-wrap" [class.price-wrap--change]="priceChange != null"
    [class.price-wrap--up]="priceChange != null && isPriceIncreased"
    [class.price-wrap--down]="priceChange != null && !isPriceIncreased" (click)="priceChangeClicked.emit()">

    <div *ngIf="priceChange != null" class="price-btn-wrap" (click)="$event.stopPropagation()">

        <rpc-menu-icon-button class="price-btn" [class]="isPriceIncreased ? 'price-btn--up' : 'price-btn--down'"
            [alt]="'LAST_PRICE_CHANGE.TITLES.SHOW_PRICE_CHANGE'" [iconName]="isPriceIncreased ? 'up' : 'down'"
            [menuActionClass]="'price-dropdown-wrap'" (clicked)="onLastPriceChangeClicked()"
            (closed)="onLastPriceChangeClosed()">

            <price-change-info [priceChange]="priceChange" [isPriceIncreased]="isPriceIncreased">
            </price-change-info>

        </rpc-menu-icon-button>

    </div>

    <div class="price-simple" [title]="formattedLastPrice">
        {{ formattedLastPrice }}
    </div>

</div>