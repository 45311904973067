import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import * as savedSearchActions from '../actions/saved-search.actions';
import { SavedSearchStoreService } from '../services/saved-search-store.service';
import { SimpleDialogService } from '@core-utils/simple-dialog/services/simple-dialog.service';
import { AddEditSavedSearchDialogComponent } from '@saved-search/components/add-edit-saved-search-dialog/add-edit-saved-search-dialog.component';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import * as googleAnalyticsActions from '@core-layout/app/store/actions/google-analytics.actions';
import { SavedSearchListComponent } from '@saved-search/components/saved-search-list/saved-search-list.component';
import { SaveYourSearchComponent } from '@saved-search/components/save-your-search/save-your-search.component';
import { MatchMediaService } from '@media/services/match-media.service';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { SavedSearchData } from '@saved-search/models/saved-search-data';

@Injectable()
export class SavedSearchDialogsEffects {
    constructor(
        private readonly actions$: Actions,
        public readonly customerActivityService: CustomerActivityService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly simpleDialogService: SimpleDialogService,
        private readonly matDialog: MatDialog,
        private readonly matchMediaService: MatchMediaService,
        private readonly bottomSheet: MatBottomSheet
    ) { }

    public readonly showSavedSearchList$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.showSavedSearchList),
        map(({ showCreateOption }) => {
            return this.matchMediaService.activeMediaQuery === 'xs'
                ? savedSearchActions.showSavedSearchListBottomSheet()
                : savedSearchActions.showSavedSearchListDialog({ showCreateOption });
        })
    ));

    public readonly showSavedSearchListDialog$ = createEffect(
        () => this.actions$.pipe(
            ofType(savedSearchActions.showSavedSearchListDialog),
            switchMap(({ showCreateOption }) => {
                const dialogConfig: MatDialogConfig = { autoFocus: false, restoreFocus: false, panelClass: 'saved-search-modal' };

                const dialogRef = this.matDialog.open(SavedSearchListComponent, dialogConfig);

                dialogRef.componentInstance.showCreateOption = showCreateOption;

                return dialogRef.componentInstance.closed.pipe(tap(() => dialogRef.close()));
            })
        ),
        { dispatch: false }
    );

    public readonly showSavedSearchListBottomSheet$ = createEffect(
        () => this.actions$.pipe(
            ofType(savedSearchActions.showSavedSearchListBottomSheet),
            switchMap(() => {
                const config: MatBottomSheetConfig = { panelClass: ['saved-search-bottomsheet'], data: { showCreateOption: true } };

                const bottomSheetRef = this.bottomSheet.open(SavedSearchListComponent, config);

                return bottomSheetRef.instance.closed.pipe(tap(() => bottomSheetRef.dismiss()));
            })
        ),
        { dispatch: false }
    );

    public readonly showSaveYourSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(savedSearchActions.showSaveYourSearch),
            concatLatestFrom(() => this.savedSearchStoreService.savedSearchesToModify$),
            map(([, savedSearches]) => {

                if (savedSearches.length === 0) {
                    return savedSearchActions.showAddEditSavedSearchDialog({ savedSearchId: null });
                }

                return this.matchMediaService.activeMediaQuery === 'xs'
                    ? savedSearchActions.showSaveYourSearchBottomSheet()
                    : savedSearchActions.showSaveYourSearchDialog();
            }));
    });

    public readonly showSaveYourSearchBottomSheet$ = createEffect(
        () => this.actions$.pipe(
            ofType(savedSearchActions.showSaveYourSearchBottomSheet),
            switchMap(() => {
                const config: MatBottomSheetConfig = { panelClass: ['save-your-search-bottomsheet'] };

                const bottomSheetRef = this.bottomSheet.open(SaveYourSearchComponent, config);

                return bottomSheetRef.instance.closed.pipe(
                    tap(() => bottomSheetRef.dismiss())
                );
            })
        ),
        { dispatch: false }
    );

    public readonly showSaveYourSearchDialog$ = createEffect(
        () => this.actions$.pipe(
            ofType(savedSearchActions.showSaveYourSearchDialog),
            switchMap(() => {
                const dialogConfig: MatDialogConfig = { autoFocus: false, restoreFocus: false, panelClass: 'save-your-search-modal' };
                const dialogRef = this.matDialog.open(SaveYourSearchComponent, dialogConfig);

                return dialogRef.componentInstance.closed.pipe(tap(() => dialogRef.close()));
            })
        ),
        { dispatch: false }
    );

    public readonly showDeleteSavedSearchDialog$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.showDeleteSavedSearchDialog),
        switchMap(({ savedSearch, actionOnSuccess }) => {
            const config = { showTitle: true, showCancel: true, title: 'DIALOGS.DELETE_SAVED_SEARCH', message: 'DIALOGS.SAVED_SEARCH_DELETION', confirmButtonText: 'DIALOGS.BUTTONS.DELETE', panelClass: 'remove-save-your-search-modal' };

            return this.simpleDialogService.showForStream(config).pipe(
                filter(Boolean),
                tap(() => actionOnSuccess()),
                map(() => savedSearchActions.deleteSavedSearch({ savedSearch }))
            );
        }),
    ));

    public readonly showAddEditSavedSearchDialog$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.showAddEditSavedSearchDialog),
        concatLatestFrom(({ savedSearchId }) => [
            this.savedSearchStoreService.savedSearches$,
            this.savedSearchStoreService.getSavedSearch(savedSearchId),
            this.foldersStoreReadService.foldersSavedSearches$,
        ]),
        switchMap(([{ savedSearchId }, savedSearches, savedSearch, foldersSavedSearches]) => {
            const folderId = foldersSavedSearches.find(x => x.savedSearchId === savedSearchId)?.folderId;
            const data = { savedSearches, savedSearch, folderId };
            const dialogConfig: MatDialogConfig = { data, autoFocus: false, restoreFocus: false, panelClass: 'saved-search-edit-modal' };

            return this.matDialog.open(AddEditSavedSearchDialogComponent, dialogConfig).afterClosed().pipe(
                filter(Boolean),
                switchMap((result: SavedSearchData) => {
                    const isUpdate = savedSearch != null;
                    const savedSearchModification = { ...result, updateCriteria: !isUpdate };

                    const actions: Action[] = [savedSearch != null
                        ? savedSearchActions.updateSavedSearchRequested({ savedSearchModification })
                        : savedSearchActions.createSavedSearchRequested({ savedSearchModification })
                    ];

                    if (isUpdate) {
                        if (folderId !== result.folderId) {
                            actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchEdition_AttachFolder }));
                        }
                    } else {
                        actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchCreation }));

                        if (result.folderId != null) {
                            actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchCreation_AttachFolder }));
                        }
                    }

                    return actions;
                })
            );
        }),
    ));
}