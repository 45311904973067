<div class="rpc-smart">

    <div class="rpc-smart-close" (click)="onClose()">

        <rpc-icon class="rpc-smart-close-icon" [iconName]="'cross'">
        </rpc-icon>

    </div>

    <div class="rpc-smart-title">
        {{'SMART_BANNER.TITLE' | translate}}
    </div>

    <div *ngIf="isIOS" class="rpc-smart-action rpc-smart-action--apple" (click)="onInstall('ios')">

        <img [src]="isDarkMode ? 'assets/images/smart-banner/apple-dark.svg' : 'assets/images/smart-banner/apple.svg'"
            alt="">

    </div>

    <div *ngIf="isAndroid" class="rpc-smart-action rpc-smart-action--android" (click)="onInstall('android')">

        <img [src]="isDarkMode ? 'assets/images/smart-banner/android-dark.svg' : 'assets/images/smart-banner/android.svg'"
            alt="">

    </div>

</div>