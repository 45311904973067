<progress-bar class="progress-bar">
</progress-bar>

<div class="refresher">

    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>

</div>
<div scrollToTopTarget class="global" id="main" [class.global--toolbar]="!configuration.layout.toolbar.hidden"
    [class.global--smart]="data.isShowSmartBanner"
    *ngLet="({ isHybrid: isHybrid$ | async, isMobile: isMobile$ | async, isShowSmartBanner: isShowSmartBanner$ | async}) as data">

    <smart-banner class="global-smart" *ngIf="data.isShowSmartBanner" [isDarkMode]="isDarkMode">
    </smart-banner>

    <toolbar class="global-toolbar" *ngIf="!configuration.layout.toolbar.hidden && !data.isHybrid && !data.isMobile">
    </toolbar>

    <div class="global-content"
        [class.global-content--without-footer]="configuration.layout.footer.hidden && data.isHybrid" cdkScrollable
        scrollToTopContainer [scrollToTopOffset]="250">

        <!--
            *ngIf="true" hack is required here for router-outlet to work
            correctly. Otherwise, it won't register the changes on the
            layout and won't update the view.
        -->
        <router-outlet *ngIf="true">
        </router-outlet>

        <div class="global-content-print">View is not supported by the Printed Version</div>

        <footer class="global-footer" *ngIf="!configuration.layout.footer.hidden && !data.isHybrid">
        </footer>

    </div>

    <navigation-panel class="global-bottombar" *ngIf="isUserAuthorized">
    </navigation-panel>

    <custom-spinner class="mp-splash-wrap" *ngIf="!configuration.layout.spinner.hidden" [showImage]="false"
        [class]="configuration.layout.spinner.hidden ? '' : 'mobile-spinner--active'">
    </custom-spinner>

</div>