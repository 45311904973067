<div class="profile-general-info" [class.profile-general-info--child]="!isPrimaryCustomer">

    <ng-container *ngIf="isPrimaryCustomer && isSelfProfile">

        <div class="profile-general-info-item">

            <div class="profile-general-info-item-title">
                {{ 'PROFILE.TITLES.EMAILS' | translate }}
            </div>

            <emails-list class="profile-general-info-item-block" [emails]="emails">
            </emails-list>

        </div>

        <mat-divider class="profile-general-info-divider">
        </mat-divider>

    </ng-container>

    <div class="profile-general-info-item">

        <div class="profile-general-info-item-title">
            {{ 'PROFILE.TITLES.PHONES' | translate }}
        </div>

        <phones-list class="profile-general-info-item-block" [phones]="phones">
        </phones-list>

    </div>

    <mat-divider class="profile-general-info-divider">
    </mat-divider>

    <div class="profile-general-info-item">

        <div class="profile-general-info-item-title">
            {{ 'PROFILE.TITLES.ADDRESSES' | translate }}
        </div>

        <addresses-list class="profile-general-info-item-block" [addresses]="addresses">
        </addresses-list>

    </div>

</div>