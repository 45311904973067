import { locale as appointmentForm } from '@appointments/components/appointment-form/i18n/en';
import { locale as appointments } from '@appointments/components/appointments/i18n/en';

const appointmentsCommon = {
    APPOINTMENTS_COMMON: {
        STATUSES: {
            AGENT_REQUESTED: 'Agent requested',
            CUSTOMER_REQUESTED: 'You requested',
            CONFIRMED: 'Confirmed',
            DECLINED: 'Declined',
            REQUESTOR: 'Requestor',
            SHOWN: 'Shown',
            UNCONFIRMED: 'Not yet confirmed',
            PENDING: 'Pending'
        },
        EXPORT: {
            BUTTONS: {
                EXPORT: 'Export'
            },
            TITLES: {
                GOOGLE: 'Google (online)',
                OUTLOOK: 'Outlook (online)',
                ISC: '.ics file',
                CALENDAR: 'Calendar'
            }
        },
        ACTIONS: {
            BUTTONS: {
                DECLINE: 'Decline',
                CANCEL: 'Cancel Appointment',
                CANCEL_MOBILE: 'Cancel',
                DELETE: 'Delete',
                ACCEPT: 'Accept',
                EDIT: 'Edit',
                ACTIONS: 'Actions'
            }
        },
        DIALOGS: {
            DELETE: 'Delete this appointment?',
            INSERT_TO_NEW_LISTING:
                'Requesting an appointment for this listing will also add it to your Pick List.',
            ALREADY_HAVE_AGENT_REQUEST:
                'You already have a pending appointment request for this listing. Please respond to it before you request another.',
            ALREADY_HAVE_YOUR_REQUEST:
                'You already have a pending appointment request for this listing. Please wait for agent respond before you request another.',
            ALREADY_HAVE_CONFIRMED:
                'You already have an upcoming appointment for this listing, are you sure you’d like to schedule another?',
            BUTTONS: {
                CREATE_APPOINTMENT: 'Request Appointment'
            }
        },
        ERRORS: {
            LISTINGS_APPOINTMENTS_LOADING: 'Failed to load listings appointments.',
            LISTING_APPOINTMENTS_LOADING: 'Failed to load listing appointments.',
            APPOINTMENT_CREATION: 'Failed to request appointment. Please, try again.',
            APPOINTMENTS_UPDATE: 'Failed to update appointment. Please, try again.',
            APPOINTMENTS_STATUS_UPDATE: 'Failed to update appointment status. Please, try again.',
            APPOINTMENTS_DELETION: 'Failed to delete appointment. Please, try again.',
            APPOINTMENTS_MARK_AS_VIEWED: 'Failed to mark appointment as viewed.',
            LISTING_REMOVED_ERROR: 'The process is aborted due to listing removal.'
        }
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...appointmentForm.data,
        ...appointments.data,
        ...appointmentsCommon
    }
};