import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import isEqual from 'lodash-es/isEqual';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';

import { DEFAULT_FOLDER_SETTINGS } from '@settings/constants/settings.constants';
import { SettingsHelper } from '@settings/settings.helper';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsApiService } from '@settings/store/services/settings-api.service';
import { SettingsStoreService } from '../services/settings-store.service';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';
import { ToastService } from '@core-services/toast.service';
import { SETTINGS_ACTION_ERRORS_LOCALIZAION } from '../constants.ts/layout-filter-settings.constants';

@Injectable()
export class SettingsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly settingsApiService: SettingsApiService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly hybridService: HybridService,
        private readonly toaster: ToastService,
    ) { }

    public readonly loadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.loadSettings),
            switchMap(() => this.settingsApiService.loadSettings()),
            share()
        )
    );

    public readonly loadNewMatchSettings$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.loadNewMatchSettings),
        concatLatestFrom(() => this.settingsStoreService.newMatchSettingsLoaded$),
        filter(([, newMatchSettingsLoaded]) => !newMatchSettingsLoaded),
        switchMap(() => this.settingsApiService.loadNewMatchSettings()),
    ));

    public readonly notifyHybridAppAboutChangesInTheme$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.loadSettingsSuccessful),
        tap(settings => {
            this.hybridService.sendMessage({ kind: 'dark-mode', value: settings.layoutSettings.darkMode });

            return settings;
        })),
        { dispatch: false }
    );

    public readonly updateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.updateSettings),
        concatLatestFrom(() => this.settingsStoreService.getSettings()),
        tap((([{ settings }]) => this.hybridService.sendMessage({ kind: 'dark-mode', value: settings.layoutSettings.darkMode }))),
        switchMap(([{ settings }, oldSettings]) => {
            const notDefaultFoldersSettings = settings.layoutSettings.foldersSettings.filter(({ folderId, ...folderSettings }) => {
                return !isEqual(folderSettings, DEFAULT_FOLDER_SETTINGS);
            });

            const settingsToUpdate = SettingsHelper.updateWithFoldersSettings(settings, notDefaultFoldersSettings);

            return [
                settingsActions.updateSettingsRequested({ newSettings: settingsToUpdate, oldSettings }),
                settingsActions.setSettings({ settings: settingsToUpdate })
            ];
        }),
        share()
    ));

    public readonly updateSettingsRequested$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.updateSettingsRequested),
        switchMap(({ newSettings, oldSettings }) => this.settingsApiService.updateSettings(newSettings, oldSettings)),
    ));

    public updateNewMatchSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.updateNewMatchSettings),
            switchMap(({ request }) => this.settingsApiService.updateNewMatchSettings(request)),
            share()
        )
    );

    public loadNeighborhoodsMapping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(settingsActions.loadNeighborhoodsMapping),
            switchMap(() => this.settingsApiService.loadNeighborhoodsMapping()),
            share()
        )
    );

    public readonly updateLayoutSettings$ = createEffect(() => this.actions$.pipe(
        ofType(settingsActions.updateLayoutSettings),
        concatLatestFrom(() => this.settingsStoreService.getSettings()),
        map(([{ layoutSettings }, oldSettings]) => {
            const settings = { ...oldSettings, layoutSettings: { ...oldSettings.layoutSettings, ...layoutSettings } };

            return settingsActions.updateSettings({ settings });
        })
    ));

    public readonly showError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                settingsActions.loadSettingsFailed,
                settingsActions.loadNewMatchSettingsFailed,
                settingsActions.updateSettingsFailed,
                settingsActions.updateNewMatchSettingsFailed,
                settingsActions.loadNeighborhoodsMappingFailed
            ),
            filter(({ error }) => SETTINGS_ACTION_ERRORS_LOCALIZAION.has(error)),
            tap(({ error }) => this.toaster.showClientError(SETTINGS_ACTION_ERRORS_LOCALIZAION.get(error)))
        ),
        { dispatch: false }
    );
}