export const locale = {
    lang: 'en',
    data: {
        PRICE_CHANGE_INFO: {
            TITLES: {
                PRICE: 'Price',
                INCREASE: 'Increase',
                DECREASE: 'Decrease',
                FROM: 'From',
                BY: 'By'
            }
        }
    }
};