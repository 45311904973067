import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { UpdatePasswordModel } from '../../interfaces/customer-settings/update-password-model';
import { ProfileBaseStoreService } from '../../store/services/profile-base-store.service';

@Component({
    selector: 'profile-settings',
    templateUrl: './profile-settings.component.html',
    styleUrls: ['./profile-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSettingsComponent {
    @Input() public isSelfProfile: boolean;
    @Input() public settings: CustomerSettings;

    constructor(
        private readonly settingsStoreService: SettingsStoreService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
    ) { }

    public onSettingsChanged(settings: CustomerSettings): void {
        this.profileBaseStoreService.updateSettings(settings);
    }

    public onDarkModeSelected(isDarkMode: boolean): void {
        this.googleAnalyticsStoreService.addDarkModeChangedEvent(isDarkMode ? 'on' : 'off');

        this.settingsStoreService.updateLayoutSettings({ darkMode: isDarkMode });
    }

    public onPasswordChanged(model: UpdatePasswordModel): void {
        this.profileBaseStoreService.updatePassword(model.data);
    }

    public onDeactivateAccount(): void {
        this.profileBaseStoreService.showDeactivateAccountDialog();
    }
}
