export const locale = {
    lang: 'en',
    data: {
        CHANGE_AGENT_DIALOG: {
            TITLES: {
                CHANGE_AGENT: 'Change Agent',
                INSTRUCTIONS: 'Please, select the Agent you will be working with for the next session',
            },
            BUTTONS: {
                CHANGE_AGENT: 'Change Agent',
                CHANGE: 'Change',
                CLOSE: 'Close',
                CANCEL: 'Cancel'
            }
        }
    }
};