import { Injectable } from '@angular/core';
import { LocalStorageConstants } from '@auth/constants/local-storage-constants';

@Injectable({ providedIn: 'root' })
export class StorageService {

    public static saveDarkMode(darkMode: boolean): void {
        localStorage.setItem(LocalStorageConstants.DarkMode, darkMode ? 'true' : 'false');
    }

    public static saveSearchMode(searchMode: boolean): void {
        localStorage.setItem(LocalStorageConstants.SearchMode, searchMode ? 'true' : 'false');
    }

    public static saveSmartBannerHidden(isHidden: boolean): void {
        localStorage.setItem(LocalStorageConstants.HideSmartBanner, isHidden ? 'true' : 'false');
    }

    public static getDarkMode(): boolean {
        return localStorage.getItem(LocalStorageConstants.DarkMode) === 'true';
    }

    public static getSearchMode(): boolean {
        return localStorage.getItem(LocalStorageConstants.SearchMode) === 'true';
    }

    public static getSmartBannerHidden(): boolean {
        return localStorage.getItem(LocalStorageConstants.HideSmartBanner) === 'true';
    }
}