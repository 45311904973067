import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { MatchMediaService } from '@media/services/match-media.service';
import { HybridService } from '../../hybrid/hybrid.service';
import { StorageService } from '@auth/services/storage.service';

@Injectable({ providedIn: 'root' })
export class SmartBannerService {
    public isSmartBannerHidden$ = new BehaviorSubject<boolean>(StorageService.getSmartBannerHidden());
    private readonly appStoreApplicationId = 6448311069;

    constructor(
        private readonly platform: Platform,
        private readonly matchMediaService: MatchMediaService,
        private readonly hybridService: HybridService
    ) { }

    public isShow(): Observable<boolean> {
        return combineLatest([
            this.hybridService.isHybrid$,
            this.matchMediaService.onMediaChange,
            this.isSmartBannerHidden$
        ]).pipe(map(([isHybrid, mediaQuery, isSmartBannerHidden]) => {
            const isChromeIOS = navigator.userAgent.includes('CriOS');
            const isPlatformSupported = isChromeIOS || this.platform.ANDROID;

            return !isHybrid && !isSmartBannerHidden && isPlatformSupported && (mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md');
        }));
    }

    public setSmartBannerHidden(isHidden: boolean): void {
        this.isSmartBannerHidden$.next(isHidden);
        StorageService.saveSmartBannerHidden(isHidden);
    }

    public hide(): void {
        this.setSmartBannerHidden(true);
    }

    public navigateToStore(platform: 'android' | 'ios'): void {
        const link = platform === 'ios'
            ? `https://apps.apple.com/us/app/chatgpt/id${this.appStoreApplicationId}`
            : 'https://play.google.com/store/apps/details?id=com.openai.chatgpt';

        window.open(link, 'blank');
    }
}
