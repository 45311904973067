<div class="customer-info-wrap">

    <edit-profile-info *ngIf="isEdit; else isView" [profileInfo]="profileInfo" (save)="onSave($event)"
        (cancel)="onCancel()">
    </edit-profile-info>

    <ng-template #isView>

        <div class="customer-info-box">

            <div class="customer-info-item customer-info-item--name">
                {{ profileInfo?.firstName + ' ' + profileInfo?.lastName }}
            </div>

            <div class="customer-info-item  customer-info-item--email">
                {{ profileInfo?.userName }}
            </div>

            <div class="customer-info-btn-wrap">

                <rpc-button class="customer-info-btn" [color]="'accent'" [title]="'PROFILE_TOP.TITLES.EDIT'"
                    [iconName]="'edit'" (clicked)="onEdit()">
                </rpc-button>

            </div>

        </div>

    </ng-template>

</div>