import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { ValidatorsMapItem } from '@core-controls/models/validators-map-item';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ValidationService } from '@core-validation/validation.service';
import { Folder } from '@folders/models/folder';
import { SavedSearch } from '@saved-search/models/saved-search';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SavedSearchForm } from '../../models/saved-search-form';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatchMediaService } from '@media/services/match-media.service';

@Component({
    selector: 'saved-search-form',
    templateUrl: './saved-search-form.component.html',
    styleUrls: ['./saved-search-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchFormComponent implements OnInit, OnChanges {
    @Input() public savedSearch: SavedSearch | null = null;
    @Input() public savedSearches: SavedSearch[] = [];
    @Input() public folders: Folder[] = [];
    @Input() public folderId: number | null = null;

    @ViewChild('mobileFolderList') mobileFolderList: TemplateRef<unknown>;
    @ViewChild('addEditFolderForm') addEditFolderForm: TemplateRef<unknown>;

    public form: FormGroup;
    public nameControlOptions: RpcInputOptions;
    public folderControlOptions: RpcSelectControltOptions;
    public includePreviousMatches?: RpcCheckboxState;
    public shouldSearchNewMatches: boolean;
    public isCreateFolderFormVisible = false;
    public readonly defaultNewSavedSearchId = Math.floor(Math.random() * -99999);
    public readonly isMobile$ = this.matchMediaService.onMediaChange.pipe(map(mediaQuery => mediaQuery === 'xs'));

    public readonly folderNotSelectedId = -1;
    private readonly translatePipe = new TranslatePipe(this.translateService, this.changeDetectorRef);
    private bottomsheetRef: MatBottomSheetRef;
    private newlyCreatedFolderName: string;

    constructor(
        private readonly validationService: ValidationService,
        private readonly formBuilder: FormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly translateService: TranslateService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly bottomSheet: MatBottomSheet,
        private readonly matchMediaService: MatchMediaService,
    ) { }

    public get isShowIncludePreviousMatchesCheckbox(): boolean {
        const folderId = this.folderControlValue;

        return this.savedSearch != null && folderId != null && folderId !== this.folderNotSelectedId;
    }

    public get folderControlValue(): number {
        return this.form.get('folder').value as number;
    }

    public get nameControlValue(): string {
        return this.form.get('name').value as string;
    }

    public ngOnInit(): void {
        this.buildForm();
    }

    public ngOnChanges(changes: SimpleChanges<SavedSearchFormComponent>): void {
        this.recreateFormControls();
    }

    public onCreateFolderClicked(model: IManageFolderModel): void {
        this.onCloseCreateFolderControl();

        this.foldersStoreWriteService.create(model.name);

        this.googleAnalyticsStoreService.addCreateFolderEvent('saved_search');
    }

    public getFormValue(): SavedSearchForm {
        const folderId = this.folderControlValue !== this.folderNotSelectedId ? this.folderControlValue : null;

        return {
            id: this.savedSearch?.id ?? this.defaultNewSavedSearchId,
            name: this.nameControlValue,
            folderId,
            includePreviousMatches: folderId != null && this.includePreviousMatches === 'checked',
            searchNewMatches: this.shouldSearchNewMatches
        };
    }

    public onSearchNewMatchesChange(value: boolean) {
        this.shouldSearchNewMatches = value;
    }

    public getNewFolderName(): string {
        let folderName = this.nameControlValue;

        if (folderName == null) {
            return null;
        }

        while (this.folders.some(({ name }) => name === folderName)) {
            folderName += '1';
        }

        return folderName;
    }

    public onCloseCreateFolderControl(): void {
        this.isCreateFolderFormVisible = false;
    }

    public onShowCreateFolderControl(): void {
        if (this.matchMediaService.activeMediaQuery === 'xs') {
            this.bottomsheetRef.dismiss();

            const actionOnSuccess = (model: IManageFolderModel) => this.newlyCreatedFolderName = model.name;

            this.foldersStoreWriteService.showAddEditFolderDialog({ name: this.getNewFolderName(), eventSource: 'saved_search', actionOnSuccess });
        } else {
            this.isCreateFolderFormVisible = true;
        }
    }

    public onFolderMobileClick(): void {
        if (this.matchMediaService.activeMediaQuery === 'xs') {
            this.bottomsheetRef = this.bottomSheet.open(this.mobileFolderList, { panelClass: 'saved-search-mobile-options-wrap' });
        }
    }

    public onCloseFolderSelection(): void {
        this.bottomsheetRef.dismiss();
    }

    public onFolderSelected(folderId: number): void {
        this.folderControlOptions = new RpcSelectControltOptions(this.form, 'folder', this.getFolderSelectOptions(), folderId);

        this.changeDetectorRef.detectChanges();

        this.onCloseFolderSelection();
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({});
        this.createFormControls(this.savedSearch?.name, this.folderId);
    }

    private recreateFormControls(): void {
        if (this.form == null) {
            return;
        }

        const savedSearchesName = this.nameControlValue;
        const folderId = Boolean(this.newlyCreatedFolderName)
            ? this.folders.find(({ name }) => name === this.newlyCreatedFolderName).id
            : this.folderControlValue;

        this.form.removeControl('folder');
        this.form.removeControl('name');
        this.createFormControls(savedSearchesName, folderId);

        this.newlyCreatedFolderName = null;
    }

    private createFormControls(savedSearchName: string, folderId: number): void {
        const savedSearchesNamesWithoutCurrent = this.savedSearches.reduce(
            (names, { id, name }) => this.savedSearch?.id === id ? names : [...names, name],
            Array<string>()
        );

        this.nameControlOptions = new RpcInputOptions(this.form, 'name', savedSearchName ?? '', this.getNameValidators(savedSearchesNamesWithoutCurrent));
        this.folderControlOptions = new RpcSelectControltOptions(this.form, 'folder', this.getFolderSelectOptions(), folderId ?? this.folderNotSelectedId);
        this.includePreviousMatches = 'checked';
        this.shouldSearchNewMatches = this.shouldSearchNewMatches ?? this.savedSearch?.searchNewMatches;
    }

    private getFolderSelectOptions(): RpcSelectOption[] {
        const options = this.folders.map((folder): RpcSelectOption => ({
            title: folder.name,
            value: folder.id,
        }));

        options.unshift({
            title: this.translatePipe.transform('SAVED_SEARCH_FORM.OPTIONS.NOT_SELECTED') as string,
            value: this.folderNotSelectedId,
        });

        return options;
    }

    private getNameValidators(savedSearchesNames: string[]): ValidatorsMapItem[] {
        return [
            {
                message: 'SAVED_SEARCH_FORM.VALIDATION_ERRORS.REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            },
            {
                message: 'SAVED_SEARCH_FORM.VALIDATION_ERRORS.WHITESPACES',
                showError: (control: FormControl) => control.hasError('whitespaces'),
                validator: this.validationService.whitespacesValidator
            },
            {
                message: 'SAVED_SEARCH_FORM.VALIDATION_ERRORS.UNIQUE',
                showError: (control: FormControl) => control.hasError('uniqueValue'),
                validator: this.validationService.uniqueValuesValidatior(savedSearchesNames, true)
            }
        ];
    }
}
