import { Injectable } from '@angular/core';
import { MessageHandler } from './message-handler';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';

export interface HasCandidatesMessage {
    hasCandidates: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class HasCandidatesMessageHandler implements MessageHandler<HasCandidatesMessage> {

    constructor(private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService) {
    }
    
    public handle(message: HasCandidatesMessage): void {
        this.collaborationSpaceStoreService.loadHasMultipleCollaborationSpacesSuccessful(message.hasCandidates);
    }
}

