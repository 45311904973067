import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'saved-search-option',
    templateUrl: './saved-search-option.component.html',
    styleUrls: ['./saved-search-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchOptionComponent {
    @Input() public savedSearchName: string;
    @Input() public savedSearchIcon = 'saved-search';
    @Input() public disabled: boolean;

    @Output() public clicked = new EventEmitter<void>();

    public onSavedSearchClick(): void {
        if (!this.disabled) {
            this.clicked.emit();
        }
    }
}
