export const locale = {
    lang: 'en',
    data: {
        EDIT_PROFILE_INFO: {
            TITLES: {
                EDIT_PROFILE: 'Edit Profile',
                FIRST_NAME: 'First Name',
                LAST_NAME: 'Last Name',
                MAIN_EMAIL: 'Main Email',
                EMAIL: 'Email',
                CANCEL: 'Cancel',
                SAVE: 'Save',
                CLOSE: 'Close',
            },
            VALIDATION_ERRORS: {
                FIRST_NAME_REQUIRED: 'First name is required',
                LAST_NAME_REQUIRED: 'Last name is required'
            }
        }
    }
};