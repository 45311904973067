import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';

import { CustomerInfo } from '../../interfaces/customer-info/customer-info';
import { Email } from '../../interfaces/customer-info/email';
import { Phone } from '../../interfaces/customer-info/phone';
import { Address } from '../../interfaces/customer-info/address';

@Component({
    selector: 'general-info-form',
    templateUrl: './general-info-form.component.html',
    styleUrls: ['../general-info-base/general-info-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInfoFormComponent {
    @Input() public isPrimaryCustomer: boolean;
    @Input() public isSelfProfile: boolean;
    @Input() public emails: Email[];
    @Input() public phones: Phone[];
    @Input() public addresses: Address[];
    @Input() public isFullVersion = true;

    public form = this.formBuilder.group({});
    public readonly emailControlName = 'emails';
    public readonly phoneControlName = 'phones';
    public readonly addressControlName = 'addresses';

    constructor(private readonly formBuilder: FormBuilder) { }

    public cancel(): void {
        this.cancelEdit();
    }

    public save(): CustomerInfo | null {
        if (this.form.invalid) {
            this.form.markAllAsTouched();

            return null;
        }

        const emails = ((this.form.controls[this.emailControlName] as FormArray)?.getRawValue() ?? []) as Email[];
        const phones = (this.form.controls[this.phoneControlName] as FormArray).getRawValue() as Phone[];
        const addresses = (this.form.controls[this.addressControlName] as FormArray).getRawValue() as Address[];

        this.cancelEdit();

        return new CustomerInfo(phones, emails, addresses);
    }

    private cancelEdit(): void {
        this.form.removeControl(this.addressControlName);
        this.form.removeControl(this.emailControlName);
        this.form.removeControl(this.phoneControlName);
    }
}