<div id="{{notificationTargetScrollTo}}" class="rpc-notifications"
    *ngLet="isShowOnlyUnviewedNotifications$ | async as isShowOnlyUnviewedNotifications">

    <div class="rpc-notifications-header">

        <div class="rpc-notifications-title">
            {{ 'NOTIFICATIONS.TITLES.NOTIFICATIONS' | translate}}
        </div>

        <rpc-icon-button class="rpc-notifications-close" [iconName]="'cross'" [title]="'NOTIFICATIONS.TITLES.CLOSE'"
            (clicked)="onClose()">
        </rpc-icon-button>

        <div class="rpc-notifications-clear" *ngIf="(flatNotifications$ | async).length > 0" matRipple
            (click)="onMarkAllViewed()">
            {{ 'NOTIFICATIONS.TITLES.MARK_ALL_AS_READ' | translate}}
        </div>

        <mat-slide-toggle class="rpc-notifications-toogle" [labelPosition]="'after'"
            [checked]="isShowOnlyUnviewedNotifications"
            (change)="onShowOnlyUnviewedFilterChanged(!isShowOnlyUnviewedNotifications)">

            <span class="rpc-notifications-toogle-text">
                {{ 'NOTIFICATIONS.TITLES.ONLY_SHOW_UNREAD' | translate }}
            </span>

        </mat-slide-toggle>

    </div>

    <mat-tab-group class="rpc-notifications-tabs" *ngLet="unreadNotificationsCounters$ | async as counters"
        [selectedIndex]="activeTabIndex$ | async" [color]="'accent'" rpcScrollFix
        (selectedTabChange)="onSelectedTabChanged($event)">

        <ng-container *ngFor="let tabData of tabsData;">

            <mat-tab>

                <ng-template mat-tab-label>

                    <div [rpcBadge] [rpcBadgeAlert]="counters[tabData.counterKey]">
                        {{ tabData.title | translate }}
                    </div>

                </ng-template>

                <notification-list [notifications]="tabData.notifications$ | async"
                    (navigateToNotificationEntity)="onNavigateToNotificationEntity($event)">
                </notification-list>

            </mat-tab>

        </ng-container>

    </mat-tab-group>

</div>