import { Inject, Injectable, NgZone } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject, firstValueFrom } from 'rxjs';

import { RECONNECTION_TIME_INTERVALS, USER_DEACTIVATED_METHOD_NAME } from '@notifications/constants/communication.constants';
import { UserStoreService } from '@auth/store/services/user-store.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {

    private hubConnection: HubConnection = null;

    constructor(
        @Inject('NOTIFICATIONS_API_URL') private readonly notificationsApiUrl: { url: string },
        private readonly ngZone: NgZone,
        private readonly userStoreService: UserStoreService,
    ) { }

    public createHub(hubName: string, tokenObservable: Observable<string>): void {
        this.ngZone.runOutsideAngular(() => {
            void this.hubConnection?.hub?.stop();

            const connectionOptions = {
                accessTokenFactory: () => firstValueFrom(tokenObservable),
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            };

            this.hubConnection = {
                hubName,
                hub: new signalR.HubConnectionBuilder()
                    .withUrl(this.notificationsApiUrl.url + hubName, connectionOptions)
                    .withAutomaticReconnect(RECONNECTION_TIME_INTERVALS)
                    .configureLogging(signalR.LogLevel.Warning)
                    .build()
            };

            this.hubConnection.hub.on(USER_DEACTIVATED_METHOD_NAME, () => this.userStoreService.logout());

            this.hubConnection.hub.start()
                .then(() => console.log(`[${hubName}] Hub Connection Created`))
                .catch(error => console.error(`[${hubName}] Hub Connection Creation Failure: ${error}`));
        });
    }

    public removeHub(hubName: string): void {
        this.ngZone.runOutsideAngular(() => {
            if (this.hubConnection != null) {
                this.hubConnection.hub.off(USER_DEACTIVATED_METHOD_NAME);

                this.hubConnection.hub.stop()
                    .then(() => console.log(`[${hubName}] Hub Connection Closed`))
                    .catch(error => console.error(`[${hubName}] Hub Connection Closing Failure: ${error}`));
            }
        });
    }

    public createHubListener<TResult>(listenerName: string): Subject<TResult> {
        const listenResultSubject = new Subject<TResult>();

        this.removeHubListener(listenerName);

        this.ngZone.runOutsideAngular(() => {
            this.hubConnection.hub.on(listenerName, (data: TResult) => {
                listenResultSubject.next(data);
            });
        });

        return listenResultSubject;
    }

    public removeHubListener(listenerName: string): void {
        this.ngZone.runOutsideAngular(() => {
            this.hubConnection.hub.off(listenerName);
        });
    }
}

export class HubConnection {
    public hubName: string;
    public hub: signalR.HubConnection;
}