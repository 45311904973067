import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { NewAccountDataModel, OldAccountDataModel } from '../../interfaces/customer-info/account-data-model';
import { Email } from '../../interfaces/customer-info/email';
import { TopProfileInfo } from '../../interfaces/customer-info/top-profile-info';

@Component({
    selector: 'edit-profile-info',
    templateUrl: './edit-profile-info.component.html',
    styleUrls: ['./edit-profile-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProfileInfoComponent {
    @Input()
    public set profileInfo(profileInfo: TopProfileInfo) {
        if (profileInfo == null) {
            return;
        }

        const { firstName, lastName, emails, userName, isAdditionalProfile } = profileInfo;

        this.firstName = firstName;
        this.lastName = lastName;
        this.userName = userName;
        this.emails = emails;
        this.isAdditionalProfile = isAdditionalProfile;

        this.buildForm(firstName, lastName, emails);
    }

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<{ newData: NewAccountDataModel, oldData: OldAccountDataModel }>();

    public accountDataForm = this.formBuilder.group({});
    public firstNameControlOptions: RpcInputOptions;
    public lastNameControlOptions: RpcInputOptions;
    public mainEmailSelectOptions: RpcSelectControltOptions;
    public mainAdditionalProfileEmailOptions: RpcInputOptions;

    public firstName: string;
    public lastName: string;
    public userName: string;

    public isAdditionalProfile: boolean;

    private emails: Email[] = [];

    constructor(
        private readonly formBuilder: FormBuilder
    ) { }

    public onCancel(): void {
        this.cancel.emit();
    }

    public onSave(): void {
        const { firstName, lastName, accountName } = this.accountDataForm.value as { firstName: string, lastName: string, accountName: string };

        const email = this.isAdditionalProfile ? this.userName : accountName;
        const newAccountEmail = this.emails.find(({ emailAddress }) => emailAddress === email);

        const newData: NewAccountDataModel = { firstName, lastName, newAccountName: email, oldAccountName: this.userName };
        const oldData: OldAccountDataModel = {
            firstName: this.firstName,
            lastName: this.lastName,
            oldAccountName: this.userName,
            newAccountName: newAccountEmail.emailAddress,
            newAccountEmailPreviousCarbonCopy: newAccountEmail.isCarbonCopy
        };

        this.save.emit({ newData, oldData });
    }

    private buildForm(firstName: string, lastName: string, emails: Email[]): void {
        this.firstNameControlOptions = {
            formGroup: this.accountDataForm,
            controlName: 'firstName',
            defaultValue: firstName,
            validatorsMap: [
                {
                    message: 'EDIT_PROFILE_INFO.VALIDATION_ERRORS.FIRST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.lastNameControlOptions = {
            formGroup: this.accountDataForm,
            controlName: 'lastName',
            defaultValue: lastName,
            validatorsMap: [
                {
                    message: 'EDIT_PROFILE_INFO.VALIDATION_ERRORS.LAST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        const loginEmail = emails.find(x => x.isForLogin)?.emailAddress;

        this.mainEmailSelectOptions = new RpcSelectControltOptions(
            this.accountDataForm,
            'accountName',
            emails.map(email => new RpcSelectOption(email.emailAddress, email.emailAddress)),
            loginEmail
        );

        this.mainAdditionalProfileEmailOptions = {
            formGroup: this.accountDataForm,
            controlName: 'additionalProfileAccountName',
            defaultValue: loginEmail
        };
    }
}
