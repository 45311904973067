import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MAXIMUM_ADDITIONAL_PROFILES_COUNT } from '@profile/components/additional-profiles-tab/constants/additional-profiles-tab.constants';

import { ProfileBaseState } from '../states/profile-base.state';
import { ChildAccountStatus } from '../../interfaces/child-account/child-account-status';
import * as userSelector from '@auth/store/selectors/user.selector';

const selectProfileBase = createFeatureSelector<ProfileBaseState>('profile-base');

export const selectAdditionalProfiles = createSelector(
    selectProfileBase,
    state => state == null || state.additionalProfiles == null ? [] : state.additionalProfiles
);
export const selectActiveAdditionalProfiles = createSelector(
    selectAdditionalProfiles,
    profiles => profiles.filter(x => x.status === ChildAccountStatus.Active)
);

export const selectSelectedAdditionalProfileId = createSelector(selectProfileBase, state => state?.selectedAdditionalProfileId);

export const selectCanAddAdditionalProfile = createSelector(
    selectAdditionalProfiles,
    additionalProfiles => additionalProfiles.length < MAXIMUM_ADDITIONAL_PROFILES_COUNT
);

export const selectAdditionalProfilesEmails = createSelector(selectAdditionalProfiles, additionalProfiles => additionalProfiles.map(x => x.email));

export const selectIsSelfProfile = createSelector(
    selectSelectedAdditionalProfileId,
    userSelector.selectCustomerId,
    (selectedAdditionalProfileId, customerId) => selectedAdditionalProfileId == null || selectedAdditionalProfileId === customerId
);