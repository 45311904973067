import { Injectable } from '@angular/core';
import 'browser-report';

import { Telemetry } from '@customer-activity/models/telemetry';
import { ApplicationUpdateService } from '@notifications/services/application-update.service';
import { HybridService } from '../../../core-modules/hybrid/hybrid.service';

@Injectable({ providedIn: 'root' })
export class TelemetryService {

    constructor(
        private readonly applicationUpdateService: ApplicationUpdateService,
        private readonly hybridService: HybridService
    ) { }

    public getTelemetry(): Telemetry {
        const navigator = window.navigator;
        const browserData = window.browserReportSync();
        const browserInfo = {
            browser: `${browserData.browser.name} ${browserData.browser.version}`,
            operatingSystem: `${browserData.os.name} ${browserData.os.version}`,
            ip: browserData.ip,
            cookieEnabled: browserData.cookies,
            flashVersion: browserData.flash.version,
            javaVersion: browserData.java.version,
            webSocketsSupport: browserData.websockets,
            browserSize: `${browserData.viewport.width} x ${browserData.viewport.height}`,
            screenSize: `${browserData.screen.width} x ${browserData.screen.height} @ ${browserData.screen.dppx.toFixed(0)}x`,
            colorDepth: `${browserData.screen.colors}`,
            fullUserAgentString: navigator.userAgent,
            currentPage: window.location.pathname,
            hostname: window.location.hostname
        };

        const applicationInfo = {
            version: this.applicationUpdateService.getVersion(),
            published: this.applicationUpdateService.getBuildDate()
        };

        const result: Telemetry = {
            browserInfo,
            applicationInfo
        };
        
        if (this.hybridService.isHybrid$.value){
            result.hybridAppInfo = {
                version: this.hybridService.appVersion,
                deviceModel: this.hybridService.deviceModel
            };
        }
        
        return result;
    }
}