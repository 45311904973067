import { filter, take } from 'rxjs/operators';

import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { AppService } from '@core-layout/app/services/app.service';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';
import { DynamicLinkService } from '@core-layout/app/dynamic-link.service';
import { environment } from 'environments/environment';

export const initializeApplication = (
    dynamicLinkService: DynamicLinkService,
    tokenInitializerService: TokenInitializerService,
    appService: AppService,
    hybridService: HybridService) => {

    if (HybridService.isHybridApp()) {
        return (): Promise<boolean> => new Promise(resolve => {
            hybridService.isReady$
                .pipe(filter(isReady => isReady), take(1))
                .subscribe(_ => {
                    void init(appService, hybridService, tokenInitializerService)
                        .then(() => resolve(true));
                });

            hybridService.start();
        });
    }

    dynamicLinkService.setUri(environment.baseGraphQlUrl);

    return (): Promise<boolean> => init(appService, hybridService, tokenInitializerService)
        .then(() => true);
};

function init(appService: AppService, hybridService: HybridService, tokenInitializerService: TokenInitializerService) {
    return Promise.all([
        appService.initializeGoogleAnalytics(),
        appService.initializeNewRelic(),
        tokenInitializerService.initialize(),
        appService.initializeSvgSprite(),
        appService.initializeCompanyConfiguration(),
        appService.initializeDeviceInfo()
    ])
        .then(() => hybridService.sendMessage({ kind: 'main-view-loaded' }));
}
