import { Injectable } from '@angular/core';
import { MessageHandler } from './message-handler';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GoBackMessageHandler implements MessageHandler<void> {

    constructor(private readonly location: Location) {
    }

    public handle(): void {
        this.location.back();
    }
}