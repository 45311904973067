import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { NewMatchSettingsService } from '@profile/services/new-match-settings.service';
import { NewAccountDataModel, OldAccountDataModel } from '../../../profile-base/interfaces/customer-info/account-data-model';
import { CustomerInfo } from '../../../profile-base/interfaces/customer-info/customer-info';
import { NewMatchSettingsModel } from '../../../profile-base/interfaces/new-match-settings/new-match-settings.model';
import * as additionalProfileActions from '../actions/additional-profile.actions';
import * as additionalProfileSelector from '../selectors/additional-profile.selector';

@Injectable({ providedIn: 'root' })
export class AdditionalProfileStoreService {
    constructor(
        private readonly store: Store,
        private readonly newMatchSettingsService: NewMatchSettingsService
    ) { }

    public readonly topProfileInfo$ = this.store.select(additionalProfileSelector.selectTopProfileInfo);

    public readonly phones$ = this.store.select(additionalProfileSelector.selectPhones);

    public readonly emails$ = this.store.select(additionalProfileSelector.selectEmails);

    public readonly addresses$ = this.store.select(additionalProfileSelector.selectAddresses);

    public readonly profileImage$ = this.store.select(additionalProfileSelector.selectProfileImage);

    public readonly customerFullName$ = this.store.select(additionalProfileSelector.selectCustomerFullName);

    public readonly settings$ = this.store.select(additionalProfileSelector.selectSettings);
    public readonly generalInfoLoaded$ = this.store.select(additionalProfileSelector.selectGeneralInfoLoaded);
    public readonly settingsLoaded$ = this.store.select(additionalProfileSelector.selectSettingsLoaded);
    public readonly newMatchSettingsLoaded$ = this.store.select(additionalProfileSelector.selectNewMatchSettingsLoaded);

    public readonly newMatchSettings$ = this.store
        .select(additionalProfileSelector.selectNewMatchSettings)
        .pipe(map(settingsValue => this.newMatchSettingsService.createSettings(settingsValue)));

    public load(): void {
        this.store.dispatch(additionalProfileActions.load());
    }

    public updateCustomerInfo(customerInfo: CustomerInfo): void {
        this.store.dispatch(additionalProfileActions.updateCustomerInfo({ customerInfo }));
    }

    public updateProfileImage(image: File): void {
        this.store.dispatch(additionalProfileActions.updateProfileImage({ image }));
    }

    public deleteProfileImage(): void {
        this.store.dispatch(additionalProfileActions.deleteProfileImage());
    }

    public updateAccountData(newData: NewAccountDataModel, oldData: OldAccountDataModel): void {
        this.store.dispatch(additionalProfileActions.updateAccountData({ newData, oldData }));
    }

    public updateNewMatchSettings(settings: NewMatchSettingsModel): void {
        const request = this.newMatchSettingsService.formatRequest(settings);

        this.store.dispatch(additionalProfileActions.updateNewMatchSettings({ request }));
    }
}