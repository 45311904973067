<div class="range-filter-wrap">
    <rpc-input class="range-filter-input range-filter-input--from" [type]="'text'" [appearance]="'fill'"
        [label]="'RANGE_FILTER.TITLES.FROM'" [options]="minControlOptions" formatCurrency [isTransform]="isCurrency"
        [exceptionValues]="[anyDefaultText]" [useFraction]="false" (blurChange)="formatBorderValues()">
    </rpc-input>

    <rpc-input class="range-filter-input range-filter-input--to" [type]="'text'" [appearance]="'fill'"
        [label]="'RANGE_FILTER.TITLES.TO'" [options]="maxControlOptions" formatCurrency [isTransform]="isCurrency"
        [exceptionValues]="[anyDefaultText]" [useFraction]="false" (blurChange)="formatBorderValues()">
    </rpc-input>

    <rpc-range-slider class="range-filter" [value]="rangeSliderValue" [formatLabel]="formatLabel" [min]="minValue"
        [step]="step" [max]="maxValue" (output)="onRangeSliderChanged($event)">
    </rpc-range-slider>
</div>