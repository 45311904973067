import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { ValidationService } from '@core-validation/validation.service';
import { UpdatePasswordModel } from '../../interfaces/customer-settings/update-password-model';
import { UpdatePasswordRequest } from '../../interfaces/customer-settings/update-password-request';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {

    @Input() isAdditionalProfile: boolean;
    @Output() passwordChanged: EventEmitter<UpdatePasswordModel> = new EventEmitter<UpdatePasswordModel>();

    public changePasswordForm: FormGroup;
    public oldPasswordControlOptions: RpcInputOptions;
    public newPasswordControlOptions: RpcInputOptions;
    public confirmPasswordControlOptions: RpcInputOptions;

    constructor(
        private readonly validationService: ValidationService) {
    }

    public get isUpdateButtonDisabled(): boolean {
        return !this.changePasswordForm.dirty
            || this.changePasswordForm.invalid
            || (!this.isAdditionalProfile && this.changePasswordForm.controls.oldPassword.value == null)
            || this.changePasswordForm.controls.newPassword.value == null
            || this.changePasswordForm.controls.confirmPassword.value == null;
    }

    public ngOnInit(): void {
        this.buildForm();
    }

    public cancel(): void {
        this.resetForm();
    }

    public save = () => {
        if (this.changePasswordForm.invalid) {
            return;
        }

        this.passwordChanged.emit({ data: this.changePasswordForm.getRawValue() as UpdatePasswordRequest });

        this.resetForm();
    };

    private buildForm(): void {
        const requiredValidator = {
            message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.PASSWORD_REQUIRED',
            showError: (control: FormControl) => control.hasError('required'),
            validator: Validators.required
        };

        this.changePasswordForm = new FormGroup({});
        this.oldPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'oldPassword',
            validatorsMap: [requiredValidator]
        };

        this.newPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'newPassword',
            validatorsMap: [
                requiredValidator,
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.NUMBER_REQIRED',
                    showError: (control: FormControl) => control.hasError('noNumber'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.CAPITAL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noCapitalCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.SMALL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noSmallCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.SPECIAL_REQIRED',
                    showError: (control: FormControl) => control.hasError('noSpecialCharacter'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.MINIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('minlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.MAXIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('maxlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.PASSWORDS_MATCH',
                    showError: (control: FormControl) => control.hasError('matching'),
                    validator: this.validationService.getNotMatchValidator('oldPassword')
                }
            ]
        };

        this.confirmPasswordControlOptions = {
            formGroup: this.changePasswordForm,
            controlName: 'confirmPassword',
            validatorsMap: [
                requiredValidator,
                {
                    message: 'CHANGE_PASSWORD.VALIDATION_ERRORS.PASSWORDS_NOT_MATCH',
                    showError: (control: FormControl) => control.hasError('notMatching'),
                    validator: this.validationService.getMatchValidator('newPassword')
                }
            ]
        };
    }

    private resetForm(): void {
        this.changePasswordForm.reset();
    }
}
