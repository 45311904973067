import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ValidationService } from '@core-validation/validation.service';
import { Email } from '../../../profile-base/interfaces/customer-info/email';
import { EmailTypes } from '../../../profile-base/interfaces/enums/email-types.enum';
import { EmailControlsGroup } from './models/email-controls-group';

@Component({
    selector: 'emails-edit-form',
    templateUrl: './emails-edit-form.component.html',
    styleUrls: ['../../../profile-base/components/profile-info-base/profile-info-base.component.scss', './emails-edit-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailsEditFormComponent implements OnChanges {
    @Input() public parentGroup: FormGroup;
    @Input() public controlName: string;
    @Input() public emails: Email[];

    private readonly emailSelectOptionValues: RpcSelectOption[] = [
        { title: 'EMAILS_EDIT_FORM.TITLES.OFFICE', value: EmailTypes.office },
        { title: 'EMAILS_EDIT_FORM.TITLES.PERSONAL', value: EmailTypes.personal },
        { title: 'EMAILS_EDIT_FORM.TITLES.OTHER', value: EmailTypes.other }
    ];

    private emailFormGroups: FormArray;
    private tempEmailId = -1;

    public emailControlsGroups: EmailControlsGroup[];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly validationService: ValidationService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngOnChanges(changes: SimpleChanges<EmailsEditFormComponent>): void {
        const { controlName, parentGroup, emails } = changes;

        if ([controlName?.currentValue, parentGroup?.currentValue, emails?.currentValue].every(x => x != null)) {
            this.initializeEmailControls(controlName.currentValue, parentGroup.currentValue, emails.currentValue);
        }
    }

    public addEmail(): void {
        const [emailControlGroup, currentGroup] = this.createEmailControlGroup(this.tempEmailId);

        this.emailControlsGroups.push(emailControlGroup);
        this.emailFormGroups.push(currentGroup);
        this.tempEmailId -= 1;
        this.changeDetectorRef.detectChanges();
    }

    public removeEmail(i: number): void {
        this.emailControlsGroups.splice(i, 1);
        this.emailFormGroups.removeAt(i);
    }

    private initializeEmailControls(controlName: string, parentGroup: FormGroup, emails: Email[]): void {
        this.emailControlsGroups = [];

        this.emailFormGroups = this.formBuilder.array([], this.validationService.uniqueByValidatior('emailAddress'));

        emails.forEach(email => {
            const [emailControlGroup, currentGroup] = this.createEmailControlGroup(email.id, email);

            this.emailControlsGroups.push(emailControlGroup);
            this.emailFormGroups.push(currentGroup);
        });

        parentGroup.addControl(controlName, this.emailFormGroups);
    }

    private createEmailControlGroup(id: number, email: Email = null): [EmailControlsGroup, FormGroup] {
        const currentGroup = this.formBuilder.group({
            id,
            isForLogin: email?.isForLogin ?? false
        });

        const emailTypeValidatorsMap = [{
            message: 'EMAILS_EDIT_FORM.ERRORS.CLIENT.EMAIL_TYPE_REQUIRED',
            showError: (control: FormControl) => control.hasError('required'),
            validator: Validators.required
        }];
        const emailAddressValidatorsMap = [
            {
                message: 'EMAILS_EDIT_FORM.ERRORS.CLIENT.EMAIL_REQUIRED',
                showError: (control: FormControl) => control.hasError('required'),
                validator: Validators.required
            },
            {
                message: 'EMAILS_EDIT_FORM.ERRORS.CLIENT.EMAIL_INVALID',
                showError: (control: FormControl) => control.hasError('email'),
                validator: this.validationService.emailWithDotValidator
            },
            {
                message: 'EMAILS_EDIT_FORM.ERRORS.CLIENT.EMAIL_NOT_UNIQUE',
                showError: (control: FormControl) => control.hasError('uniqueBy'),
                validator: null
            }
        ];

        const isCarbonCopyControl = new FormControl(email != null ? email.isCarbonCopy : false);

        currentGroup.addControl('isCarbonCopy', isCarbonCopyControl);

        const emailControlGroup = {
            id: 0,
            selectOptions: new RpcSelectControltOptions(currentGroup, 'type', this.emailSelectOptionValues, email?.type, emailTypeValidatorsMap),
            inputOptions: new RpcInputOptions(currentGroup, 'emailAddress', email?.emailAddress, emailAddressValidatorsMap),
            isForLogin: email?.isForLogin,
            isCarbonCopyControl
        };

        return [emailControlGroup, currentGroup];
    }
}