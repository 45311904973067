export enum RpcRoute {
    Login = 'login',
    ForgotPassword = 'forgot-password',
    RestorePassword = 'restore-password',
    Registration = 'registration',
    Portfolio = 'portfolio',
    SearchListings = 'search-listings',
    FindHome = 'find-your-home',
    Appointments = 'appointments',
    Listing = 'listing/:id',
    SharedListing = 'shared-listing',
    SharedListingWithCode = 'shared-listing/:code',
    Profile = 'profile',
    NewMatch = 'new-match/:listingId',
    NotFound = 'not-found',
    MortgageReport = 'mortgage-report',
    AdditionalProfile = 'additional-profile/:id',
    AdditionalProfiles = 'additional-profiles',
    AgentSelection = 'agent-selection',
    Folder = ':folder',
    FolderFullPath = '/portfolio/:folder',
    GeneralInfo = 'general-info',
    KnowledgeBase = 'knowledge-base',
    ProfileSettings = 'settings',
    NewMatchSettings = 'new-match-settings',
}