import { createReducer, on } from '@ngrx/store';

import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsState } from '@settings/store/states/settings.state';
import { SettingsHelper } from '../../settings.helper';

const initialState = new SettingsState();

export const settingsReducer = createReducer(
    initialState,
    on(settingsActions.loadSettings, (state) => ({ ...state, isLoading: true })),
    on(settingsActions.loadSettingsSuccessful, (state, settings) => {
        return { ...state, settings: SettingsHelper.updateWithDefaultFolderSettings(settings), isLoading: false };
    }),
    on(settingsActions.loadSettingsFailed, (state) => ({ ...state, isLoading: false })),
    on(settingsActions.loadNewMatchSettingsSuccessful, settingsActions.updateNewMatchSettingsFailed, (state, { settings }) => {
        return ({ ...state, newMatchSettings: settings.settingsValues, newMatchSettingsLoaded: true });
    }),
    on(settingsActions.updateSettingsFailed, (state, { settings }) => ({ ...state, settings })),
    on(settingsActions.updateNewMatchSettings, (state, { request: { personalSettings, sharedSettings } }) => {
        return {
            ...state,
            newMatchSettings: [...personalSettings, ...sharedSettings]
        };
    }),
    on(settingsActions.loadNeighborhoodsMappingSuccessful, (state, action) => {
        if (action.neighborhoods != null) {
            return {
                ...state,
                neighborhoodsMapping: {
                    type: 'FeatureCollection',
                    features: action.neighborhoods.map(item => item.features != null && item.features.length > 0 ? item.features[0] : null)
                }
            };
        }

        return {
            ...state,
            neighborhoodsMapping: null
        };
    }),
    on(settingsActions.setSettings, (state, { settings }) => ({ ...state, settings })),
    on(settingsActions.resetState, () => ({ ...initialState }))
);