import { Address } from './address';
import { Email } from './email';
import { Phone } from './phone';

export class CustomerInfo {
    constructor(
        public readonly phones: Phone[],
        public readonly emails: Email[],
        public readonly addresses: Address[],
    ) { }
}