import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { from, merge } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { InviteUserDialogComponent } from '../../components/invite-user-dialog/invite-user-dialog.component';
import { DEFAULT_INVITE_USER_DIALOG_CONFIG } from '../../components/invite-user-dialog/invite-user-dialog.constants';
import { IInviteUserModel } from '../../invite-user/invite-user-model';
import * as profileBaseActions from '../actions/profile-base.actions';
import { ProfileBaseApiService } from '../services/profile-base-api.service';
import { ProfileBaseStoreService } from '../services/profile-base-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import * as googleAnalyticsActions from '@core-layout/app/store/actions/google-analytics.actions';
import { EditProfileInfoComponent } from '../../components/edit-profile-info/edit-profile-info.component';
import { NewAccountDataModel, OldAccountDataModel } from '../../interfaces/customer-info/account-data-model';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { SimpleDialogService } from '@core-utils/simple-dialog/services/simple-dialog.service';
import { RouteService } from '@core-layout/app/services/route.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { PROFILE_TABS_NAMES } from '../../constants/profile-tabs.constants';
import { PROFILE_ACTION_ERRORS_MAP } from '@profile/components/additional-profiles-tab/constants/profile-base.constants';
import { ToastService } from '@core-services/toast.service';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';
import { MatchMediaService } from '@media/services/match-media.service';

@Injectable()
export class ProfileBaseEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly profileBaseApiService: ProfileBaseApiService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly matDialog: MatDialog,
        private readonly userStoreService: UserStoreService,
        private readonly simpleDialogService: SimpleDialogService,
        private readonly routeService: RouteService,
        private readonly translateService: TranslateService,
        private readonly toaster: ToastService,
        private readonly matchMediaService: MatchMediaService,
        private readonly hybridService: HybridService,
    ) { }

    public readonly getAdditionalProfiles$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.loadAdditionalProfiles),
        switchMap((props) => this.profileBaseApiService.loadAdditionalProfiles(props.isActiveOnly))
    ));

    public readonly inviteUser$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.inviteUser),
        switchMap(params => this.profileBaseApiService.inviteUser(params.inviteUserModel))
    ));

    public reSendInvite$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.reSendInvite),
        switchMap(({ model }) => this.profileBaseApiService.reSendInvite(model))
    ));

    public readonly cancelInvitation$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.cancelInvitation),
        switchMap(params => this.profileBaseApiService.cancelInvitation(params.childEmail))
    ));

    public readonly deactivateChildAccountByPrimary$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.deactivateChildAccountByPrimary),
        concatLatestFrom(() => this.profileBaseStoreService.selectedAdditionalProfileId$),
        mergeMap(([{ childCustomerId }, additionalProfileId]) => {
            return this.profileBaseApiService.deactivateChildAccountByPrimary(childCustomerId ?? additionalProfileId);
        }),
    ));

    public readonly navigateToPrimaryProfilePage$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.navigateToPrimaryProfilePage, profileBaseActions.deactivateChildAccountByPrimarySuccessful),
        concatLatestFrom(() => this.hybridService.isHybrid$),
        switchMap(([, isHybrid]) => {
            const isMobile = isHybrid || new Set<string>(['xs', 'sm', 'md']).has(this.matchMediaService.activeMediaQuery);

            const routeAction = isMobile
                ? this.routeService.navigate(('/' + RpcRoute.Profile + '/' + RpcRoute.AdditionalProfiles) as RpcRoute)
                : this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.ADDITIONAL_PROFILES } });

            return from(routeAction).pipe(map(() => profileBaseActions.setActiveAdditionalProfileId({ additionalProfileId: null })));
        }),
    ));

    public readonly showInviteUserDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showInviteUserDialog),
        concatLatestFrom(() => [this.profileBaseStoreService.additionalProfilesEmails$, this.profileBaseStoreService.canAddAdditionalProfile$]),
        filter(([, , canAddAdditionalProfile]) => canAddAdditionalProfile),
        switchMap(([, additionalProfilesEmails]) => {
            const data = { portfolioEmails: additionalProfilesEmails };

            return this.matDialog.open(InviteUserDialogComponent, { ...DEFAULT_INVITE_USER_DIALOG_CONFIG, data }).afterClosed();
        }),
        filter(Boolean),
        switchMap((inviteUserModel: IInviteUserModel) => [
            profileBaseActions.inviteUser({ inviteUserModel }),
            googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.ChildCustomer_Invite })
        ])
    ));

    public readonly showResendInviteDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showResendInviteDialog),
        concatLatestFrom(() => this.profileBaseStoreService.additionalProfilesEmails$),
        switchMap(([{ childEmail }, additionalProfilesEmails]) => {
            const data = { email: childEmail, portfolioEmails: additionalProfilesEmails };

            return this.matDialog.open(InviteUserDialogComponent, { ...DEFAULT_INVITE_USER_DIALOG_CONFIG, data })
                .afterClosed().pipe(
                    filter(Boolean),
                    map(({ recepientEmail, message }: IInviteUserModel) => {
                        return profileBaseActions.reSendInvite({ model: { oldEmail: childEmail, newEmail: recepientEmail, message } });
                    })
                );
        }),
    ));

    public readonly showEditProfileInfoDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showEditProfileInfoDialog),
        switchMap(({ profileInfo }) => {
            const dialogConfig: MatDialogConfig = { autoFocus: false, restoreFocus: false, panelClass: 'edit-customer-info-modal' };

            const dialogRef = this.matDialog.open(EditProfileInfoComponent, dialogConfig);

            dialogRef.componentInstance.profileInfo = profileInfo;

            return merge(dialogRef.componentInstance.cancel, dialogRef.componentInstance.save).pipe(
                tap(() => dialogRef.close()),
                filter(result => typeof result === 'object'),
                map(({ newData, oldData }: { newData: NewAccountDataModel, oldData: OldAccountDataModel }) => {
                    return profileBaseActions.editProfileInfo({ newData, oldData, isAdditionalProfile: profileInfo.isAdditionalProfile });
                }),
            );
        })
    ));

    public readonly showDeactivateAccountDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showDeactivateAccountDialog),
        concatLatestFrom(() => [
            this.profileBaseStoreService.isSelfProfile$,
            this.userStoreService.isPrimaryCustomer$,
            this.profileBaseStoreService.selectedAdditionalProfileId$,
        ]),
        switchMap(([, isSelfProfile, isPrimaryCustomer, selectedAdditionalProfileId]) => {
            const additionalMessage = isPrimaryCustomer && isSelfProfile
                ? 'PROFILE.DIALOGS.DEACTIVATEION_MESSAGE_PRIMARY_CUSTOMER'
                : 'PROFILE.DIALOGS.DEACTIVATEION_MESSAGE_CHILD_ACCOUNT';

            const config = {
                title: 'PROFILE.TITLES.DEACTIVATE_ACCOUNT',
                showCancel: true,
                showTitle: true,
                panelClass: 'deactivate-account-modal',
                message: additionalMessage,
                confirmButtonText: 'PROFILE.TITLES.DEACTIVATE',
            };

            return this.simpleDialogService.showForStream(config).pipe(
                filter(Boolean),
                map(() => {
                    return isPrimaryCustomer && !isSelfProfile
                        ? profileBaseActions.deactivateChildAccountByPrimary({ childCustomerId: selectedAdditionalProfileId })
                        : profileBaseActions.deactivateAccount({ isPrimaryCustomer });
                })
            );
        })
    ));

    public readonly navigateToProfile$ = createEffect(
        () => this.actions$.pipe(
            ofType(profileBaseActions.navigateToProfile),
            concatLatestFrom(() => [this.profileBaseStoreService.isSelfProfile$, this.profileBaseStoreService.selectedAdditionalProfileId$]),
            tap(([, isSelfProfile, selectedAdditionalProfileId]) => {
                const navigate = isSelfProfile
                    ? this.routeService.navigate(RpcRoute.Profile)
                    : this.routeService.navigate(RpcRoute.AdditionalProfile, null, null, selectedAdditionalProfileId.toString());

                navigate.catch(() => { });
            })
        ),
        { dispatch: false }
    );

    public readonly showCancelInvitationDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showCancelInvitationDialog),
        switchMap(({ childEmail }) => {
            const message = this.translateService.instant('PROFILE.DIALOGS.MAIN_CANCEL_INVITATION_MESSAGE', { childEmail }) as string;
            const config = {
                title: 'PROFILE.TITLES.REVOKE_INVITATION',
                showTitle: true,
                showCancel: true,
                panelClass: 'deactivate-account-modal',
                message,
                confirmButtonText: 'PROFILE.TITLES.REVOKE'
            };

            return this.simpleDialogService.showForStream(config).pipe(
                filter(Boolean),
                map(() => profileBaseActions.cancelInvitation({ childEmail }))
            );
        })),
    );

    public readonly showDeactivateChildAccountDialog$ = createEffect(() => this.actions$.pipe(
        ofType(profileBaseActions.showDeactivateChildAccountDialog),
        switchMap(({ childCustomerId }) => {
            const config = {
                title: 'PROFILE.TITLES.DEACTIVATE_ACCOUNT',
                showTitle: true,
                showCancel: true,
                panelClass: 'deactivate-account-modal',
                message: 'PROFILE.DIALOGS.DEACTIVATEION_MESSAGE_CHILD_ACCOUNT_BY_PRIMARY',
                confirmButtonText: 'PROFILE.TITLES.DEACTIVATE'
            };

            return this.simpleDialogService.showForStream(config).pipe(
                filter(Boolean),
                map(() => profileBaseActions.deactivateChildAccountByPrimary({ childCustomerId }))
            );
        })),
    );

    public readonly showError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                profileBaseActions.cancelInvitationFailed,
                profileBaseActions.inviteUserFailed,
                profileBaseActions.reSendInviteFailed,
                profileBaseActions.deactivateChildAccountByPrimaryFailed
            ),
            filter(({ error }) => PROFILE_ACTION_ERRORS_MAP.has(error)),
            tap(({ error }) => this.toaster.showClientError(PROFILE_ACTION_ERRORS_MAP.get(error)))
        ),
        { dispatch: false }
    );
}