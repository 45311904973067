import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { IInviteUserModel } from '../../../profile-base/invite-user/invite-user-model';
import { ChildAccount } from '../../interfaces/child-account/child-account';
import { IResendInvitationModel } from '../../invite-user/resend-invitation-model';
import * as profileBaseActions from '../actions/profile-base.actions';
import { ProfileActionErrors } from '@profile/enums/profile-action-errors';

@Injectable({ providedIn: 'root' })
export class ProfileBaseApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public loadAdditionalProfiles(isActiveOnly: boolean): Observable<Action> {
        return this.http.get(`profile/sharedAccounts?activeOnly=${isActiveOnly}`).pipe(
            map((additionalProfiles: ChildAccount[]) => profileBaseActions.loadAdditionalProfilesSuccessful({ additionalProfiles })),
            catchError((errorResponse: HttpErrorResponse) =>
                of(profileBaseActions.loadAdditionalProfilesFailed(errorResponse.error as ApiError)))
        );
    }

    public inviteUser(inviteUserModel: IInviteUserModel): Observable<Action> {
        return this.http.post('auth/invite-child-account', inviteUserModel).pipe(
            map(() => profileBaseActions.inviteUserSuccessful({ childEmail: inviteUserModel.recepientEmail })),
            catchError((errorResponse: HttpErrorResponse) =>
                of(profileBaseActions.inviteUserFailed({ error: (errorResponse.error as ApiError).errorKey as ProfileActionErrors })))
        );
    }

    public deactivateChildAccountByPrimary(childCustomerId: number): Observable<Action> {
        return this.http.post('auth/deactivate/child', childCustomerId).pipe(
            map(() => profileBaseActions.deactivateChildAccountByPrimarySuccessful({ childCustomerId })),
            catchError((errorResponse: HttpErrorResponse) =>
                of(profileBaseActions.deactivateChildAccountByPrimaryFailed({ error: (errorResponse.error as ApiError).errorKey as ProfileActionErrors })))
        );
    }

    public reSendInvite(resendInvitationModel: IResendInvitationModel): Observable<Action> {
        return this.http.post('auth/invitation/resend', resendInvitationModel).pipe(
            map(() => profileBaseActions.reSendInviteSuccessful(resendInvitationModel)),
            catchError((errorResponse: HttpErrorResponse) =>
                of(profileBaseActions.reSendInviteFailed({ error: (errorResponse.error as ApiError).errorKey as ProfileActionErrors })))
        );
    }

    public cancelInvitation(childEmail: string): Observable<Action> {
        return this.http.post('auth/invitation/cancelInvitation', { childEmail }).pipe(
            map(() => profileBaseActions.cancelInvitationSuccessful({ childEmail })),
            catchError((errorResponse: HttpErrorResponse) =>
                of(profileBaseActions.cancelInvitationFailed({ error: (errorResponse.error as ApiError).errorKey as ProfileActionErrors })))
        );
    }
}