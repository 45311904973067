import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';

import { AgentsModule } from '@agents/agents.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RpcControlsModule } from '@core-controls/controls.module';
import { CustomSpinnerModule } from '@core-layout/custom-spinner/custom-spinner.module';
import { PaginatorModule } from '@core-layout/paginator/paginator.module';
import { ScrollToTopModule } from '@core-layout/scroll-to-top/scroll-to-top.module';
import { RpcPipesModule } from '@core-pipes/pipes.module';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { ListingsModule } from '@listings/listings.module';
import { MediaModule } from '@media/media.module';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { UsersModule } from '@users/users.module';
import { locale as english } from '../folders/i18n/en';
import { AddEditFolderDialogComponent } from './components/add-edit-folder-dialog/add-edit-folder-dialog.component';
import { AddEditFolderFormComponent } from './components/add-edit-folder-form/add-edit-folder-form.component';
import { FolderDetailsComponent } from './components/folder-details/folder-details.component';
import { FolderManagementDialogComponent } from './components/folder-management-dialog/folder-management-dialog.component';
import { FolderAttachmentDialogComponent } from './components/folder-attachment-dialog/folder-attachment-dialog.component';
import { FolderSelectionComponent } from './components/folder-selection/folder-selection.component';
import { FolderComponent } from './components/folder/folder.component';
import { FoldersViewComponent } from './components/folders-view/folders-view.component';
import { RemoveFolderDialogComponent } from './components/remove-folder-dialog/remove-folder-dialog.component';
import { FolderTooltipComponent } from './components/tooltip/folder-tooltip.component';
import { FoldersEffects } from './store/effects/folders.effects';
import { foldersReducer } from './store/reducers/folders.reducer';
import { RpcDirectivesModule } from '@core-directives/directives.module';

const components = [
    FolderDetailsComponent,
    FolderComponent,
    FoldersViewComponent,
    AddEditFolderDialogComponent,
    AddEditFolderFormComponent,
    FolderManagementDialogComponent,
    FolderSelectionComponent,
    RemoveFolderDialogComponent,
    FolderTooltipComponent,
    FolderAttachmentDialogComponent
];

@NgModule({
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        MatDividerModule,
        InViewportModule,
        NgLetModule,
        MatCheckboxModule,
        MatRippleModule,

        // Application Modules
        UsersModule,
        AgentsModule,
        MatSlideToggleModule,
        RpcControlsModule,
        RpcIconModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MediaModule,
        ScrollToTopModule,
        CustomSpinnerModule,
        PaginatorModule,
        RpcPipesModule,
        ListingsModule,
        RpcDirectivesModule,

        // Module Configuration
        StoreModule.forFeature('folders', foldersReducer),
        EffectsModule.forFeature([FoldersEffects])
    ],
    declarations: components,
    exports: components
})
export class FoldersModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
