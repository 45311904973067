import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { CheckRestoreCodeRequest } from '@auth/models/requests/check-restore-code-request';
import { ForgotPasswordRequest } from '@auth/models/requests/forgot-password-request';
import { RestorePasswordRequest } from '@auth/models/requests/restore-password-request';
import * as restorePaswordActions from '@auth/store/actions/restore-password.actions';

@Injectable({ providedIn: 'root' })
export class AuthStoreService {

    constructor(
        private readonly store: Store<{}>
    ) { }

    public sendRestorePasswordLink(userName: string): void {
        this.store.dispatch(restorePaswordActions.sendRestorePasswordLink({ request: new ForgotPasswordRequest(userName) }));
    }

    public checkRestoreCode(restoreCode: string): void {
        this.store.dispatch(restorePaswordActions.checkCode({ request: new CheckRestoreCodeRequest(restoreCode) }));
    }

    public changePassword(password: string, restoreCode: string): void {
        this.store.dispatch(restorePaswordActions.changePassword({ request: new RestorePasswordRequest(password, restoreCode) }));
    }
}