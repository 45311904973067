export const locale = {
    lang: 'en',
    data: {
        PROFILE_SETTINGS_PAGE: {
            TITLES: {
                BACK: 'Back',
                SETTINGS: 'Settings'
            }
        }
    }
};