import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as profileActions from '@profile/store/actions/profile.actions';
import * as profileSelectors from '@profile/store/selectors/profile.selector';
import { ChildAccount } from '../../../profile-base/interfaces/child-account/child-account';
import { NewAccountDataModel, OldAccountDataModel } from '../../../profile-base/interfaces/customer-info/account-data-model';
import { CustomerInfo } from '../../../profile-base/interfaces/customer-info/customer-info';

@Injectable({ providedIn: 'root' })
export class ProfileStoreService {

    constructor(
        private readonly store: Store,
    ) { }

    public readonly topProfileInfo$ = this.store.select(profileSelectors.selectTopProfileInfo);

    public readonly phones$ = this.store.select(profileSelectors.selectPhones);

    public readonly emails$ = this.store.select(profileSelectors.selectEmails);

    public readonly addresses$ = this.store.select(profileSelectors.selectAddresses);
    public readonly generalInfoLoaded$ = this.store.select(profileSelectors.selectGeneralInfoLoaded);

    public updateCustomerInfo(customerInfo: CustomerInfo): void {
        this.store.dispatch(profileActions.updateCustomerInfo({ customerInfo }));
    }

    public updateProfileImage(image: File): void {
        this.store.dispatch(profileActions.updateProfileImage({ image }));
    }

    public deleteProfileImage(): void {
        this.store.dispatch(profileActions.deleteProfileImage());
    }

    public updateAccountData(newData: NewAccountDataModel, oldData: OldAccountDataModel): void {
        this.store.dispatch(profileActions.updateAccountData({ newData, oldData }));
    }

    public openChildProfileDetails(profile: ChildAccount): void {
        this.store.dispatch(profileActions.additionalProfileDetailsOpening({ profile }));
    }
}