<div class="remove-listing-wrap" *ngLet="(listings$ | async) as listings">
    <div class="remove-listing-title-wrap">
        <div class="remove-listing-title">{{'LISTING_MANAGEMENT_DIALOG.TITLES.CONFIRMATION_MESSAGE_1' | translate}}
            {{ data.listingId == null ? listings?.length : listings[0]?.listingAddress }}
            {{ (listings?.length == 1 ? 'LISTING_MANAGEMENT_DIALOG.TITLES.LISTING' :
            'LISTING_MANAGEMENT_DIALOG.TITLES.LISTINGS') | translate }}
            {{ (data.isHardDelete ? 'LISTING_MANAGEMENT_DIALOG.TITLES.CONFIRMATION_MESSAGE_3' :
            'LISTING_MANAGEMENT_DIALOG.TITLES.CONFIRMATION_MESSAGE_2') | translate }}</div>
        <rpc-icon-button class="remove-listing-title-close" [iconName]="'cross'"
            [title]="'LISTING_MANAGEMENT_DIALOG.BUTTONS.CLOSE'" [mat-dialog-close]="false" (click)='close()'>
        </rpc-icon-button>
    </div>
    <div class="remove-listing-list-block">
        <ng-container *ngLet="removedListingsCount$ | async as removedListingsCount">
            <div class="rpc-warning-block" *ngIf="!data?.isHardDelete && removedListingsCount > 0">
                {{ 'LISTING_MANAGEMENT_DIALOG.TITLES.WARNINGS.MESSAGE_1' | translate }}
            </div>
        </ng-container>
        <listings-list class="remove-listing-list-wrap"
            [ngClass]="{ 'remove-listing-list-wrap--single': listings?.length === 1 }" [listings]="listings"
            [isMarkedRemoved]="!data?.isHardDelete && data?.isMarkRemovedListing"
            (unselectListingClicked)="onUnselectListingClicked($event)">
        </listings-list>
    </div>
    <div class="remove-listing-action-wrap">
        <rpc-button class="remove-listing-action" [appearanceType]="'stroke'" [mainButton]="true"
            [title]="'LISTING_MANAGEMENT_DIALOG.BUTTONS.CANCEL' | translate" (click)="close()">
        </rpc-button>

        <rpc-button class="remove-listing-action" [appearanceType]="'flat'" [color]="'accent'" [mainButton]="true"
            [title]="(data.isHardDelete ? 'LISTING_MANAGEMENT_DIALOG.BUTTONS.DELETE' : 'LISTING_MANAGEMENT_DIALOG.BUTTONS.REMOVE') | translate"
            (click)="removeListings(listings)">
        </rpc-button>
    </div>
</div>