<form [formGroup]="changePasswordForm" class="change-password-wrap">

    <div *ngIf="!isAdditionalProfile" class="change-password-item">

        <password [label]="'CHANGE_PASSWORD.FIELDS.OLD_PASSWORD'" [autocomplete]="'current-password'"
            [options]="oldPasswordControlOptions">
        </password>

    </div>

    <div class="change-password-item">

        <password [label]="'CHANGE_PASSWORD.FIELDS.NEW_PASSWORD'" [autocomplete]="'new-password'"
            [options]="newPasswordControlOptions">
        </password>

    </div>

    <div class="change-password-item">

        <password [label]="'CHANGE_PASSWORD.FIELDS.PASSWORD_CONFIRM'" [autocomplete]="'new-password'"
            [options]="confirmPasswordControlOptions">
        </password>

    </div>

    <div class="change-password-btn-wrap">

        <rpc-button class="change-password-btn" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
            [disabled]="isUpdateButtonDisabled"
            [title]="isAdditionalProfile ? 'CHANGE_PASSWORD.BUTTONS.UPDATE_PASSWORD' : 'CHANGE_PASSWORD.BUTTONS.CHANGE_PASSWORD'"
            (clicked)="save()">
        </rpc-button>

    </div>

</form>