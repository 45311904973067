<div class="saved-search-edit" [class.saved-search-edit--edit]="data.savedSearch != null">

    <div class="saved-search-edit-header">

        <div class="saved-search-edit-header-title"
            *ngLet="(data.savedSearch != null ? 'ADD_EDIT_SAVED_SEARCH_DIALOG.TITLES.EDIT_SAVED_SEARCH' : 'ADD_EDIT_SAVED_SEARCH_DIALOG.TITLES.ADD_SAVED_SEARCH') | translate as title"
            [title]="title">
            {{title}}
        </div>

        <rpc-button class="saved-search-edit-header-submit" [color]="'accent'" [disabled]="isFormInvalid"
            [title]="'ADD_EDIT_SAVED_SEARCH_DIALOG.BUTTONS.SAVE'" (clicked)="onSave()">
        </rpc-button>

        <rpc-icon-button class="saved-search-edit-header-close" [iconName]="'cross'"
            [title]="'ADD_EDIT_NOTE_DIALOG.BUTTONS.CLOSE'" (clicked)="onClose()">
        </rpc-icon-button>

    </div>

    <saved-search-form class="saved-search-edit-body" [savedSearch]="data.savedSearch"
        [savedSearches]="data.savedSearches" [folders]="folders$ | async" [folderId]="data.folderId">
    </saved-search-form>

    <div class="saved-search-edit-footer">

        <rpc-button class="saved-search-edit-footer-item saved-search-edit-footer-item--delete"
            *ngIf="data.savedSearch != null" [appearanceType]="'stroke'" [mainButton]="true" [iconName]="'delete-bin'"
            [color]="'warn'" [title]="'ADD_EDIT_SAVED_SEARCH_DIALOG.BUTTONS.DELETE'" (clicked)="onDeleteSavedSearch()">
        </rpc-button>

        <rpc-button class="saved-search-edit-footer-item saved-search-edit-footer-item--cancel"
            [appearanceType]="'stroke'" [mainButton]="true" [title]="'ADD_EDIT_SAVED_SEARCH_DIALOG.BUTTONS.CANCEL'"
            (clicked)="onClose()">
        </rpc-button>

        <rpc-button class="saved-search-edit-footer-item saved-search-edit-footer-item--submit"
            [appearanceType]="'flat'" [color]="'accent'" [mainButton]="true"
            [title]="data.savedSearch != null ? 'ADD_EDIT_SAVED_SEARCH_DIALOG.BUTTONS.SAVE' : 'ADD_EDIT_SAVED_SEARCH_DIALOG.BUTTONS.CREATE'"
            [disabled]="isFormInvalid" (clicked)="onSave()">
        </rpc-button>

    </div>

</div>