<div class="saved-search-option" [title]="savedSearchName" [class.saved-search-option--disabled]="disabled"
    (click)="onSavedSearchClick()">

    <rpc-icon class="saved-search-option-icon" [iconName]="savedSearchIcon">
    </rpc-icon>

    <div class="saved-search-option-text">
        {{savedSearchName}}
    </div>

</div>