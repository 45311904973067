import { createReducer, on } from '@ngrx/store';

import { ChildAccount } from '../../../profile-base/interfaces/child-account/child-account';
import { ChildAccountStatus } from '../../../profile-base/interfaces/child-account/child-account-status';
import * as profileBaseActions from '../actions/profile-base.actions';
import { ProfileBaseState } from '../states/profile-base.state';

export const profileBaseReducer = createReducer(
    new ProfileBaseState(),
    on(profileBaseActions.loadAdditionalProfilesSuccessful, (state, { additionalProfiles }) => {
        return {
            ...state,
            additionalProfiles: additionalProfiles.map(x => ({ ...x, fullName: `${x.firstName} ${x.lastName}` }))
        };
    }),
    on(profileBaseActions.inviteUserSuccessful, (state, { childEmail }) => {
        const newChildAccount = new ChildAccount();

        newChildAccount.email = childEmail;
        newChildAccount.lastActivityDate = new Date();
        newChildAccount.status = ChildAccountStatus.Invited;

        return {
            ...state,
            additionalProfiles: [newChildAccount, ...state.additionalProfiles]
        };
    }),
    on(profileBaseActions.reSendInviteSuccessful, (state, { oldEmail, newEmail }) => {
        return {
            ...state,
            additionalProfiles: state.additionalProfiles
                .map(child => child.email === oldEmail
                    ? {
                        ...child,
                        email: newEmail ?? oldEmail,
                        status: ChildAccountStatus.Resend,
                        lastActivityDate: new Date()
                    }
                    : child
                )
        };
    }),
    on(profileBaseActions.cancelInvitationSuccessful, (state, { childEmail }) => {
        return {
            ...state,
            additionalProfiles: state.additionalProfiles.filter(child => child.email !== childEmail)
        };
    }),
    on(profileBaseActions.deactivateChildAccountByPrimarySuccessful, (state, { childCustomerId }) => {
        return {
            ...state,
            additionalProfiles: state.additionalProfiles.filter(child => child.customerId !== childCustomerId)
        };
    }),
    on(profileBaseActions.setActiveAdditionalProfileId, (state, { additionalProfileId }) => {
        return { ...state, selectedAdditionalProfileId: additionalProfileId };
    })
);