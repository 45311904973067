import { Injectable } from '@angular/core';
import { MessageHandler } from './message-handler';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';

export interface MarkNotificationMessage {
    notificationId: number;
}

@Injectable({
    providedIn: 'root',
})
export class MarkNotificationAsReadMessageHandler
    implements MessageHandler<MarkNotificationMessage> {
    constructor(private readonly notificationsStoreService: NotificationsStoreService) { }

    public handle(message: MarkNotificationMessage): void {
        this.notificationsStoreService.lastNotificationId = message.notificationId;
    }
}
