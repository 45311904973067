import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'profile-toolbar',
    templateUrl: './profile-toolbar.component.html',
    styleUrls: ['./profile-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileToolbarComponent {
    @Input() public isAdditionalProfile: boolean;

    @Output() public edit = new EventEmitter<void>();
    @Output() public navigateBack = new EventEmitter<void>();

    public onNavigateBack(): void {
        this.navigateBack.emit();
    }

    public onEdit(): void {
        this.edit.emit();
    }

}
