import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store/src/models';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';

import { EncryptedCredential } from '@auth/models/encrypted-credential';
import { Invitation } from '@auth/models/invitation';
import { LoginData } from '@auth/models/login-data';
import { User } from '@auth/models/user';
import { RegisterApiService } from '@auth/services/register-api.service';
import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { TokenService } from '@auth/services/token.service';
import * as registrationActions from '@auth/store/actions/invitation.actions';
import * as loginActions from '@auth/store/actions/login.actions';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ToastService } from '@core-services/toast.service';
import { ProgressBarService } from '@core-utils/progress-bar/progress-bar.service';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { ActivityType } from '@customer-activity/models/activity-type';
import { ApiError } from '@error/models/api-error';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import * as googleAnalyticsActions from '@core-layout/app/store/actions/google-analytics.actions';
import * as appActions from '@core-layout/app/store/actions/app.actions';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';
import { SmartBannerService } from '@core-layout/smart-banner/smart-banner.service';

@Injectable()
export class AuthEffects {

    constructor(
        private readonly routeService: RouteService,
        private readonly actions$: Actions,
        private readonly http: ApiHttpClient,
        private readonly registerApiService: RegisterApiService,
        private readonly userService: UserStoreService,
        private readonly toastService: ToastService,
        private readonly progressBarService: ProgressBarService,
        private readonly tokenInitializerService: TokenInitializerService,
        private readonly customerActivityService: CustomerActivityService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly hybridService: HybridService,
        private readonly smartBannerService: SmartBannerService,
    ) { }

    public readonly tryLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginActions.tryLogin),
            switchMap((action) =>
                this.http.post('auth/try-create-token', action.credential)
                    .pipe(
                        switchMap((result: ApiDataResult<LoginData | EncryptedCredential>) => this.processLoginResponse(result.result)),
                        catchError((errorResponse: HttpErrorResponse) => of(loginActions.loginFailed(errorResponse.error as ApiError)))
                    )),
            share()
        )
    );

    public readonly login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginActions.login),
            switchMap((action) => {
                return this.http.post('auth/token', action.credential)
                    .pipe(
                        switchMap((result: ApiDataResult<LoginData>) => {
                            return this.processRegistrationLoginAccessResponse(result.result);
                        }),
                        catchError((errorResponse: HttpErrorResponse) => of(loginActions.loginFailed(errorResponse.error as ApiError)))
                    );
            }),
            share()
        )
    );

    public readonly loginSuccessfull$ = createEffect(() => this.actions$.pipe(
        ofType(loginActions.loginSuccessful),
        map(() => {
            this.smartBannerService.setSmartBannerHidden(false);

            this.routeService.navigateToReturnUrl().finally(() => this.progressBarService.hide());

            return googleAnalyticsActions.setCustomerAttributes();
        })
    ));

    public readonly loginFailed$ = createEffect(
        () => this.actions$.pipe(
            ofType(loginActions.loginFailed),
            tap(error => {
                this.showErrorMessage(error);
                this.routeService.navigate(RpcRoute.Login, { queryParamsHandling: 'preserve' }).finally(() => this.progressBarService.hide());
            }),
            catchError((error: ApiError) => of(this.showErrorMessage(error)))),
        { dispatch: false }
    );

    public readonly logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginActions.logout),
            switchMap((action) => {
                this.hybridService.sendMessage({ kind: 'logout' });

                if (action.redirect) {
                    return from(this.routeService.navigate(RpcRoute.Login))
                        .pipe((
                            switchMap(() => this.http.post('auth/logout', {})
                                .pipe(
                                    map(() => loginActions.logoutSuccessful(action.forced, action.duringLoginSync)),
                                    catchError((errorResponse: HttpErrorResponse) => of(loginActions.logoutFailed({ forced: action.forced })))
                                ))
                        ));
                }

                return EMPTY.pipe(
                    switchMap(() => this.http.post('auth/logout', {})
                        .pipe(
                            map(() => loginActions.logoutSuccessful(action.forced, action.duringLoginSync)),
                            catchError((errorResponse: HttpErrorResponse) => of(loginActions.logoutFailed({ forced: action.forced })))
                        )
                    )
                );
            }),
            share()
        )
    );

    public readonly logoutSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(loginActions.logoutSuccessful),
        switchMap(() => [
            googleAnalyticsActions.setCustomerAttributes(),
            appActions.loadDomainCompanyConfiguration()
        ])
    ));

    public readonly refresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginActions.refresh),
            switchMap(() =>
                this.http.post('auth/refreshToken', {})
                    .pipe(
                        switchMap(this.processRefreshedAccessToken),
                        catchError((errorResponse: HttpErrorResponse) => of(loginActions.refreshFailed(errorResponse.error as ApiError)))
                    ))
        )
    );

    public readonly collaborationSpaceLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginActions.collaborationSpaceLogin),
            switchMap(({ response }) => {
                const user = TokenService.processUserData(response);

                return [
                    loginActions.collaborationSpaceLoginSuccessful({ user, duringLoginSync: false }),
                    settingsActions.saveLoadedSettings(user.loginData.customerSettings, true),
                ];
            }),
        ));

    public readonly invitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(registrationActions.getInvitation),
            switchMap((action) =>
                this.http.post('auth/collectRegistrationData', { invitation: action.inviteCode })
                    .pipe(
                        map((response: ApiDataResult<Invitation>) => {
                            return response.error != null
                                ? registrationActions.invitationGotFail(response.error)
                                : registrationActions.invitationGotSuccessful(response.result);
                        }),
                        catchError((errorResponse: HttpErrorResponse) => of(registrationActions.invitationGotFail(errorResponse.error as ApiError)))
                    )),
            share()
        )
    );

    public readonly invitationGotFail$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(registrationActions.invitationGotFail),
                tap((error) => {
                    this.toastService.showServerError(error.errorKey);
                    this.routeService.navigate(RpcRoute.Login).finally(() => this.progressBarService.hide());
                })
            ),
        { dispatch: false }
    );

    public readonly registration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(registrationActions.registration),
            concatLatestFrom(() => this.userService.getUserInvitation()),
            switchMap(([{ registrationData }, invitation]) => {
                // restore email in case user change it
                registrationData.accountName = invitation.customer.email;

                this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.SignUp);

                return this.registerApiService.register(registrationData);
            }),
        )
    );

    public readonly registrationSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(registrationActions.registrationSuccessful),
        map(() => {
            this.tokenInitializerService.initialize().then(() => {
                this.routeService.navigateToReturnUrl().finally(() => this.progressBarService.hide());
            }).catch(() => { });

            return googleAnalyticsActions.setCustomerAttributes();
        }),
    ));

    public readonly addLoginActivityAfterRegistration$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(registrationActions.addLoginActivityAfterRegistration),
                switchMap(() => this.customerActivityService.addActivity(ActivityType.Login))
            ),
        { dispatch: false }
    );

    public readonly registrationFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(registrationActions.registrationFailed),
                tap(error => {
                    this.toastService.showServerError(error.errorKey);
                    this.progressBarService.hide();
                })
            ),
        { dispatch: false }
    );

    private readonly processRefreshedAccessToken = (loginData: ApiDataResult<LoginData>): Action[] => {
        const user: User = TokenService.processUserData(loginData.result);

        return [
            loginActions.refreshSuccessful(user),
            settingsActions.saveLoadedSettings(user.loginData.customerSettings, false),
        ];
    };

    private readonly processLoginResponse = (loginResponse: LoginData | EncryptedCredential): Action[] => {
        const encryptedCredentials = loginResponse as EncryptedCredential;

        if (encryptedCredentials?.encryptedAccountName != null && encryptedCredentials.encryptedPassword != null) {
            return [loginActions.loginWithinAgentsSelection(encryptedCredentials)];
        }

        return this.processLoginAccessResponse(loginResponse as LoginData);
    };

    private readonly processLoginAccessResponse = (loginResponse: LoginData): Action[] => {
        if (loginResponse !== null) {
            const user: User = TokenService.processUserData(loginResponse);

            return [
                loginActions.loginSuccessful(user),
                settingsActions.saveLoadedSettings(user.loginData.customerSettings, true)
            ];
        }

        return [loginActions.loginFailed(new ApiError('Auth_User_NotFound'))];
    };

    private readonly processRegistrationLoginAccessResponse = (loginResponse: LoginData): Action[] => {
        if (loginResponse !== null) {
            this.smartBannerService.setSmartBannerHidden(false);

            const user: User = TokenService.processUserData(loginResponse);

            return [
                settingsActions.saveLoadedSettings(user.loginData.customerSettings, true),
                registrationActions.registrationSuccessful(user),
                registrationActions.addLoginActivityAfterRegistration()
            ];
        }

        return [loginActions.loginFailed(new ApiError('Auth_User_NotFound'))];
    };

    private showErrorMessage(error: ApiError): void {
        this.toastService.showServerError(error);
        this.progressBarService.hide();
    }
}