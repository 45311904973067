import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AddEditSavedSearchDialogData } from '../../models/add-edit-saved-search-dialog-data';
import { SavedSearchFormComponent } from '../saved-search-form/saved-search-form.component';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';
import { SavedSearchData } from '../../models/saved-search-data';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';

@Component({
    selector: 'add-edit-saved-search-dialog',
    templateUrl: './add-edit-saved-search-dialog.component.html',
    styleUrls: ['./add-edit-saved-search-dialog.component.scss']
})
export class AddEditSavedSearchDialogComponent {
    @ViewChild(SavedSearchFormComponent) private readonly savedSearchFormComponent: SavedSearchFormComponent;

    public readonly folders$ = this.foldersStoreReadService.customFoldersSortedByDate$;

    constructor(
        private readonly dialogRef: MatDialogRef<AddEditSavedSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: AddEditSavedSearchDialogData,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
    ) { }

    public get isFormInvalid(): boolean {
        return this.savedSearchFormComponent?.form?.invalid;
    }

    public onDeleteSavedSearch(): void {
        this.savedSearchStoreService.showDeleteSavedSearchDialog(this.data.savedSearch, () => this.onClose());
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public onSave(): void {
        const formValue = this.savedSearchFormComponent.getFormValue();
        const value: SavedSearchData = { ...formValue, isCreation: false };

        this.dialogRef.close(value);
    }
}