import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import isEqual from 'lodash-es/isEqual';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { EmailAlertTimeOption } from '@settings/enums/email-alert-time-option.enum';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { EmailAlertsSettings } from '@settings/models/settings/email-alerts-settings';
import { RpcSelectControltOptions } from '@core-controls/components/rpc-select/models/rpc-select-controlt-options';
import { RpcSelectOption } from '@core-controls/components/rpc-select/models/rpc-select-option';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { FieldGroup } from './models/field-group';

@Component({
    selector: 'email-alert-settings',
    templateUrl: './email-alert-settings.component.html',
    styleUrls: ['./email-alert-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailAlertSettingsComponent implements OnChanges {

    @Input() settings: CustomerSettings;
    @Output() settingsChanged: EventEmitter<CustomerSettings> = new EventEmitter<CustomerSettings>();

    public form: FormGroup;
    public fieldGroups: FieldGroup[];

    public readonly controlIconMap = new Map<keyof EmailAlertsSettings, { title: string, icon?: string, iconClass?: string }>([
        ['newListing', { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.ADDED', icon: ListingActivityConstants.PickListed.icon, iconClass: 'profile-settings-block-list-item-text-icon--picked' }],
        ['like', { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.LIKED', icon: ListingActivityConstants.Liked.icon, iconClass: 'profile-settings-block-list-item-text-icon--liked' }],
        ['dislike', { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.DISLIKED', icon: ListingActivityConstants.Disliked.icon, iconClass: 'profile-settings-block-list-item-text-icon--disliked' }],
        ['remove', { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.REMOVED', icon: 'delete-bin', iconClass: 'profile-settings-block-list-item-text-icon--removed' }],
        ['comment', { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.MESSAGES', icon: 'comments' }],
        ['appointmentConfirm', { title: 'EMAIL_ALERT_SETTINGS.APPOINTMENTS.CONFIRMATIONS' }],
        ['appointmentRequest', { title: 'EMAIL_ALERT_SETTINGS.APPOINTMENTS.REQUESTS' }],
        ['appointmentDecline', { title: 'EMAIL_ALERT_SETTINGS.APPOINTMENTS.DECLINES' }],
    ]);

    constructor(
        private readonly formBuilder: FormBuilder
    ) { }

    public ngOnChanges(changes: SimpleChanges<EmailAlertSettingsComponent>): void {
        if (changes.settings != null &&
            changes.settings.currentValue != null &&
            (changes.settings.previousValue == null || !isEqual(changes.settings.currentValue, changes.settings.previousValue))
        ) {
            const currentEmailAlerts = changes.settings.currentValue.emailAlerts;

            if (this.form == null) {
                this.form = this.formBuilder.group({});
                this.createFormControls(currentEmailAlerts);
            }

            this.form.patchValue(currentEmailAlerts);
        }
    }

    public onSettingChanged(key: keyof EmailAlertsSettings, option: RpcSelectOption) {
        this.settingsChanged.next(new CustomerSettings({ ...this.form.value, [key]: option.value }, this.settings.layoutSettings, this.settings.permissionSettings));
    }

    private createFormControls(currentEmailAlerts: EmailAlertsSettings): void {
        const { newListing, like, dislike, remove, comment, ...appointmentEmailOptions } = currentEmailAlerts;

        this.fieldGroups = [
            { title: 'EMAIL_ALERT_SETTINGS.LISTINGS.TITLE', fields: this.getFields({ newListing, like, dislike, remove, comment }) },
            { title: 'EMAIL_ALERT_SETTINGS.APPOINTMENTS.TITLE', fields: this.getFields(appointmentEmailOptions) }
        ];
    }

    private readonly getFields = (emailAlerts: Partial<EmailAlertsSettings>) => {
        return Object.entries(emailAlerts)
            .map(([key, value]: [keyof EmailAlertsSettings, number]) => ({
                key,
                controlOptions: new RpcSelectControltOptions(this.form, key, this.getSelectOptions(), value),
                ...this.controlIconMap.get(key)
            }));
    };

    private getSelectOptions(): RpcSelectOption[] {
        return [
            {
                title: 'EMAIL_ALERT_SETTINGS.OPTIONS.DAILY',
                value: EmailAlertTimeOption.Daily
            },
            {
                title: 'EMAIL_ALERT_SETTINGS.OPTIONS.REAL_TIME',
                value: EmailAlertTimeOption.RealTime
            },
            {
                title: 'EMAIL_ALERT_SETTINGS.OPTIONS.DISABLED',
                value: EmailAlertTimeOption.Disabled
            },
        ];
    }
}