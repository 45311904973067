export const locale = {
    lang: 'en',
    data: {
        SAVED_SEARCH_FORM: {
            TITLES: {
                SAVED_SEARCH_NAME: 'Saved Search Name',
                FOLDER: 'Folder',
                NEW_FOLDER: 'New Folder',
                NOTE: 'Include previously matched listings to this folder',
                PREVIOUSLY_MATCHED: 'Previously Matched',
                INCLUDE_IN_NEW_MATCHES: 'Include in New Matches',
                SELECT_FOLDER: 'Select Folder',
                CLOSE: 'Close',
            },
            OPTIONS: {
                NOT_SELECTED: 'Not Selected',
            },
            VALIDATION_ERRORS: {
                REQUIRED: 'Name is required',
                WHITESPACES: 'Name can not include whitespaces only',
                UNIQUE: 'Name should be unique within sales and rentals saved searches'
            },
            BUTTONS: {
                CREATE: 'Create',
                CANCEL: 'Cancel',
                EDIT: 'Edit',
            }
        }
    }
};