<form class="edit-customer-info" [formGroup]="accountDataForm">

    <div class="edit-customer-info-header">

        <rpc-icon-button class="edit-customer-info-header-close" [iconName]="'cross'"
            [title]="'EDIT_PROFILE_INFO.TITLES.CLOSE'" (clicked)="onCancel()">
        </rpc-icon-button>

        <div class="edit-customer-info-header-title">
            {{ 'EDIT_PROFILE_INFO.TITLES.EDIT_PROFILE' | translate }}
        </div>

        <rpc-button class="edit-customer-info-header-action" [title]="'EDIT_PROFILE_INFO.TITLES.SAVE'"
            [color]="'accent'" [disabled]="accountDataForm?.invalid" (clicked)="onSave()">
        </rpc-button>

    </div>

    <div class="edit-customer-info-body">

        <rpc-input class="edit-customer-info-input" [type]="'text'" [appearance]="'fill'"
            [label]="'EDIT_PROFILE_INFO.TITLES.FIRST_NAME'" [options]="firstNameControlOptions">
        </rpc-input>

        <rpc-input class="edit-customer-info-input" [type]="'text'" [appearance]="'fill'"
            [label]="'EDIT_PROFILE_INFO.TITLES.LAST_NAME'" [options]="lastNameControlOptions">
        </rpc-input>

        <rpc-input class="edit-customer-info-input" *ngIf="isAdditionalProfile; else primaryProfile" [type]="'text'"
            [appearance]="'fill'" [label]="'EDIT_PROFILE_INFO.TITLES.EMAIL'"
            [options]="mainAdditionalProfileEmailOptions" [disabled]="true">
        </rpc-input>

        <ng-template #primaryProfile>

            <rpc-select class="edit-customer-info-select" [color]="'accent'"
                [label]="'EDIT_PROFILE_INFO.TITLES.MAIN_EMAIL'" [controlOptions]="mainEmailSelectOptions"
                [appearance]="'fill'">
            </rpc-select>

        </ng-template>

    </div>

    <div class="edit-customer-info-footer">

        <rpc-button [mainButton]="true" [appearanceType]="'stroke'" [title]="'EDIT_PROFILE_INFO.TITLES.CANCEL'"
            (clicked)="onCancel()">
        </rpc-button>

        <rpc-button [color]="'accent'" [mainButton]="true" [appearanceType]="'flat'"
            [title]="'EDIT_PROFILE_INFO.TITLES.SAVE'" [disabled]="accountDataForm?.invalid" (clicked)="onSave()">
        </rpc-button>

    </div>

</form>