export const locale = {
    lang: 'en',
    data: {
        GENERAL_INFO_PAGE: {
            TITLES: {
                CANCEL: 'Cancel',
                BACK: 'Back',
                GENERAL_INFO: 'General Info',
                SAVE: 'Save',
            }
        }
    }
};