import { Inject, Injectable, InjectionToken } from '@angular/core';
import { cloneDeep, isEqual, merge } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

import { AppConfiguration } from '@app-config/models/app-configuration';
import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { WelcomeVideoLinkModel } from '@app-config/models/welcome-video-link-model';
export const APP_CONFIG = new InjectionToken('applicationConfiguration');

@Injectable({ providedIn: 'root' })
/**
 * Service provides access to application layout and company styles configuration.
 */
export class AppConfigurationService {

    private readonly currentConfiguration$: BehaviorSubject<AppConfiguration>;
    private readonly companyLogo$ = new BehaviorSubject<string>(null);
    private readonly internalCompanyConfigurationLoaded$ = new BehaviorSubject<boolean>(false);
    private readonly configurationDefault: AppConfiguration;

    constructor(@Inject(APP_CONFIG) storedDefaultConfiguration: AppConfiguration) {
        this.configurationDefault = storedDefaultConfiguration;
        this.currentConfiguration$ = new BehaviorSubject(cloneDeep(this.configurationDefault));
    }

    get configuration$(): Observable<AppConfiguration> {
        return this.getConfiguration();
    }

    public get welcomeVideoLinkFeature$(): Observable<WelcomeVideoLinkModel> {
        return this.getConfiguration().pipe(map((x): WelcomeVideoLinkModel => ({
            enabled: x.company.features.welcomeVideoLink.enabled,
            link: x.company.features.welcomeVideoLink.link,
            productNameAlias: x.company.productNameAlias
        })));
    }

    public get isWelcomeVideoLinkExisted$(): Observable<boolean> {
        return this.getConfiguration().pipe(map(x =>
            x.company.features?.welcomeVideoLink != null &&
            x.company.features.welcomeVideoLink.enabled &&
            x.company.features.welcomeVideoLink.link?.length > 0 &&
            x.company.productNameAlias?.length > 0));
    }

    /**
    * Merges current configuration with new one.
    * Usage of any type allows to pass as an argument only a part of configuration.
    * @param configuration Configuration to be merged into current.
    * @type AppConfiguration
    */
    set configuration(configuration: unknown) {
        this.setConfiguration(configuration);
    }

    /**
     * Merges current configuration with new one.
     * Usage of any type allows to pass as an argument only a part of configuration.
     * @param configuration Configuration to be merged into current.
     * @type AppConfiguration
     */
    public setConfiguration(configuration: unknown): void {
        const config = merge({}, this.currentConfiguration$.getValue(), configuration);

        this.currentConfiguration$.next(config);
    }

    public getConfiguration(): Observable<AppConfiguration> {
        return this.currentConfiguration$.asObservable();
    }

    public getCompanyConfiguration(): Observable<CompanyConfiguration> {
        return this.getConfiguration().pipe(map(({ company }) => company), distinctUntilChanged(isEqual));
    }

    public resetLayout(applyDefault: boolean): void {
        const config = cloneDeep(this.currentConfiguration$.getValue());

        if (applyDefault && isEqual(this.currentConfiguration$.getValue().layout, this.configurationDefault.layout)) {

            config.layout = cloneDeep(this.configurationDefault.layout);

            this.currentConfiguration$.next(config);
        }

        this.currentConfiguration$.next(config);
    }

    public setCompany(companyConfiguration: CompanyConfiguration): void {
        this.configuration = {
            company: {
                ...companyConfiguration,
                logoPath: 'assets/images/company/' + companyConfiguration.id + '/logo.svg',
                darkModeLogoPath: 'assets/images/company/' + companyConfiguration.id + '/logo-dark.svg',
                whiteLogoPath: 'assets/images/company/' + companyConfiguration.id + '/logo-white.svg',
            }
        };
    }

    public setCompanyLogo(companyConfiguration: CompanyConfiguration, isDarkMode: boolean, companyLogo?: string): void {
        const isBrandCompany = companyConfiguration.internalId != null;
        const logoPath = isBrandCompany
            ? `assets/images/company/${companyConfiguration.id}/${isDarkMode ? 'logo-full-dark.svg' : 'logo-full.svg'}`
            : companyLogo ?? this.companyLogo$.getValue();

        this.companyLogo$.next(logoPath);
    }

    public getCompanyLogo(): Observable<string> {
        return this.companyLogo$.asObservable();
    }

    public setInternalCompanyConfigurationLoaded(isLoaded: boolean): void {
        this.internalCompanyConfigurationLoaded$.next(isLoaded);
    }

    public getInternalCompanyConfigurationLoaded(): Observable<boolean> {
        return this.internalCompanyConfigurationLoaded$.asObservable();
    }
}