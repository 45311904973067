import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map } from 'rxjs';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';

@Component({
    selector: 'logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
    @Input() public isWhiteLogo?: boolean = false;

    public readonly companyConfiguration$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company));
    public readonly isDarkMode$ = this.settingsStoreService.isDarkMode$;
    public readonly internalCompanyConfigurationLoaded$ = this.configurationService.getInternalCompanyConfigurationLoaded();

    constructor(
        private readonly routeService: RouteService,
        private readonly configurationService: AppConfigurationService,
        private readonly settingsStoreService: SettingsStoreService,
    ) { }

    public onLogoClicked(): void {
        this.routeService.navigate(RpcRoute.FindHome).catch(() => { });
    }
}
