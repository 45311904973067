import { Component, OnInit } from '@angular/core';
import { switchMap, iif } from 'rxjs';

import { ProfileBaseStoreService } from '../../store/services/profile-base-store.service';
import { AdditionalProfileStoreService } from '@additional-profile/store/services/additional-profile-store.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';

@Component({
    selector: 'profile-settings-page',
    templateUrl: './profile-settings-page.component.html',
    styleUrls: ['./profile-settings-page.component.scss']
})
export class ProfileSettingsPageComponent implements OnInit {
    public readonly isSelfProfile$ = this.profileBaseStoreService.isSelfProfile$;
    public readonly settings$ = this.profileBaseStoreService.isSelfProfile$.pipe(
        switchMap(isSelfProfile => iif(() => isSelfProfile, this.settingsStoreService.getSettings(), this.additionalProfileStoreService.settings$))
    );

    constructor(
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly additionalProfileStoreService: AdditionalProfileStoreService
    ) { }

    public ngOnInit() {
        this.profileBaseStoreService.loadSettings();
    }

    public onNavigateBack(): void {
        this.profileBaseStoreService.navigateToProfile();
    }
}
