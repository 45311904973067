<div class="ca" *ngIf="agentCandidates?.length > 0">

    <div class="ca-header">

        <div class="ca-header-title">
            {{ 'CHANGE_AGENT_DIALOG.TITLES.CHANGE_AGENT' | translate }}
        </div>

        <rpc-icon-button class="ca-header-close" [iconName]="'cross'" [title]="'CHANGE_AGENT_DIALOG.BUTTONS.CLOSE'"
            mat-dialog-close (clicked)="onClose()">
        </rpc-icon-button>

        <rpc-button class="ca-header-action" [title]="'CHANGE_AGENT_DIALOG.BUTTONS.CHANGE'" [color]="'accent'"
            [disabled]="!agentList.canSubmit" (clicked)="onSpaceSelected(agentList.selectedCustomerId)">
        </rpc-button>

    </div>

    <div class="ca-body">

        <div class="ca-note">
            {{ 'CHANGE_AGENT_DIALOG.TITLES.INSTRUCTIONS' | translate }}
        </div>

        <agent-list #agentList class="ca-list" [agentCandidates]="agentCandidates"
            [currentCustomerId]="currentCustomerId$ | async" (agentSelectionConfirmed)="onSpaceSelected($event)">
        </agent-list>

    </div>

    <div class="ca-footer">

        <rpc-button [appearanceType]="'stroke'" [mainButton]="true" [title]="'CHANGE_AGENT_DIALOG.BUTTONS.CANCEL'"
            (clicked)="onClose()">
        </rpc-button>

        <rpc-button [appearanceType]="'flat'" [mainButton]="true" [color]="'accent'"
            [title]="'CHANGE_AGENT_DIALOG.BUTTONS.CHANGE_AGENT'" [disabled]="!agentList.canSubmit"
            (clicked)="onSpaceSelected(agentList.selectedCustomerId)">
        </rpc-button>

    </div>

</div>