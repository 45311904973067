export const locale = {
    lang: 'en',
    data: {
        MULTI_SELECTION_MENU: {
            TITLES: {
                LISTINGS_SELECTED: '{{count}} selected',
                SELECT_ALL: 'Select All',
            }
        }
    }
};