export const locale = {
    lang: 'en',
    data: {
        SAVE_YOUR_SEARCH: {
            TITLES: {
                CLOSE: 'Close',
                SAVE_YOUR_SEARCH: 'Save Your Search',
                NEW_SAVED_SEARCH: 'Create New',
                SAVED_SEARCH_NOTE: 'Please, select one of the existing saved searches or create a new one.'
            }
        }
    }
};
