<div class="confirm-wrap" [class.confirm-wrap--title]="options.showTitle">

    <div class="confirm-title-wrap" *ngIf="options.showTitle">

        <div class="confirm-title">
            {{ (options.title ?? 'SIMPLE_DIALOG.TITLES.CONFIRM') | translate}}
        </div>

    </div>

    <div class="confirm-message">
        {{options.message | translate}}
    </div>

    <div class="confirm-actions" [class.confirm-actions--cancel]="options.showCancel">

        <rpc-button *ngIf="options.showCancel" class="confirm-btn confirm-btn--cancel" [mainButton]="true"
            [appearanceType]="'stroke'" [title]="'SIMPLE_DIALOG.BUTTONS.CANCEL'" (clicked)="dialogRef.close(false)">
        </rpc-button>

        <rpc-button class="confirm-btn confirm-btn--submit"
            [title]="options.confirmButtonText ?? 'SIMPLE_DIALOG.BUTTONS.OK'" [mainButton]="true" [color]="'accent'"
            [appearanceType]="'flat'" (clicked)="dialogRef.close(true)">
        </rpc-button>

    </div>

</div>