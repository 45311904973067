import { AfterViewInit, Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { fromEvent, take } from 'rxjs';

import { MatchMediaService } from '@media/services/match-media.service';
import { SavedSearchInfo } from '@saved-search/models/saved-search-info';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';

@Component({
    selector: 'save-your-search',
    templateUrl: './save-your-search.component.html',
    styleUrls: ['./save-your-search.component.scss']
})
export class SaveYourSearchComponent implements AfterViewInit {
    @Output() public closed = new EventEmitter<void>();

    public readonly savedSearchesToModify$ = this.savedSearchStoreService.savedSearchesToModify$;

    constructor(
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly matchMediaService: MatchMediaService,
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef<HTMLElement>,
    ) { }

    public ngAfterViewInit(): void {
        const panelElement = this.elementRef.nativeElement.parentElement;

        this.renderer.setStyle(panelElement, 'height', panelElement.clientHeight + 'px');

        if (this.matchMediaService.activeMediaQuery === 'xs') {
            fromEvent(panelElement, 'scroll')
                .pipe(take(1))
                .subscribe(() => this.onExpandPanel());
        }
    }

    public onSavedSearchCreate(): void {
        this.savedSearchStoreService.showAddEditSavedSearchDialog();

        this.onClose();
    }

    public onSavedSearchSelected(savedSearch: SavedSearchInfo): void {
        const { id, name, searchNewMatches, folderId, } = savedSearch;

        this.savedSearchStoreService.updateSavedSearch({ id, name, searchNewMatches, folderId, updateCriteria: true });

        this.onClose();
    }

    public onClose(): void {
        this.closed.emit();
    }

    public onExpandPanel(): void {
        if (this.matchMediaService.activeMediaQuery === 'xs') {
            this.renderer.addClass(this.elementRef.nativeElement.parentElement, 'rpc-bottomsheet--top');

            this.renderer.removeStyle(this.elementRef.nativeElement.parentElement, 'height');
        }
    }
}
