<ng-container *ngLet="companyConfiguration$ | async as companyConfiguration">

    <div class="login-page-wrap">

        <not-authorized-toolbar class="login-page-toolbar" [hasLink]="false">
        </not-authorized-toolbar>

        <div class="login-page-body">

            <div class="auth-wrap login" @fadeIn>
                <svg class="auth-decoration" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 472.27 522">
                    <linearGradient id="gradientTop" gradientUnits="userSpaceOnUse" x1="-224.8883" y1="724.1395"
                        x2="-224.8883" y2="723.2499"
                        gradientTransform="matrix(557.6543 0 0 -586.9705 125648.6016 425048.3125)">
                        <stop class="bg-top-start-color" offset="0" />
                        <stop class="bg-top-stop-color" offset="1" />
                    </linearGradient>
                    <linearGradient id="gradientBottom" gradientUnits="userSpaceOnUse" x1="-794.3042" y1="723.9017"
                        x2="-794.3042" y2="722.9017"
                        gradientTransform="matrix(228.207 0 0 -250.1331 181056 181071.5625)">
                        <stop class="bg-bottom-start-color" offset="0" />
                        <stop class="bg-bottom-stop-color" offset="1" />
                    </linearGradient>
                    <path class="bg-top"
                        d="M4,0.08C5.84,2.92,32.31,44.49,31.5,84.6c-0.83,41.55-10.21,94.69,33.54,127.98s58.54,66.96,59.44,130.34s24.47,77.97,67.61,88.6s124.46-7.67,188.04,90.48h93.11c0.02-0.02,0,0.03,0,0l0.06-522.18" />
                    <g transform="translate(569)">
                        <path class="bg-bottom"
                            d="M-323.9-0.18c0,0,55.5,8.04,69.23,57.62c17.38,62.76,120,20.77,117.35,104.66c-2.28,72.13,41.62,87.86,41.62,87.86V-0.18H-323.9z" />
                    </g>
                </svg>
                <div class="auth-inner" style="--bg-image: url('/assets/images/decoration/login.png');">
                    <div class="auth-form-wrap">
                        <div class="auth-form">
                            <div class="auth-title">
                                {{ 'LOGIN.TITLES.LOGIN_FORM' | translate }}
                            </div>
                            <div class="auth-description">
                                <div class="auth-description-item">
                                    {{'LOGIN.TITLES.WELCOME' | translate}} {{companyConfiguration.productNameAlias}}.
                                </div>
                                <div class="auth-description-item">
                                    {{ 'LOGIN.TITLES.EXPLANATION' | translate }}
                                </div>
                            </div>
                            <form name="loginForm" [formGroup]="loginForm" novalidate>
                                <rpc-input class="auth-form-input" type="email" appearance="fill" autocomplete="email"
                                    label="LOGIN.TITLES.USERNAME" [options]="userNameControlOptions">
                                </rpc-input>

                                <password class="auth-form-input" [autocomplete]="'current-password'"
                                    [label]="'LOGIN.TITLES.PASSWORD'" [options]="passwordControlOptions">
                                </password>

                                <div class="remember-forgot-password">
                                    <a class="forgot-password" [routerLink]="'/' + RpcRoute.ForgotPassword">
                                        {{'LOGIN.TITLES.FORGOT_PASSWORD' | translate}}
                                    </a>
                                </div>
                                <rpc-button class="auth-submit-button" [mainButton]="true" [color]="'accent'"
                                    [disabled]="loginForm.invalid" [appearanceType]="'flat'" title="LOGIN.TITLES.LOGIN"
                                    (clicked)="login()">
                                </rpc-button>
                            </form>
                        </div>
                        <div class="auth-terms-wrap">
                            {{'LOGIN.TITLES.AGREE_TERMS' | translate}}
                            <br />
                            <a [href]="termsAndConditionsLink" target="_blank" rel="nofollow">
                                {{'LOGIN.TITLES.TERMS_CONDITIONS' | translate}}
                            </a>
                            {{'LOGIN.TITLES.AND' | translate}}
                            <a [href]="privacyPolicyLink" target="_blank" rel="nofollow">
                                {{'LOGIN.TITLES.PRIVACY_POLICY' | translate}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <additional-footer class="global-additional-footer">
    </additional-footer>

</ng-container>