import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgLetModule } from 'ng-let';
import { MatDialogModule } from '@angular/material/dialog';

import { locale as english } from './i18n/en';
import { SavedSearchFormComponent } from './components/saved-search-form/saved-search-form.component';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { savedSearchReducer } from './store/reducers/saved-search.reducer';
import { SavedSearchEffects } from './store/effects/saved-search.effects';
import { AddEditSavedSearchDialogComponent } from './components/add-edit-saved-search-dialog/add-edit-saved-search-dialog.component';
import { SavedSearchInfoComponent } from './components/saved-search-info/saved-search-info.component';
import { SavedSearchOptionComponent } from './components/saved-search-option/saved-search-option.component';
import { FoldersModule } from '@folders/folders.module';
import { SavedSearchListComponent } from './components/saved-search-list/saved-search-list.component';
import { SaveYourSearchComponent } from './components/save-your-search/save-your-search.component';
import { SavedSearchDialogsEffects } from './store/effects/saved-search-dialogs.effects';

const componentsForExport = [
    SavedSearchInfoComponent,
    SavedSearchOptionComponent,
    SavedSearchListComponent,
    SaveYourSearchComponent
];

const components = [
    ...componentsForExport,
    SavedSearchFormComponent,
    AddEditSavedSearchDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatRadioModule,
        FormsModule,
        NgLetModule,
        MatDialogModule,

        RpcControlsModule,
        RpcIconModule,
        FoldersModule,

        StoreModule.forFeature('saved-search', savedSearchReducer),
        EffectsModule.forFeature([SavedSearchEffects, SavedSearchDialogsEffects])
    ],
    declarations: components,
    exports: componentsForExport
})
export class SavedSearchModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}