import { Injectable } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { Operation, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DynamicLinkService {
    private uri: string;

    constructor(private readonly httpLink: HttpLink) {
    }

    setUri(newUri: string) {
        this.uri = newUri;
    }

    createDynamicLink() {
        return from([
            setContext((operation: Operation, prevContext: any) => ({
                ...prevContext,
                uri: this.uri,
            })),
            this.httpLink.create({ uri: this.uri })
        ]);
    }
}
