import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as switchCollaborationSpaceActions from '../actions/switch-collaboration-space.actions';
import * as switchCollaborationSpaceSelectors from '../selectors/switch-collaboration-space.selectors';
import { AuthState } from '../states/auth.state';

@Injectable({ providedIn: 'root' })
export class CollaborationSpaceStoreService {
    constructor(
        private readonly store: Store<AuthState>
    ) { }

    public readonly collaborationSpaces$ = this.store.select(switchCollaborationSpaceSelectors.selectCollaborationSpaces);
    public readonly hasMultipleCollaborationSpaces$ = this.store.select(switchCollaborationSpaceSelectors.selectHasMultipleCollaborationSpaces);

    public loadCollaborationSpaces(): void {
        this.store.dispatch(switchCollaborationSpaceActions.loadCollaborationSpaces());
    }

    public loadHasMultipleCollaborationSpaces(): void {
        this.store.dispatch(switchCollaborationSpaceActions.loadHasMultipleCollaborationSpaces());
    }

    public loadHasMultipleCollaborationSpacesSuccessful(hasMultipleCollaborationSpaces: boolean): void {
        this.store.dispatch(switchCollaborationSpaceActions.loadHasMultipleCollaborationSpacesSuccessful({hasMultipleCollaborationSpaces}));
    }

    public switchCollaborationSpace(customerId: number): void {
        this.store.dispatch(switchCollaborationSpaceActions.switchCollaborationSpace({ selectedCustomerId: customerId }));
    }
}