<div class="rpc-logo" *ngIf="(companyConfiguration$ | async) as companyConfiguration" (click)="onLogoClicked()">

    <img class="rpc-logo-img" [src]="isWhiteLogo 
        ? companyConfiguration.whiteLogoPath
        : (isDarkMode$ | async) ? companyConfiguration.darkModeLogoPath : companyConfiguration.logoPath"
        [alt]="companyConfiguration.productNameAlias">

    <div class="rpc-logo-name-wrap">

        <div class="rpc-logo-name">{{companyConfiguration.productName}}</div>

    </div>

    <div class="rpc-logo-company-wrap"
        *ngIf="companyConfiguration.internalId != null || (internalCompanyConfigurationLoaded$ | async)">

        <div class="rpc-logo-company"
            [title]="'LOGO.TITLES.BY' | translate : {companyName: companyConfiguration.companyName}">
            {{'LOGO.TITLES.BY' | translate : {companyName: companyConfiguration.companyName} }}
        </div>

    </div>

</div>