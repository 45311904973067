<div class="rpc-saved-your-search" (swipeup)="onExpandPanel()" data-mc-options='{ "touchAction": "pan-y" }'>

    <div class="rpc-saved-your-search-header">

        <div class="rpc-saved-your-search-header-title-wrap">

            <div class="rpc-saved-your-search-header-title">
                {{'SAVE_YOUR_SEARCH.TITLES.SAVE_YOUR_SEARCH' | translate}}
            </div>

            <rpc-icon-button class="rpc-saved-your-search-header-close" [iconName]="'cross'"
                [title]="'SAVE_YOUR_SEARCH.TITLES.CLOSE'" (clicked)="onClose()">
            </rpc-icon-button>

        </div>

        <div class="rpc-saved-your-search-header-note">
            {{'SAVE_YOUR_SEARCH.TITLES.SAVED_SEARCH_NOTE' | translate}}
        </div>

    </div>

    <div class="rpc-saved-your-search-body">

        <saved-search-option class="rpc-saved-your-search-option rpc-saved-your-search-option--first"
            [savedSearchName]="'SAVE_YOUR_SEARCH.TITLES.NEW_SAVED_SEARCH'| translate" [savedSearchIcon]="'add-new'"
            (clicked)="onSavedSearchCreate()">
        </saved-search-option>

        <ng-container *ngFor="let savedSearchInfo of savedSearchesToModify$ | async">

            <saved-search-option [savedSearchName]="savedSearchInfo.name"
                (clicked)="onSavedSearchSelected(savedSearchInfo)">
            </saved-search-option>

        </ng-container>

    </div>

</div>