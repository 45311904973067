<div class="profile-email-list">

    <ng-container *ngIf="emails.length > 0; else empty">

        <div class="profile-email-list-item" *ngFor="let email of emails"
            [class.profile-email-list-item--label]="email.isForLogin || email.isCarbonCopy">

            <div class="profile-email-list-item-option">
                {{ (emailTypesText.get(email.type) ?? '') | translate }}
            </div>

            <div class="profile-email-list-item-value" [title]="email.emailAddress">
                {{email.emailAddress}}
            </div>

            <div class="profile-email-list-item-label" *ngIf="email.isForLogin"
                [title]="'EMAILS_LIST.TITLES.LOGIN_EMAIL' | translate">

                <div class="profile-email-list-item-label-text">
                    {{ 'EMAILS_LIST.TITLES.LOGIN_EMAIL' | translate }}
                </div>

            </div>

            <div class="profile-email-list-item-label" *ngIf="email.isCarbonCopy"
                [title]="'EMAILS_LIST.TITLES.CC_ED' | translate">

                <div class="profile-email-list-item-label-text">
                    {{ 'EMAILS_LIST.TITLES.CC_ED' | translate }}
                </div>

            </div>

        </div>

    </ng-container>

    <ng-template #empty>

        <div class="profile-email-list-empty">
            {{ 'EMAILS_LIST.TITLES.EMPTY' | translate }}
        </div>

    </ng-template>

</div>