<form [formGroup]="inviteUserFormGroup">
    <rpc-input [label]="'INVITE_USER_FORM.FIELDS.RECIPIENT_EMAIL'" [type]="'email'" [appearance]="'fill'"
        [options]="recepientEmailControlOptions">
    </rpc-input>

    <mat-form-field class="rpc-textarea" [appearance]="'fill'">
        <mat-label>{{ 'INVITE_USER_FORM.FIELDS.MESSAGE' | translate }}</mat-label>
        <textarea matInput [formControlName]="'message'"></textarea>
        <mat-hint [align]="'end'">
            {{ message.length }} / {{ maximumMessageLength }}
        </mat-hint>
    </mat-form-field>
</form>