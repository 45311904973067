import { NgModule } from '@angular/core';
import { NgLetModule } from 'ng-let';
import { CommonModule } from '@angular/common';

import { NavigationPanelComponent } from './navigation-panel.component';
import { RpcControlsModule } from '@core-controls/controls.module';
import { NotificationsModule } from '@notifications/notifications.module';

@NgModule({
    imports: [
        NgLetModule,
        CommonModule,

        RpcControlsModule,
        NotificationsModule
    ],
    declarations: [NavigationPanelComponent],
    exports: [NavigationPanelComponent]
})
export class NavigationPanelModule { }
