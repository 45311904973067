import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NewAccountDataModel, OldAccountDataModel } from '../../interfaces/customer-info/account-data-model';
import { TopProfileInfo } from '../../interfaces/customer-info/top-profile-info';
import { HybridService } from 'app/modules/core-modules/hybrid/hybrid.service';

@Component({
    selector: 'profile-top',
    templateUrl: './profile-top.component.html',
    styleUrls: ['./profile-top.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTopComponent {
    @Input() public profileInfo: TopProfileInfo;

    @Output() save = new EventEmitter<{ newData: NewAccountDataModel, oldData: OldAccountDataModel }>();

    public isHybrid$ = this.hybridService.isHybrid$;

    constructor(private readonly hybridService: HybridService) { }

    public isEdit = false;

    public onEdit(): void {
        this.setEditMode(true);
    }

    public onCancel(): void {
        this.setEditMode(false);
    }

    public onSave({ newData, oldData }: { newData: NewAccountDataModel, oldData: OldAccountDataModel }): void {
        this.save.emit({ newData, oldData });

        this.setEditMode(false);
    }

    private setEditMode(isEditMode: boolean): void {
        this.isEdit = isEditMode;
    }
}