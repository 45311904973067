import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { SmartBannerComponent } from './smart-banner.component';
import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RpcControlsModule,
        RpcIconModule
    ],
    declarations: [SmartBannerComponent],
    exports: [SmartBannerComponent]
})
export class SmartBannerModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService
    ) {
        this.translationLoaderService.loadTranslations(english);
    }
}
