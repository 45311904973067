
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Email } from '../../interfaces/customer-info/email';
import { Phone } from '../../interfaces/customer-info/phone';
import { Address } from '../../interfaces/customer-info/address';

@Component({
    selector: 'general-info',
    templateUrl: './general-info.component.html',
    styleUrls: ['../general-info-base/general-info-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInfoComponent {
    @Input() public isPrimaryCustomer: boolean;
    @Input() public isSelfProfile: boolean;
    @Input() public emails: Email[];
    @Input() public phones: Phone[];
    @Input() public addresses: Address[];
    @Input() public isFullVersion = true;
}
