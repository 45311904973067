<div class="price-dropdown" *ngIf="priceChange !=null">

    <div class="price-dropdown-title">
        {{"PRICE_CHANGE_INFO.TITLES.PRICE" | translate }}
        {{ (isPriceIncreased ? 'PRICE_CHANGE_INFO.TITLES.INCREASE' :
        'PRICE_CHANGE_INFO.TITLES.DECREASE') | translate }}
    </div>

    <div class="price-dropdown-date">
        {{ priceChange.date | date: date.Formats.AngularPipeShortDateSlash }}
    </div>

    <div class="price-dropdown-info-wrap">

        <div class="price-dropdown-info">

            <div class="price-dropdown-info-title">
                {{"PRICE_CHANGE_INFO.TITLES.FROM" | translate }}
            </div>

            <div class="price-dropdown-info-option">
                {{ priceChange.previousPrice | currency: currency.Codes.USD: currency.Symbols.USD:
                currency.Formats.WithoutFraction }}
            </div>

        </div>

        <div class="price-dropdown-info">

            <div class="price-dropdown-info-title">
                {{"PRICE_CHANGE_INFO.TITLES.BY" | translate}}
            </div>

            <div class="price-dropdown-info-option">
                {{ math.abs(priceChange.delta) | currency: currency.Codes.USD: currency.Symbols.USD:
                currency.Formats.WithoutFraction }}

                <span>({{ priceChange.percent | number }}%)</span>
            </div>

        </div>
    </div>
</div>