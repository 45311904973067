import { Component, OnInit, ViewChild } from '@angular/core';
import { iif, Observable, switchMap } from 'rxjs';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { ProfileStoreService } from '@profile/store/services/profile-store.service';
import { GeneralInfoFormComponent } from '../../components/general-info-form/general-info-form.component';
import { ProfileBaseStoreService } from '../../store/services/profile-base-store.service';
import { AdditionalProfileStoreService } from '@additional-profile/store/services/additional-profile-store.service';
import { Email } from '../../interfaces/customer-info/email';
import { Phone } from '../../interfaces/customer-info/phone';
import { Address } from '../../interfaces/customer-info/address';

@Component({
    selector: 'general-info-page',
    templateUrl: './general-info-page.component.html',
    styleUrls: ['./general-info-page.component.scss']
})
export class GeneralInfoPageComponent implements OnInit {
    @ViewChild(GeneralInfoFormComponent) private readonly generalInfoForm: GeneralInfoFormComponent;

    public readonly isPrimaryCustomer$ = this.userStoreService.isPrimaryCustomer$;
    public readonly isSelfProfile$ = this.profileBaseStoreService.isSelfProfile$;
    public emails$: Observable<Email[]>;
    public phones$: Observable<Phone[]>;
    public addresses$: Observable<Address[]>;

    public isEditMode = false;

    constructor(
        private readonly userStoreService: UserStoreService,
        private readonly profileStoreService: ProfileStoreService,
        private readonly additionalProfileStoreService: AdditionalProfileStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
    ) { }

    public get isFormInvalid(): boolean {
        return this.generalInfoForm?.form?.invalid;
    }

    public ngOnInit(): void {
        this.emails$ = this.profileBaseStoreService.isSelfProfile$.pipe(
            switchMap(isSelfProfile => iif(() => isSelfProfile, this.profileStoreService.emails$, this.additionalProfileStoreService.emails$))
        );

        this.phones$ = this.profileBaseStoreService.isSelfProfile$.pipe(
            switchMap(isSelfProfile => iif(() => isSelfProfile, this.profileStoreService.phones$, this.additionalProfileStoreService.phones$))
        );

        this.addresses$ = this.profileBaseStoreService.isSelfProfile$.pipe(
            switchMap(isSelfProfile => iif(() => isSelfProfile, this.profileStoreService.addresses$, this.additionalProfileStoreService.addresses$))
        );

        this.profileBaseStoreService.loadProfileData();
    }

    public onNavigateBack(): void {
        this.profileBaseStoreService.navigateToProfile();
    }

    public onEdit(): void {
        this.isEditMode = true;
    }

    public onCancel(): void {
        this.cancelEdit();
    }

    public onSave(): void {
        const customerInfo = this.generalInfoForm.save();

        if (customerInfo == null) {
            return;
        }

        this.cancelEdit();

        this.profileBaseStoreService.updateCustomerInfo(customerInfo);
    }

    private cancelEdit(): void {
        this.isEditMode = false;
    }
}
