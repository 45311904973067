<mat-button-toggle-group class="rpc-button-toggle-wrap" [appearance]="appearence"
    #rpcButtonToggleGroup="matButtonToggleGroup" [value]="default" [title]="alt | translate" [disabled]="disabled"
    (change)="onChanged($event)">

    <ng-container *ngFor="let toggleItem of toggleItems">

        <mat-button-toggle class="rpc-button-toggle" [value]="toggleItem.value" [disableRipple]="disableRipple"
            [title]="(toggleItem.alt == null ? toggleItem.title : toggleItem.alt) | translate"
            [disabled]="toggleItem.disable">

            <rpc-icon class="rpc-button-toggle-icon" *ngIf="toggleItem.iconName != null && toggleItem.iconName !== ''"
                [iconName]="toggleItem.iconName">
            </rpc-icon>

            <div class="rpc-button-toggle-text" *ngIf="toggleItem.title != null && toggleItem.title !== ''">
                {{ toggleItem.title | translate }}
            </div>


        </mat-button-toggle>

    </ng-container>

</mat-button-toggle-group>