<div class="nm-profile-page">

    <div class="nm-profile-page-toolbar">

        <rpc-icon-button class="nm-profile-page-toolbar-back" [iconName]="'left'" [color]="'accent'"
            [title]="'NEW_MATCH_SETTINGS_PAGE.TITLES.BACK'" (clicked)="onNavigateBack()">
        </rpc-icon-button>

        <div class="nm-profile-page-toolbar-title">
            {{ 'NEW_MATCH_SETTINGS_PAGE.TITLES.NEW_MATCHES' | translate }}
        </div>

    </div>

    <new-match-settings class="nm-profile-page-body" [settings]="newMatchSettings$ | async"
        (settingsChanged)="onNewMatchSettingsChanged($event)">
    </new-match-settings>

    <rpc-scroll-to-top class="rpc-scroll-to-top-wrap">
    </rpc-scroll-to-top>

</div>