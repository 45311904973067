import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { serverMessagesMapping } from '@auth/pages/restore-password/server-message-mapping/server-message-mapping';
import * as restorePaswordActions from '@auth/store/actions/restore-password.actions';
import { RestorePasswordEffects } from '@auth/store/effects/restore-password.effects';
import { AuthStoreService } from '@auth/store/services/auth-store.service';
import { fadeInScaleAnimation } from '@core-animations/element-animations';
import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ServerMessageService } from '@core-services/server-message.service';
import { ToastService } from '@core-services/toast.service';
import { ValidationService } from '@core-validation/validation.service';
import { ApiError } from '@error/models/api-error';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'restore-password',
    templateUrl: './restore-password.component.html',
    styleUrls: ['../../auth-base/auth-base.component.scss', './restore-password.component.scss'],
    animations: [fadeInScaleAnimation]
})
export class RestorePasswordComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();
    private readonly subscription = new Subscription();

    private restoreCode: string;
    public restorePasswordForm: FormGroup;
    public companyConfiguration: CompanyConfiguration;
    public passwordControlOptions: RpcInputOptions;
    public passwordConfirmControlOptions: RpcInputOptions;

    constructor(
        private readonly authStoreService: AuthStoreService,
        private readonly restorePasswordEffects: RestorePasswordEffects,
        private readonly configurationService: AppConfigurationService,
        private readonly routeService: RouteService,
        private readonly toaster: ToastService,
        private readonly serverMessageService: ServerMessageService,
        private readonly validationService: ValidationService,
        private readonly route: ActivatedRoute
    ) {
        configurationService.configuration = { layout: { spinner: { hidden: true } } };

        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }

    public ngOnInit(): void {
        this.route.queryParams
            .pipe(take(1))
            .subscribe(params => {
                this.restoreCode = params.restoreCode;
                this.authStoreService.checkRestoreCode(this.restoreCode);
            });

        this.restorePasswordForm = new FormGroup({});

        this.passwordControlOptions = {
            formGroup: this.restorePasswordForm,
            controlName: 'password',
            validatorsMap: [
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.PASSWORD_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.NUMBER_REQIRED',
                    showError: (control: FormControl) => control.hasError('noNumber'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.CAPITAL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noCapitalCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.SMALL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noSmallCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.SPECIAL_REQIRED',
                    showError: (control: FormControl) => control.hasError('noSpecialCharacter'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.MINIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('minlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.MAXIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('maxlength'),
                    validator: this.validationService.getPasswordValidator()
                }
            ]
        };

        this.passwordConfirmControlOptions = {
            formGroup: this.restorePasswordForm,
            controlName: 'passwordConfirm',
            validatorsMap: [
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.PASSWORD_CONFIRMATION_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'RESTORE_PASSWORD.VALIDATION_ERRORS.PASSWORDS_NOT_MATCH',
                    showError: (control: FormControl) => control.hasError('notMatching'),
                    validator: this.validationService.getMatchValidator('password')
                }
            ]
        };

        this.configurationService.configuration$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(configuration => this.companyConfiguration = configuration.company);

        this.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.subscription.unsubscribe();
    }

    public restorePassword(): void {
        const password = this.restorePasswordForm.get('password').value as string;

        this.authStoreService.changePassword(password, this.restoreCode);
    }

    private subscribe(): void {
        this.restorePasswordEffects.checkCode$
            .pipe(
                ofType(restorePaswordActions.checkCodeFailed),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                (error: ApiError) => {
                    this.routeService.navigate(RpcRoute.Login)
                        .then(() => error.errorKey != null
                            ? this.showErrorMessage(error)
                            : this.toaster.showClientError('RESTORE_PASSWORD.ERRORS.SERVER.INVALID_RESTORE_CODE'))
                        .catch(() => { });
                });

        this.restorePasswordEffects.changePassword$
            .pipe(
                ofType(restorePaswordActions.changePasswordSuccessful),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                () => {
                    this.routeService.navigate(RpcRoute.Login)
                        .then(() => this.toaster.showClientSuccess('RESTORE_PASSWORD.MESSAGES.PASSWORD_RESTORED'))
                        .catch(() => { });
                });

        this.restorePasswordEffects.changePassword$
            .pipe(
                ofType(restorePaswordActions.changePasswordFailed),
                takeUntil(this.unsubscribe$)
            ).subscribe(
                (error: ApiError) => this.showErrorMessage(error));

        this.subscription.add(this.restorePasswordForm.get('password').valueChanges
            .subscribe(() => {
                this.restorePasswordForm.get('passwordConfirm').updateValueAndValidity();
                this.restorePasswordForm.get('password').markAsTouched();
            }));
    }

    private showErrorMessage(error: ApiError): void {
        this.toaster.showServerError(error);
    }
}