<div class="folder" [class.folder--footer]="folder.activityPanelInfo.hasActivityPanel" [id]="folder.id"
    (click)="onClick()">

    <div class="folder-header">

        <div class="folder-header-icon-wrap">

            <rpc-icon class="folder-header-icon" [iconName]="'folder'">
            </rpc-icon>

        </div>

        <div class="folder-header-name-wrap"
            [class.folder-header-name-wrap--badge]="folder.activityPanelInfo.newListingsCount > 0">

            <div class="folder-header-name"
                [title]="folder.isSystemFolder ? (('FOLDERS.TITLES.' + folder.name) | translate) : folder.name">
                {{ folder.isSystemFolder ? (('FOLDERS.TITLES.' + folder.name) | translate) : folder.name}}
            </div>

            <div class="folder-header-name-badge" *ngIf="folder.activityPanelInfo.newListingsCount > 0">
                {{folder.activityPanelInfo.newListingsCount}}</div>

        </div>

        <div class="folder-header-counter">
            {{ (folder.activityPanelInfo.listingsCount === 1 ? 'FOLDERS.TITLES.HOME' : 'FOLDERS.TITLES.HOMES')
            | translate: {count: folder.activityPanelInfo.listingsCount} }}
        </div>

        <rpc-icon-button class="folder-header-action" *ngIf="isMobile$ | async; else desktopFolderButton"
            [iconName]="'kebab'" [color]="'accent'" [title]="'FOLDERS.TITLES.DETAILS'"
            (click)="$event.stopPropagation(); onFolderDetailsClick(folder)">
        </rpc-icon-button>

        <ng-template #desktopFolderButton>

            <rpc-menu-icon-button class="folder-header-action" [iconName]="'kebab'" [alt]="'FOLDERS.TITLES.DETAILS'"
                (click)="$event.stopPropagation()" #rpcMenuIconButtonApi="rpcMenuIconButtonApi">

                <folder-details *ngIf="rpcMenuIconButtonApi.menuOpened" [folder]="folder" [isDefault]="isDefault"
                    [showFolderName]="false" [hasClose]="false" (closed)="rpcMenuIconButtonApi.closeMenu()">
                </folder-details>

            </rpc-menu-icon-button>

        </ng-template>

    </div>

    <div class="folder-body" [class]="mediaClass">

        <custom-spinner class="folder-body-spinner" *ngIf="!hasNoMedia && !isMediaInitialized" [showImage]="false">
        </custom-spinner>

        <div *ngIf="hasNoMedia; else folders" class="folder-body-empty">

            <rpc-icon [iconName]="'folders-empty'" class="folder-body-empty-icon">
            </rpc-icon>

            <div class="folder-body-empty-text">{{ 'FOLDERS.TITLES.EMPTY_FOLDER' | translate }}</div>

        </div>

        <ng-template #folders>

            <ng-container *ngFor="let image of folder.images; trackBy: trackByImage">

                <default-image class="folder-img" *ngIf="image" [image]="image.desktopUrl" [useBackgroundImage]="true"
                    (imageLoaded)="onImageLoaded()" [isDummy]="image.isDummy">
                </default-image>

            </ng-container>

        </ng-template>

    </div>

    <div class="folder-footer" *ngIf="folder.activityPanelInfo.hasActivityPanel">

        <div class="chips liked" [title]="'FOLDERS.TITLES.LIKED_LISTINGS' | translate"
            [class.chips--active]="folder.activityPanelInfo.likedListingsCount > 0"
            [class.chips--new]="folder.activityPanelInfo.hasNewLikedListings"
            (click)="onClick(initialFolderStateMode.Liked)">

            <rpc-icon [iconName]="'like'" class="chips-icon">
            </rpc-icon>

            <div class="chips-text">{{ folder.activityPanelInfo.likedListingsCount }}</div>

        </div>

        <div class="chips nm" [title]="'FOLDERS.TITLES.NEW_MATCHES' | translate"
            [class.chips--active]="folder.activityPanelInfo.newMatchesCount > 0"
            (click)="onClick(initialFolderStateMode.NewMatches)">

            <rpc-icon [iconName]="'star'" class="chips-icon">
            </rpc-icon>

            <div class="chips-text">{{ folder.activityPanelInfo.newMatchesCount }}</div>

        </div>

        <div class="chips messages" [title]="'FOLDERS.TITLES.MESSAGES' | translate"
            [class.chips--active]="folder.activityPanelInfo.commentsCount > 0"
            [class.chips--new]="folder.activityPanelInfo.hasNewComments"
            (click)="onClick(initialFolderStateMode.Comments)">

            <rpc-icon [iconName]="'comments'" class="chips-icon">
            </rpc-icon>

            <div class="chips-text">{{ folder.activityPanelInfo.commentsCount }}</div>

        </div>

        <div class="chips appointments" [title]="'FOLDERS.TITLES.APPOINTMENTS' | translate"
            [class.chips--active]="folder.activityPanelInfo.upcomingAppointmentCount > 0"
            [class.chips--new]="folder.activityPanelInfo.hasNewAppointments"
            (click)="onClick(initialFolderStateMode.Appointments)">

            <rpc-icon [iconName]="'appointment'" class="chips-icon">
            </rpc-icon>

            <div class="chips-text">{{ folder.activityPanelInfo.upcomingAppointmentCount }}</div>

        </div>

    </div>

</div>