<div class="profile-phone-list">

    <ng-container *ngIf="phones.length > 0; else empty">

        <div class="profile-phone-list-item" *ngFor="let phone of phones">

            <div class="profile-phone-list-item-option">
                {{ (phoneTypesText.get(phone.type) ?? '') | translate }}
            </div>

            <div class="profile-phone-list-item-value" [title]="phone.number.phoneNumber">
                {{phone.number.phoneNumber}}
            </div>

        </div>

    </ng-container>

    <ng-template #empty>

        <div class="profile-phone-list-empty">
            {{ 'PHONES_LIST.TITLES.EMPTY' | translate }}
        </div>

    </ng-template>

</div>