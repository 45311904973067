import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { GeneralInfoFormComponent } from '../../../profile-base/components/general-info-form/general-info-form.component';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { Email } from '../../interfaces/customer-info/email';
import { Phone } from '../../interfaces/customer-info/phone';
import { Address } from '../../interfaces/customer-info/address';
import { CustomerInfo } from '../../interfaces/customer-info/customer-info';

@Component({
    selector: 'general-info-tab',
    templateUrl: './general-info-tab.component.html',
    styleUrls: ['./general-info-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInfoTabComponent {
    @Input() public isSelfProfile: boolean;
    @Input() public emails: Email[];
    @Input() public phones: Phone[];
    @Input() public addresses: Address[];
    @Input() public isFullVersion = true;

    @Output() public save = new EventEmitter<CustomerInfo>();

    @ViewChild(GeneralInfoFormComponent) private readonly generalInfoForm: GeneralInfoFormComponent;

    public readonly isPrimaryCustomer$ = this.userStoreService.isPrimaryCustomer$;

    public isEditMode = false;

    constructor(
        private readonly userStoreService: UserStoreService,
    ) { }

    public get isFormInvalid(): boolean {
        return this.generalInfoForm?.form?.invalid;
    }

    public onEdit(): void {
        this.isEditMode = true;
    }

    public onCancel(): void {
        this.cancelEdit();
    }

    public onSave(): void {
        const customerInfo = this.generalInfoForm.save();

        if (customerInfo == null) {
            return;
        }

        this.cancelEdit();

        this.save.emit(customerInfo);
    }

    private cancelEdit(): void {
        this.isEditMode = false;
    }
}
