<div class="agent-info" [class.agent-info--active]="isSelected && !isCurrent" [class.agent-info--current]="isCurrent">

    <div class="agent-info-body">

        <div class="agent-info-status-wrap" *ngIf="isCurrent">

            <div class="agent-info-status">

                <div class="agent-info-status-text">
                    {{ 'AGENT_BOX.TITLES.CURRENT_AGENT' | translate }}
                </div>

            </div>

        </div>

        <div class="agent-info-block">

            <avatars-icon class="agent-info-avatar" [front]="front" [back]="back" [displayIcon]="isSharedSpace"
                iconName="shared-space">
            </avatars-icon>

            <div class="agent-info-right">

                <div class="agent-info-name" [title]="fullNames">
                    {{ fullNames }}
                </div>

                <div class="agent-info-title">
                    {{ agentOwner.executiveTitle }}
                </div>

                <div class="agent-info-company">
                    {{ agentOwner.companyName }}
                </div>

                <div class="agent-info-login" *ngIf="agentCandidate.lastLoginDate != null">
                    {{ 'AGENT_BOX.TITLES.LAST_LOGIN' | translate }}
                    {{ agentCandidate.lastLoginDate | date: DateConstants.Formats.AngularPipeShortDateSlash }}
                </div>

            </div>

        </div>

    </div>

    <div class="agent-info-footer">

        <div class="agent-info-footer-item" [class.agent-info-footer-item--new]="agentCandidate.hasNewListings"
            [class.agent-info-footer-item--active]="agentCandidate.listingsCount > 0">

            <rpc-icon [iconName]="'listing-in-building'" class="agent-info-footer-item-icon">
            </rpc-icon>

            <div class="agent-info-footer-item-text">
                {{agentCandidate.listingsCount}}
            </div>

        </div>

        <div class="agent-info-footer-item agent-info-footer-item--liked"
            [class.agent-info-footer-item--active]="agentCandidate.likedListingsCount > 0">

            <rpc-icon [iconName]="'like'" class="agent-info-footer-item-icon">
            </rpc-icon>

            <div class="agent-info-footer-item-text">
                {{agentCandidate.likedListingsCount}}
            </div>

        </div>

        <div class="agent-info-footer-item" [class.agent-info-footer-item--new]="agentCandidate.hasUnreadComments"
            [class.agent-info-footer-item--active]="agentCandidate.commentsCount > 0">

            <rpc-icon [iconName]="'comments'" class="agent-info-footer-item-icon">
            </rpc-icon>

            <div class="agent-info-footer-item-text">
                {{agentCandidate.commentsCount}}
            </div>

        </div>

        <div class="agent-info-footer-item" [class.agent-info-footer-item--new]="agentCandidate.hasUnreadAppointments"
            [class.agent-info-footer-item--active]="agentCandidate.appointmentsCount > 0">

            <rpc-icon [iconName]="'appointment'" class="agent-info-footer-item-icon">
            </rpc-icon>

            <div class="agent-info-footer-item-text">
                {{agentCandidate.appointmentsCount}}
            </div>

        </div>

    </div>

</div>