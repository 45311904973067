export const locale = {
    lang: 'en',
    data: {
        ADD_EDIT_SAVED_SEARCH_DIALOG: {
            TITLES: {
                ADD_SAVED_SEARCH: 'New Saved Search',
                EDIT_SAVED_SEARCH: 'Edit Saved Search',
            },
            BUTTONS: {
                CANCEL: 'Cancel',
                CREATE: 'Create',
                SAVE: 'Save',
                DELETE: 'Delete',
            }
        }
    }
};