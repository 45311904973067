import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/effects';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { ListingActivityDescription } from '@listings/constants/listing-activity-constants';
import { SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { ActivitiesClickedEventParams, ActivityClickedEventParams } from '@listings/models/events/activity-clicked-event-params';
import { ListingSelectionEventParams } from '@listings/models/events/listing-selection-event-params';
import { ListingsSelectionEventParams } from '@listings/models/events/listings-selection-event-params';
import { ListingActivityStoreService } from '@listings/store/services/listing-activity-store.service';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { ListingInfo } from '@listings/models/listing/listing-info';

@Injectable({ providedIn: 'root' })
export class ListingsService {

    constructor(
        protected readonly listingsStoreService: ListingsStoreService,
        protected readonly userStoreService: UserStoreService,
        protected readonly listingActivityStoreService: ListingActivityStoreService,
    ) { }

    public updateListingSelection(listingSelectionEventParams: ListingSelectionEventParams): void {
        if (listingSelectionEventParams.isSelected) {
            this.listingsStoreService.selectListing(listingSelectionEventParams.listingId);
        } else {
            this.listingsStoreService.unselectListings([listingSelectionEventParams.listingId]);
        }
    }

    public updateListingsSelection(listingsSelectionEventParams: ListingsSelectionEventParams): void {
        if (listingsSelectionEventParams.areSelected) {
            this.listingsStoreService.selectListings(listingsSelectionEventParams.listingIds);
        } else {
            this.listingsStoreService.unselectAllListings();
        }
    }

    public updateSelectedListingsActivity(selectedListings$: Observable<ListingInfo[]>, activity: ListingActivityDescription, unselectNewMatches = false): void {
        selectedListings$.pipe(
            concatLatestFrom(() => this.userStoreService.getUser()),
            take(1)
        ).subscribe(([listings, currentUser]) =>
            this.updateListingsActivity({
                listingCandidates: listings.map(({ id, hashCode, isNewMatch, activities, isMarketListing, pinOwnerAgentId, newMatches }) => {
                    return { id, hashCode, isNewMatch, activities, newMatches, isMarketListing, pinOwnerAgentId, customerId: currentUser.customerId };
                }),
                activity
            }, unselectNewMatches));

    }

    public updateListingsActivity(setListingActivityRequest: SetListingActivityRequest, unselectNewMatches = false): void {
        this.userStoreService.getUser()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.listingActivityStoreService.setListingsActivity(setListingActivityRequest, currentUser.customerId, currentUser.agentId);

                if (unselectNewMatches) {
                    const newMatchListingIds = setListingActivityRequest.listingCandidates.reduce((result, listing) =>
                        listing.isNewMatch ? [...result, listing.id] : result, new Array<string>());

                    this.listingsStoreService.unselectListings(newMatchListingIds);
                }
            });
    }

    public updateListingActivity(activityClickedEventParams: ActivityClickedEventParams, unselectNewMatches = false): void {
        this.userStoreService.getUser()
            .pipe(take(1))
            .subscribe(currentUser => {
                const request = { listingCandidates: [activityClickedEventParams.listingCandidate], activity: activityClickedEventParams.activity };

                if (unselectNewMatches && activityClickedEventParams.listingCandidate.isNewMatch) {
                    this.listingsStoreService.unselectListings([activityClickedEventParams.listingCandidate.id]);
                }

                return this.listingActivityStoreService.setListingsActivity(request, currentUser.customerId, currentUser.agentId);
            });
    }

    public updateListingActivities(activityClickedEventParams: ActivitiesClickedEventParams, unselectNewMatches = false): void {
        this.userStoreService.getUser()
            .pipe(take(1))
            .subscribe(currentUser => {
                const request = { listingCandidates: [activityClickedEventParams.listingCandidate], activities: activityClickedEventParams.activities };

                if (unselectNewMatches && activityClickedEventParams.listingCandidate.isNewMatch) {
                    this.listingsStoreService.unselectListings([activityClickedEventParams.listingCandidate.id]);
                }

                return this.listingActivityStoreService.setListingsActivities(request, currentUser.customerId, currentUser.agentId);
            });
    }
}