export const locale = {
    lang: 'en',
    data: {
        APPOINTMENTS: {
            BUTTONS: {
                CREATE: 'Request Appointment'
            },
            TITLES: {
                NO_APPOINTMENTS: 'You have no upcoming scheduled appointments.'
            }
        }
    }
};
