import { FeatureCollection } from '@settings/models/map/feature-collection';
import { CustomerSettings } from '@settings/models/settings/customer-settings';

export class SettingsState {

    settings: CustomerSettings;
    newMatchSettings: number[];
    neighborhoodsMapping: FeatureCollection;

    public readonly isLoading: boolean = false;
    readonly newMatchSettingsLoaded: boolean = false;

    constructor() {
        this.settings = null;
        this.neighborhoodsMapping = null;
    }
}