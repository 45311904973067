import { Injectable } from '@angular/core';
import { nameof } from '@core-models/utilities/nameof';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { NavigationItem } from '../models/navigation-item';

@Injectable({ providedIn: 'root' })
export class InternalListingDetailsNavigationService<TSnapshotItem extends NavigationItem> {

    private readonly navigationItems = new ReplaySubject<TSnapshotItem[]>(1);
    private readonly activatedItemIndex = new ReplaySubject<number>(1);
    private readonly navigationSource = new ReplaySubject<string>(1);

    constructor() {
        this.setNavigation();
    }

    public setNavigation(snapshotItems: TSnapshotItem[] = null, activatedItemIndex: number = null, navigationSource: string = null): void {
        if ((snapshotItems != null && activatedItemIndex != null && navigationSource != null) ||
            (snapshotItems == null && activatedItemIndex == null && navigationSource == null)
        ) {
            this.navigationItems.next(snapshotItems);
            this.activatedItemIndex.next(activatedItemIndex);
            this.navigationSource.next(navigationSource);
        } else {
            throw new Error(`${nameof<InternalListingDetailsNavigationService<TSnapshotItem>>('setNavigation')} requires all parameter or no parameters at all.`);
        }
    }

    public activatePrevioustNavigationItem(): void {
        this.activatedItemIndex
            .pipe(take(1))
            .subscribe(activatedItemIndex => {
                if (activatedItemIndex != null && activatedItemIndex - 1 >= 0) {
                    this.activatedItemIndex.next(activatedItemIndex - 1);
                }
            });
    }

    public activateNextNavigationItem(): void {
        combineLatest([this.activatedItemIndex, this.navigationItems])
            .pipe(take(1))
            .subscribe(([activatedItemIndex, items]) => {
                if (activatedItemIndex != null && items != null && activatedItemIndex + 1 < items.length) {
                    this.activatedItemIndex.next(activatedItemIndex + 1);
                }
            });
    }

    public getNavigation(): Observable<TSnapshotItem[]> {
        return this.navigationItems.asObservable();
    }

    public getActivatedNavigationItemIndex(): Observable<number> {
        return this.activatedItemIndex.asObservable();
    }

    public getNavigationSource(): Observable<string> {
        return this.navigationSource.asObservable();
    }

    public getActivatedNavigationItemsCount(): Observable<number> {
        return this.navigationItems.pipe(map(items => items != null ? items.length : null));
    }

    public getActivatedNavigationItem(): Observable<TSnapshotItem> {
        return combineLatest([this.activatedItemIndex, this.navigationItems])
            .pipe(map(([activatedItemIndex, items]) => {
                if ((activatedItemIndex != null && activatedItemIndex >= 0 && items != null) ||
                    (activatedItemIndex != null && items != null && activatedItemIndex < items.length)) {
                    return items[activatedItemIndex];
                }

                return null;
            }));
    }

    public hasActivatedNavigationItems(): Observable<boolean> {
        return this.getNavigation()
            .pipe(map(navigationItems => {
                return navigationItems != null && navigationItems.length > 0;
            }));
    }

    public hasPreviousNavigationItem(): Observable<boolean> {
        return this.activatedItemIndex
            .pipe(map(activatedItemIndex => activatedItemIndex != null && activatedItemIndex - 1 >= 0));
    }

    public hasNextNavigationItem(): Observable<boolean> {
        return combineLatest([this.activatedItemIndex, this.navigationItems])
            .pipe(map(([activatedItemIndex, items]) => activatedItemIndex != null && items != null && activatedItemIndex + 1 < items.length));
    }
}
