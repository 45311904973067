<div class="profile-edit-email">

    <div class="profile-edit-email-item" [class.profile-edit-email-item--login]="emailControlsGroup.isForLogin"
        *ngFor="let emailControlsGroup of emailControlsGroups; let i = index">

        <div class="profile-edit-email-item-header">

            <div class="profile-edit-email-item-header-title">
                {{ 'EMAILS_EDIT_FORM.TITLES.EMAIL' | translate }} {{ i + 1 }}
            </div>

            <rpc-button class="profile-edit-email-item-header-btn" *ngIf="!emailControlsGroup.isForLogin"
                [color]="'warn'" [title]="'EMAILS_EDIT_FORM.TITLES.DELETE'" [iconName]="'delete-bin'"
                (clicked)="removeEmail(i)">
            </rpc-button>

            <div class="profile-edit-email-item-header-label" *ngIf="emailControlsGroup.isForLogin"
                [title]="'EMAILS_EDIT_FORM.TITLES.LOGIN_EMAIL' | translate">

                <div class="profile-edit-email-item-header-label-text">
                    {{ 'EMAILS_EDIT_FORM.TITLES.LOGIN_EMAIL' | translate }}
                </div>

            </div>

        </div>

        <div class="profile-edit-email-item-body">

            <rpc-select class="profile-edit-email-item-body-select" [color]="'accent'"
                [label]="'EMAILS_EDIT_FORM.TITLES.TYPE'" [controlOptions]="emailControlsGroup.selectOptions"
                [appearance]="'fill'" [disabled]="emailControlsGroup.isForLogin">
            </rpc-select>

            <rpc-input class="profile-edit-email-item-body-input" [label]="'EMAILS_EDIT_FORM.TITLES.EMAIL_ADDRESS'"
                [type]="'email'" [appearance]="'fill'" [options]="emailControlsGroup.inputOptions"
                [disabled]="emailControlsGroup.isForLogin">
            </rpc-input>

            <div class="profile-edit-email-item-body-hint" *ngIf="emailControlsGroup.isForLogin">
                {{ 'EMAILS_EDIT_FORM.TITLES.LOGIN_DISABLE_EXPLANATION' | translate }}
            </div>

            <div class="profile-edit-email-item-toggle-wrap" *ngIf="!emailControlsGroup.isForLogin">

                <mat-slide-toggle class="profile-edit-email-item-toggle" ngDefaultControl
                    [formControl]="emailControlsGroup.isCarbonCopyControl" [labelPosition]="'after'">
                    <div>

                        <div>
                            {{ 'EMAILS_EDIT_FORM.TITLES.ADD_CC' | translate }}
                        </div>
                        <div class="profile-edit-email-item-body-hint">
                            {{ 'EMAILS_EDIT_FORM.TITLES.ADD_CC_EPLANATION' | translate }}
                        </div>

                    </div>

                </mat-slide-toggle>


            </div>

        </div>

    </div>

    <div class="profile-edit-email-add-wrap">

        <rpc-button [color]="'accent'" [iconName]="'add-new'" [title]="'EMAILS_EDIT_FORM.TITLES.ADD_NEW'"
            (clicked)="addEmail()">
        </rpc-button>

    </div>

</div>