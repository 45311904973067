export const locale = {
    lang: 'en',
    data: {
        FOLDER_DETAILS: {
            TITLES: {
                ALL_HOMES_IN_PORTFOLIO: 'All Homes in Portfolio',
                ADDED_BY_AGENT: 'Added By My Agent',
                NEW_FROM_SAVED_SEARCHES: 'New Matches From My Searches',
                OPEN_HOUSES: 'Open Houses',
                FOLDER_CREATOR: 'Folder Creator',
                ATTACHED_SAVED_SEARCH: 'Attached Saved Search',
                SET_DEFAULT: 'Set Folder as Default',
                DEFAULT_FOLDER_MESSAGE: 'Folder will be opened automatically when viewing your portfolio.',
                EDIT: 'Edit',
                DELETE_FOLDER: 'Delete Folder',
                HIDE: 'Hide',
                SEE_ALL: 'See All',
                CLOSE: 'Close',
            }
        }
    }
};
